import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function useAccessControl() {
	const navigate = useNavigate();
	const location = useLocation();
	const [isVerified, setIsVerified] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	const [menuPermissions, setMenuPermissions] = useState({
		isMenuProbeComm: false,
		isMenuProbeLogs: false,
		isMenuProbePumpLock: false,
		isMenuProbeSettings: false,
		isMenuProbeRegisters: false,
	});

	useEffect(() => {
		const token = localStorage.getItem('token');
		let decodedToken;
		if (token) {
			try {
				decodedToken = jwtDecode(token);
				setIsAdmin(decodedToken.adm === 1);
			} catch (error) {
				console.error('Invalid token:', error);
			}
		}

		const userRights = JSON.parse(localStorage.getItem('routes')) || [];
		const currentRoute = location.pathname.split('/')[1];

		const tempMenuPermissions = {
			isMenuProbeComm: userRights.some(
				(item) =>
					item.menu_key === 'menu-probe-comm' && item.visible === 1
			),
			isMenuProbeLogs: userRights.some(
				(item) =>
					item.menu_key === 'menu-probe-logs' && item.visible === 1
			),
			isMenuProbePumpLock: userRights.some(
				(item) =>
					item.menu_key === 'menu-probe-pump-lock' &&
					item.visible === 1
			),
			isMenuProbeSettings: userRights.some(
				(item) =>
					item.menu_key === 'menu-probe-settings' &&
					item.visible === 1
			),
			isMenuProbeRegisters: userRights.some(
				(item) =>
					item.menu_key === 'menu-probe-registers' &&
					item.visible === 1
			),
		};

		let tempIsVerified = false;
		if (
			currentRoute === 'details' ||
			currentRoute === 'multidetails' ||
			currentRoute === 'admin-users-admin'
		) {
			tempIsVerified = true;
		} else {
			const hasAccess = userRights.some(
				(item) =>
					item.menu_key === `${currentRoute}` &&
					item.visible === 1
			);
			if (!hasAccess) {
				navigate('/noauth');
			} else {
				tempIsVerified = true;
			}
		}

		setIsVerified(tempIsVerified);
		setMenuPermissions(tempMenuPermissions);
	}, [navigate, location]);

	if (location.pathname === '/admin-users-admin') {
		return {
			isVerified: true,
			isAdmin,
		};
	}

	if (location.pathname.split('/')[1] === 'details') {
		return {
			isVerified,
			...menuPermissions,
		};
	}

	return {
		isVerified,
	};
}

export default useAccessControl;
