import React, { useState, useEffect } from 'react';
import { DocumentLayout } from './Document.style';
import { sendRequest } from '../../api/sendRequest';
import ReactQuill, { Quill } from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import parse from 'html-react-parser';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from '../../components/Button/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Document = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [htmlContent, setHtmlContent] = useState('');
	const [dataJson, setDataJson] = useState({});
	const [documentName, setDocumentName] = useState('');
	const [topBottomMargin, setTopBottomMargin] = useState('normal');
	const [leftRightMargin, setLeftRightMargin] = useState('normal');
	const [documentNotFound, setDocumentNotFound] = useState(false);
	const [groupType, setGroupType] = useState(null);
	const [finishedProcedure, setFinishedProcedure] = useState(() => {
		const saved = localStorage.getItem('finishedProcedure');
		return saved ? saved === 'true' : false;
	});
	const { id } = useParams();
	const urlParams = new URLSearchParams(window.location.search);
	const recipient_id = urlParams.get('recipient_id');
	const location = useLocation();
	const navigate = useNavigate()

	const checkProcedure = async () => {
		const response = await sendRequest({ action: 'check-procedure' });
		const isFinished = response.data._data_ !== 0;

		setFinishedProcedure(isFinished);
		localStorage.setItem('finishedProcedure', isFinished.toString());
	};

	const fetchData = async () => {
		const getDocument = {
		  action: 'get-document-ready',
		  id: id
		};
		
		if (recipient_id !== null) {
		  getDocument.odbiorca_id = recipient_id;
		}
		
		try {
		  const response = await sendRequest(getDocument);
		  if (response.data._data_) {
			setDocumentName(response.data._data_.name);
			setDataJson(response.data._data_.data_json);
			const delta = JSON.parse(response.data._data_.data_json);
	  
			let newLeftRightMargin = 'normal';
			let newTopBottomMargin = 'normal';
	  
			if (delta.leftRightMarginOption && delta.topBottomMarginOption) {
			  newLeftRightMargin = delta.leftRightMarginOption;
			  newTopBottomMargin = delta.topBottomMarginOption;
			} else if (delta.marginOption) {
			  switch (delta.marginOption) {
				case 'normal':
				  newLeftRightMargin = 'normal';
				  newTopBottomMargin = 'normal';
				  break;
				case 'narrow':
				  newLeftRightMargin = 'narrow';
				  newTopBottomMargin = 'narrow';
				  break;
				case 'moderate':
				  newLeftRightMargin = 'moderate';
				  newTopBottomMargin = 'moderate';
				  break;
			  }
			}
	  
			setLeftRightMargin(newLeftRightMargin);
			setTopBottomMargin(newTopBottomMargin);
	  
			const quill = new Quill(document.createElement('div'));
			quill.setContents(delta.ops);
			let html = quill.root.innerHTML;
	  
			html = html.replace(
			  /%%(.*?);(\d+)%%/g,
			  (match, inputName, maxLength) => {
				return `<input type="text" name="${inputName}" placeholder="${inputName}" maxLength="${maxLength}" />`;
			  }
			);
	  
			setHtmlContent(html);
			setDocumentNotFound(false);
		  } else {
			setDocumentNotFound(true);
		  }
		} catch (error) {
		  console.error(error);
		  setDocumentNotFound(true);
		} finally {
		  setIsLoading(false);
		}
	  };	  

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const groupTypeParam = queryParams.get('groupType');
		if (groupTypeParam === '1' || groupTypeParam === '2') {
			setGroupType(groupTypeParam);
		} else {
			navigate('/');
		}

		fetchData();
		checkProcedure();
	}, [id, location, navigate]);

	const savePDF = () => {
		const input = document.querySelector('.ql-editor');
		html2canvas(input, { scale: 1.2 })
			.then((canvas) => {
				const imgWidth = 190;
				const pageHeight = 295;
				const imgHeight = (canvas.height * imgWidth) / canvas.width;
				let heightLeft = imgHeight;

				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF('p', 'mm');
				let position = 0;

				pdf.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;

				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					pdf.addPage();
					pdf.addImage(
						imgData,
						'PNG',
						5,
						position,
						imgWidth,
						imgHeight
					);
					heightLeft -= pageHeight;
				}

				pdf.save(`${documentName}.pdf`);
			})
			.catch((err) => console.error('Error generating PDF', err));
	};

	

	const handleSave = async () => {
		const inputs = document.querySelectorAll('input');
		const data = Array.from(inputs).reduce((acc, input) => {
			acc[input.name] = { length: input.maxLength, value: input.value };
			return acc;
		}, {});
		savePDF();

		const saveDocumentDone = {
			action: 'save-document-done',
			document_id: id,
			data_json: dataJson,
			groupType: groupType,
		};
		try {
			sendRequest(saveDocumentDone);
		} catch (error) {
			console.error(error);
		}
	};

	const handleDownload = async () => {
		const inputs = document.querySelectorAll('input');
		const data = Array.from(inputs).reduce((acc, input) => {
			acc[input.name] = { length: input.maxLength, value: input.value };
			return acc;
		}, {});
		savePDF();
	};

	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (documentNotFound) {
		return <DocumentLayout>Brak dokumentu</DocumentLayout>;
	}

	return (
		<DocumentLayout>
			<div className="ql-container ql-snow">
				{finishedProcedure ? (
					<Button style={{ margin: '10px' }} onClick={handleDownload}>
						Pobierz dokument
					</Button>
				) : (
					<Button style={{ margin: '10px' }} onClick={handleSave}>
						Zapisz dokument
					</Button>
				)}
				<div className={`ql-editor left-right-${leftRightMargin} top-bottom-${topBottomMargin}`}>
					{parse(htmlContent)}
				</div>
			</div>
		</DocumentLayout>
	);
};

export default Document;
