import { useState, useRef, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { SellersLayout } from './Sellers.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import useAccessControl from '../../hooks/useAccessControl';

const Sellers = () => {
	const isAccessVerified = useAccessControl();
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [data, setData] = useState();
	const [deletingRow, setDeletingRow] = useState();
	const [newSeller, setNewSeller] = useState({
		id: '',
		nazwa: '',
		nip: '',
	});
	const [errors, setErrors] = useState({
		nazwa: '',
		nip: '',
	});

	const fetchData = async () => {
		const getSellers = {
			action: 'get-sprzedawcy',
		};

		try {
			const response = await sendRequest(getSellers);
			setData(response.data._data_);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const clearForm = () => {
		setNewSeller({
			id: '',
			nazwa: '',
			nip: '',
		});
	};

	const closeModal = () => {
		if (isEditMode) {
			clearForm();
			setIsEditMode(false);
		}
		setIsModalOpen(false);
		setIsModalDeleteOpen(false);
		setDeletingRow();
	};

	const handleEditClick = (row) => {
		setNewSeller({
			id: row.id,
			nazwa: row.nazwa,
			nip: row.nip,
		});
		setIsEditMode(true);
		setIsModalOpen(true);
	};

	const handleEditSeller = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const sellerData = {
				id: newSeller.id,
				nazwa: newSeller.nazwa,
				nip: newSeller.nip,
			};

			const addSeller = {
				action: 'edit-sprzedawca',
				_data_: sellerData,
			};

			try {
				await sendRequest(addSeller);
				closeModal();
				clearForm();
				fetchData();
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const handleDeleteSeller = async () => {
		const deleteSeller = {
			action: 'delete-sprzedawca',
			id: deletingRow.id,
		};
		try {
			await sendRequest(deleteSeller);
			fetchData();
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddSeller = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const sellerData = {
				nazwa: newSeller.nazwa,
				nip: newSeller.nip,
			};

			const addSeller = {
				action: 'add-sprzedawca',
				_data_: sellerData,
			};

			try {
				await sendRequest(addSeller);
				closeModal();
				clearForm();
				fetchData();
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const validateForm = () => {
		let isValid = true;
		let newErrors = {
			nazwa: '',
			nip: '',
		};

		for (const key in newSeller) {
			if (key === 'id') continue;

			if (
				newSeller[key] === null ||
				newSeller[key] === '' ||
				(typeof newSeller[key] === 'string' &&
					newSeller[key].length === 0)
			) {
				newErrors[key] = 'To pole jest wymagane';
				isValid = false;
			}
		}

		if (newSeller.nip !== null && newSeller.nip.length !== 10) {
			newErrors.nip = 'NIP powinien zawierać 10 cyfr';
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		if (name === 'nip') {
			if (/^\d*$/.test(value)) {
				setNewSeller((prevState) => ({
					...prevState,
					[name]: value,
				}));
			}
		} else {
			setNewSeller((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '15px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							handleEditClick(row);
						}}
					/>
					<Icozamknij
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							setDeletingRow(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
		{
			name: 'Nazwa',
			selector: (row) => row.nazwa,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'NIP',
			selector: (row) => row.nip,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
	];

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	if (!isAccessVerified) {
		return null;
	}

	return (
		<SellersLayout>
			<h2 className="page-title">Sprzedawcy</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={data}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<Button
					className="add-button"
					onClick={() => setIsModalOpen(true)}
					style={{
						fontSize: '14px',
					}}>
					Dodaj sprzedawcę
				</Button>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{isEditMode ? (
							<h2>Edytuj Sprzedawcę</h2>
						) : (
							<h2>Dodaj Sprzedawcę</h2>
						)}
						<div>
							<label>Nazwa sprzedawcy:</label>
							<input
								name="nazwa"
								placeholder="Nazwa sprzedawcy"
								value={newSeller.nazwa}
								className="input-form"
								onChange={handleInputChange}
							/>

							<p className="err-msg">{errors.nazwa}</p>
						</div>
						<div>
							<label>NIP:</label>
							<input
								name="nip"
								placeholder="NIP sprzedawcy"
								value={newSeller.nip}
								className="input-form"
								onChange={handleInputChange}
							/>
							<p className="err-msg">{errors.nip}</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{isEditMode ? (
								<Button
									className="add"
									onClick={handleEditSeller}>
									Zapisz
								</Button>
							) : (
								<Button
									className="add"
									onClick={handleAddSeller}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć sprzedawcę:</h2>
						<h2>{deletingRow.nazwa} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeleteSeller()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</SellersLayout>
	);
};

export default Sellers;
