import { useState, useRef, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { GminaLayout } from './Gmina.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';
import useAccessControl from '../../hooks/useAccessControl';
import * as XLSX from 'xlsx';

const Gmina = () => {
	const isAccessVerified = useAccessControl();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [data, setData] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [deletingRow, setDeletingRow] = useState();
	const selectedGminaId = sessionStorage.getItem('selectedGminaId')
	const [newGmina, setNewGmina] = useState({
		id: '',
		nazwa: '',
	});
	const [errors, setErrors] = useState({
		nazwa: '',
	});
	const selectOptions = [
		{
			value: 0,
			label: 'Aktywny',
		},
		{
			value: 1,
			label: 'Zakonczony',
		},
		{
			value: 2,
			label: 'Rezygnacja',
		},
	];
	const customStyles = {
		control: (base) => ({
			...base,
			height: 35,
			minHeight: 30,
			color: theme.fontcolor,
			backgroundColor: theme.white,
			border: '1px solid #ccc',
		}),
		valueContainer: (base) => ({
			...base,
			padding: '0 6px',
			maxHeight: 30,
		}),
		input: (base) => ({
			...base,
			color: theme.fontcolor,
		}),
		singleValue: (base) => ({
			...base,
			color: theme.fontcolor,
		}),
		menuList: (provided) => ({
			...provided,
			maxHeight: '150px',
			overflowY: 'auto',
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: theme.white,
		}),
		option: (provided, state) => ({
			...provided,
			padding: '4px 10px',
			color: theme.fontcolor,
			backgroundColor: state.isFocused
				? theme.expandedTableBackgroundColor
				: provided.backgroundColor,
		}),
	};

	const fetchData = async () => {
		const getGminy = {
			action: 'get-gminy',
		};
	
		try {
			const response = await sendRequest(getGminy);
			setData(response.data._data_);
			const transformedData = response.data._data_.map((item) => ({
				gmina_id: item.id,
				gmina_nazwa: item.nazwa,
			}));
			if (selectedGminaId === 'all' || selectedGminaId === null) {
				localStorage.setItem('gmina_id', JSON.stringify(transformedData));
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};
	

	useEffect(() => {
		fetchData();
	}, []);

	const clearForm = () => {
		setNewGmina({
			id: '',
			nazwa: '',
		});
	};

	const closeModal = () => {
		if (isEditMode) {
			clearForm();
			setIsEditMode(false);
		}
		setIsModalOpen(false);
		setIsModalDeleteOpen(false);
		setDeletingRow();
	};

	const handleEditClick = (row) => {
		setNewGmina({
			id: row.id,
			nazwa: row.nazwa,
		});
		setIsEditMode(true);
		setIsModalOpen(true);
	};

	const handleEditGmina = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const gminaData = {
				id: newGmina.id,
				nazwa: newGmina.nazwa,
			};

			const addGmina = {
				action: 'edit-gmina',
				_data_: gminaData,
			};

			try {
				await sendRequest(addGmina);
				closeModal();
				clearForm();
				fetchData();
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const handleDeleteGmina = async () => {
		const deleteGmina = {
			action: 'delete-gmina',
			id: deletingRow.id,
		};

		try {
			await sendRequest(deleteGmina);
			closeModal();

			try {
				await fetchData();
				window.location.reload();
			} catch (fetchError) {
				console.error('Error fetching gminy after delete:', fetchError);
			}
		} catch (error) {
			console.error('Error deleting gmina:', error);
		}
	};

	const handleAddGmina = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const gminaData = {
				nazwa: newGmina.nazwa,
			};

			const addGmina = {
				action: 'add-gmina',
				_data_: gminaData,
			};

			try {
				const response = await sendRequest(addGmina);
				if(response.data.status == 2){
					setErrors({
						nazwa: 'Gmina o tej nazwie już istnieje',
					})
				} else {
					closeModal();
					clearForm();
					setErrors({
						nazwa: '',
					})
	
					const getGminy = {
						action: 'get-gminy',
					};
	
					try {
						const response = await sendRequest(getGminy);
						if (response) {
							const transformedData = response.data._data_.map(
								(item) => ({
									gmina_id: item.id,
									gmina_nazwa: item.nazwa,
								})
							);
							localStorage.setItem(
								'gmina_id',
								JSON.stringify(transformedData)
							);
	
							window.location.reload();
						}
					} catch (error) {
						console.error('Error fetching gminy:', error);
					}
				}
			} catch (error) {
				console.error('Error adding gmina:', error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const validateForm = () => {
		let isValid = true;
		let newErrors = {
			nazwa: '',
		};

		for (const key in newGmina) {
			if (key === 'id') continue;

			if (newGmina[key] === '' || newGmina[key].length === 0) {
				newErrors[key] = 'To pole jest wymagane';
				isValid = false;
			}
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewGmina((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSelectChange = async (selectedOption, id) => {
		setIsLoadingRequest(true);
		try {
			await sendRequest({
				action: 'update-edit-lock',
				_data_: { id, status: selectedOption.value },
			});
			fetchData();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoadingRequest(false);
		}
	};

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '15px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							handleEditClick(row);
						}}
					/>
					<Icozamknij
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							setDeletingRow(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
		{
			name: 'Nazwa',
			selector: (row) => row.nazwa,
			grow: 10,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Postępowanie zakończone',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}>
					<Select
						placeholder="Wybierz..."
						value={selectOptions.find(
							(option) => option.value === row.edit_lock
						)}
						onChange={(selectedOption) =>
							handleSelectChange(selectedOption, row.id)
						}
						options={selectOptions}
						styles={customStyles}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
	];

	const exportToExcel = () => {
		const dataToExport = data.map((item) => ({
			'Nazwa Gminy': item.nazwa,
			'Postępowanie Zakończone': item.edit_lock === 1 ? 'Tak' : 'Nie',
		}));

		const ws = XLSX.utils.json_to_sheet(dataToExport);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Gminy');

		const exportFileName = `Uczestnicy_Grupy_Zakupowej_${new Date().toLocaleDateString()}.xlsx`;

		XLSX.writeFile(wb, exportFileName);
	};

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	if (!isAccessVerified) {
		return null;
	}

	return (
		<GminaLayout>
			<h2 className="page-title">Uczestnicy Grupy Zakupowej</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={
						data && data.length > 0
							? data.filter((customer) =>
									Object.values(customer).some((value) =>
										String(value)
											.toLowerCase()
											.includes(searchValue.toLowerCase())
									)
							  )
							: []
					}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					defaultSortFieldId={2}
					paginationPerPage={20}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<div className="table-menu">
					<Button
						className="add-button"
						onClick={() => setIsModalOpen(true)}
						style={{
							fontSize: '14px',
						}}>
						Dodaj uczestnika
					</Button>
					<input
						placeholder="Szukaj..."
						className="search-input"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<Button
						style={{
							fontSize: '14px',
							marginBottom: '10px',
						}}
						onClick={exportToExcel}>
						Eksportuj do Excela
					</Button>
				</div>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{isEditMode ? (
							<h2>Edytuj Uczestnika</h2>
						) : (
							<h2>Dodaj Uczestnika</h2>
						)}
						<div>
							<label>Nazwa Uczestnika:</label>
							<input
								name="nazwa"
								placeholder="Nazwa Uczestnika"
								value={newGmina.nazwa}
								className="input-form"
								onChange={handleInputChange}
							/>

							<p className="err-msg">{errors.nazwa}</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{isEditMode ? (
								<Button
									className="add"
									onClick={handleEditGmina}>
									Zapisz
								</Button>
							) : (
								<Button
									className="add"
									onClick={handleAddGmina}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć uczestnika:</h2>
						<h2>{deletingRow.nazwa} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeleteGmina()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</GminaLayout>
	);
};

export default Gmina;
