import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
	Navigate,
} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './themes';
import GlobalStyle from './globalStyles';
import Root from './Root';
import Home from './pages/Home/Home';
import Users from './pages/Users/Users';
import Settings from './pages/Settings/Settings';
import NoAuth from './pages/NoAuth/NoAuth';
import Regions from './pages/Regions/Regions';
import Tariffs from './pages/Tariffs/Tariffs';
import AdminDocuments from './pages/AdminDocuments/AdminDocuments';
import Documents from './pages/Documents/Documents';
import Customers from './pages/Customers/Customers';
import Recipients from './pages/Recipients/Recipients';
import Sellers from './pages/Sellers/Sellers';
import PPE from './pages/PPE/PPE';
import OSD from './pages/OSD/OSD';
import Gmina from './pages/Gmina/Gmina';
import Document from './pages/Document/Document';
import Zalacznik2Kompleksowa from './pages/Zalacznik2/Zalacznik2Kompleksowa/Zalacznik2Kompleksowa';
import Zalacznik2Rozdzielona from './pages/Zalacznik2/Zalacznik2Rozdzielona/Zalacznik2Rozdzielona';
import Zalacznik1 from './pages/Zalacznik1/Zalacznik1';
import Stawka from './pages/Stawka/Stawka';
import ArchivalDocuments from './pages/ArchivalDocuments/ArchivalDocuments';
import ViewDocument from './pages/ViewDocument/ViewDocument';
import DocumentsAnalyser from './pages/DocumentsAnalyser/DocumentsAnalyser';
import ImportDataPage from './pages/ImportDataPage/ImportDataPage';
import ImportZalPage from './pages/ImportZalPage/ImportZalPage';

const App = () => {
	const storedThemeName = localStorage.getItem('selected-theme');
	const [theme, setTheme] = useState(
		storedThemeName === 'dark' ? darkTheme : lightTheme
	);

	const toggleTheme = () => {
		if (theme.name === 'dark') {
			setTheme(lightTheme);
		} else {
			setTheme(darkTheme);
		}
	};

	useEffect(() => {
		localStorage.setItem('selected-theme', theme.name);
	}, [theme]);

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Root toggleTheme={toggleTheme} />}>
				<Route index element={<Navigate to="/dashboard" replace />} />
				<Route path="/dashboard" element={<Home />} />
				<Route path="/admin-users-admin" element={<Users />} />
				<Route path="/admin-settings" element={<Settings />} />
				<Route path="/noauth" element={<NoAuth />} />
				<Route path="/admin-regions" element={<Regions />} />
				<Route path="/nabywcy" element={<Customers />} />
				<Route path="/admin-documents" element={<AdminDocuments />} />
				<Route path="/documents" element={<Documents />} />
				<Route path="/odbiorcy" element={<Recipients />} />
				<Route path="/sprzedawcy" element={<Sellers />} />
				<Route path="/taryfy" element={<Tariffs />} />
				<Route path="/stawka" element={<Stawka />} />
				<Route path="/ppe" element={<PPE />} />
				<Route path="/osd" element={<OSD />} />
				<Route path="/gminy" element={<Gmina />} />
				<Route path="/document/:id" element={<Document />} />
				<Route path="/zalacznik2/kompleksowa" element={<Zalacznik2Kompleksowa />} />
				<Route path="/zalacznik2/rozdzielona" element={<Zalacznik2Rozdzielona />} />
				<Route path="/zalacznik1" element={<Zalacznik1 />} />
				<Route path="/admin-documents-history" element={<ArchivalDocuments />} />
				<Route path="/admin-documents-history/document/:id" element={<ViewDocument />} />
				<Route path="/pdf" element={<DocumentsAnalyser />} />
				<Route path="/import-data" element={<ImportDataPage />} />
				<Route path="/import-zal" element={<ImportZalPage />} />
				<Route path="*" element={<Navigate to="/dashboard" replace />}/>
			</Route>
		)
	);

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<GlobalStyle />
				<RouterProvider router={router} />
			</div>
		</ThemeProvider>
	);
};

export default App;
