import axios from 'axios';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

export async function sendRequest(request) {
	const apiUrl = process.env.REACT_APP_API_URL;
	const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
	let token = localStorage.getItem('token');

	if (token) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		try {
			const testResponse = await axios.post(
				apiUrl,
				{ action: 'check-token' },
				{
					headers: { 'Content-Type': 'application/json' },
				}
			);
			if (testResponse.data.status === 2) {
				const isSavedPass = localStorage.getItem('savepass') === 'true';
				const encryptedLogin = Cookies.get('user_data');
                const encryptedPassword = Cookies.get('session_data');

                if (encryptedLogin && encryptedPassword) {
                    const bytesLogin = CryptoJS.AES.decrypt(encryptedLogin, SECRET_KEY);
                    const bytesPassword = CryptoJS.AES.decrypt(encryptedPassword, SECRET_KEY);
                    const login = bytesLogin.toString(CryptoJS.enc.Utf8);
                    const password = bytesPassword.toString(CryptoJS.enc.Utf8);

                    const loginPayload = {
                        action: 'login',
                        username: login,
                        password: password,
                    };
                    try {
                        const response = await axios.post(apiUrl, loginPayload, {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        if (response.data.status === 1) {
                            token = response.data.JWT;
                            localStorage.setItem('token', token);
                            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                        }
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    console.error('No saved credentials found in cookies');
                }
			} else if (
				testResponse.data.status === 0 &&
				testResponse.data.message === 'Error: Bad token!'
			) {
				console.log('Error: Bad token!');
				localStorage.removeItem('token');
				window.location.reload();
			}
		} catch (error) {
			token = null;
		}
	}

    try {
        return await axios.post(apiUrl, request, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        alert(`Request error: ${error.response ? error.response.data : error.message}`);
        throw error;
    }
}
