import { useTheme } from 'styled-components';

export const useStyledSelect = () => {
	const theme = useTheme();

	return {
		control: (base) => ({
			...base,
			maxHeight: 120,
			minHeight: 38,
			color: theme.fontcolor,
			backgroundColor: theme.white,
		}),
		valueContainer: (base) => ({
			...base,
			overflowY: 'auto',
			minWidth: '125px',
			maxWidth: '310px',
			maxHeight: 116,
			color: theme.fontcolor,
		}),
        input: (base) => ({
            ...base,
            color: theme.fontcolor,
        }),
		singleValue: (base) => ({
			...base,
			color: theme.fontcolor,
		}),
		multiValue: (base) => ({
			...base,
		}),
		menuList: (provided) => ({
			...provided,
			maxHeight: '200px',
			overflow: 'auto',
			backgroundColor: theme.white,
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: theme.white,
		}),
		option: (provided, state) => ({
			...provided,
			padding: '4px 12px',
			color: theme.fontcolor,
			...(state.isFocused
				? {
						backgroundColor: theme.expandedTableBackgroundColor,
						color: theme.fontcolor,
				  }
				: {}),
		}),
	};
};
