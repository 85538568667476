import { useEffect, useRef, useState } from 'react';
import { sendRequest } from '../../api/sendRequest';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { SettingsLayout } from './Settings.style';
import { Button } from '../../components/Button/Button';
import useAccessControl from '../../hooks/useAccessControl';
import LoadingComponent from '../../components/Table/LoadingComponent';

const Settings = () => {
	const isAccessVerified = useAccessControl();
	const [userData, setUserData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isInviteDisabled, setIsInviteDisabled] = useState(false);
	const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
	const [isRemindModalOpen, setIsRemindModalOpen] = useState(false);
	const [msg, setMsg] = useState('');
	const modalContentRef = useRef();

	useEffect(() => {
		const getConfigsRequest = {
			action: 'get-config',
		};

		sendRequest(getConfigsRequest)
			.then((configsResponse) => {
				setUserData(configsResponse.data._data_.results);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	}, []);

	const handleChange = (event, parameter) => {
		setUserData((prevState) =>
			prevState.map((data) =>
				data.parameter === parameter
					? { ...data, value: event.target.value }
					: data
			)
		);
	};

	const handleSave = () => {
		const saveConfigRequest = {
			action: 'save-config',
			_data_: userData.reduce(
				(acc, data) => ({ ...acc, [data.parameter]: data.value }),
				{}
			),
		};
	
		sendRequest(saveConfigRequest)
			.then(() => {
				setMsg('Zapisano');
			})
			.catch((error) => {
				console.error(error);
				setMsg('Zapis nie powiódł się');
			});
	};
	

	const handleSendInvitation = async () => {
		const sendInvitation = {
			action: 'send-invitation',
		};

		try {
			const response = await sendRequest(sendInvitation);
			if (response.data && response.data.status == 1) {
				setIsInviteDisabled(true);
				setMsg('Zaproszenia gotowe w kolejce do wysłania.');
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsInviteModalOpen(false);
		}
	};

	const handleSendRemind = async () => {
		const sendRemind = {
			action: 'send-remind',
		};

		try {
			const response = await sendRequest(sendRemind);
			if (response.data && response.data.status == 1) {
				setIsRemindModalOpen(true);
				setMsg('Przypomnienia gotowe w kolejce do wysłania.');
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsRemindModalOpen(false);
		}
	};

	const columns = [
		{
			name: 'Parametr',
			selector: (row) => row.parameter,
			grow: 1,
		},
		{
			name: 'Wartość',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '15px' }}>
					<input
						type="text"
						value={row.value}
						onChange={(event) => handleChange(event, row.parameter)}
					/>
				</div>
			),
			grow: 1,
		},
		{
			name: 'Data modyfikacji',
			selector: (row) => row.mtime,
			grow: 2,
		},
	];

	if (!isAccessVerified) {
		return null;
	}
	return (
		<SettingsLayout>
			<h2 className="page-title">Ustawienia</h2>
			<div className="table-container">
				<StyledDataTable
					columns={columns}
					data={userData}
					progressPending={isLoading}
					noDataComponent="Brak danych spełniających kryteria"
					progressComponent={<LoadingComponent />}
				/>
				<div
					style={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'space-between',
					}}>
					<Button
						className="action-button"
						onClick={handleSave}
						style={{
							fontSize: '16px',
						}}>
						Zapisz
					</Button>
					<p>{msg}</p>
					<div className='buttons'>
						<Button
							className={`action-button`}
							onClick={() => setIsRemindModalOpen(true)}>
							Wyślij Przypomnienie
						</Button>
						<Button
							className={`action-button ${
								isInviteDisabled ? 'disabled' : ''
							}`}
							onClick={() => setIsInviteModalOpen(true)}
							disabled={isInviteDisabled}>
							Wyślij Zaproszenie
						</Button>
					</div>
				</div>
			</div>
			{isInviteModalOpen && (
				<div
					onMouseDown={() => setIsInviteModalOpen(false)}
					className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz wysłać zaproszenia?</h2>
						<div className="modal-actions">
							<Button
								className="cancel"
								onClick={() => setIsInviteModalOpen(false)}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={handleSendInvitation}>
								Wyślij
							</Button>
						</div>
					</div>
				</div>
			)}
			{isRemindModalOpen && (
				<div
					onMouseDown={() => setIsRemindModalOpen(false)}
					className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz wysłać przypomnienia?</h2>
						<div className="modal-actions">
							<Button
								className="cancel"
								onClick={() => setIsRemindModalOpen(false)}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={handleSendRemind}>
								Wyślij
							</Button>
						</div>
					</div>
				</div>
			)}
		</SettingsLayout>
	);
};

export default Settings;
