import React, { useEffect, useState } from 'react';
import { sendRequest } from '../../api/sendRequest';
import Loading from '../../components/Loading/Loading';
import ReactECharts from 'echarts-for-react';
import { HomeLayout } from './Home.style';
import { useTheme } from 'styled-components';
import { ReactComponent as Logo } from './../../svg/logo.svg';
import { jwtDecode } from 'jwt-decode';

const Home = () => {
	const theme = useTheme();
	const [ppeByYear, setPpeByYear] = useState([]);
	const [energyUsageByYear, setEnergyUsageByYear] = useState([]);
	const [odbiorcyByYear, setOdbiorcyByYear] = useState([]);
	const [ppeBySg, setPpeBySg] = useState([]);
	const [energyUsageBySg, setEnergyUsageBySg] = useState([]);
	const [confirmedCustomers, setConfirmedCustomers] = useState([]);
	const [dashboardData, setDashboardData] = useState([]);
	const [checkShoppingGroup, setCheckShoppingGroup] = useState([]);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const routeLinks = JSON.parse(localStorage.getItem('routes') || '[]');
	const isVisibleForAdmin = routeLinks.some(
		(link) => link.menu_key === 'admin-settings' && link.visible === 1
	);

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			try {
				const decoded = jwtDecode(token);
				if (decoded.adm == 1) {
					setIsAdmin(true);
				}
			} catch (error) {
				console.error('Failed to decode token', error);
			}
		}

		const fetchData = async (request, setState) => {
			try {
				const response = await sendRequest(request);
				let data = response.data._data_;
				if (request.action === 'get-confirmed-customers') {
					const processedData = processConfirmedCustomers(data);
					setState(processedData);
				} else if (request.action === 'gmina-dashboard') {
					setState(response.data.status);
				} else if (request.action === 'get-ppe-by-sg' || request.action === 'get-energy-usage-by-sg') {
					const modifiedData = modifyShoppingGroupNames(data);
					setState(modifiedData);
				} else {
					setState(data);
				}
				} catch (error) {
				console.error(
					'Błąd podczas wykonywania akcji',
					request.action,
					':',
					error
				);
			}
		};

		const requests = [
			{ action: 'get-ppe-by-year', setState: setPpeByYear },
			{
				action: 'get-energy-usage-by-year',
				setState: setEnergyUsageByYear,
			},
			{ action: 'get-odbiorca-by-year', setState: setOdbiorcyByYear },
			{ action: 'get-ppe-by-sg', setState: setPpeBySg },
			{ action: 'get-energy-usage-by-sg', setState: setEnergyUsageBySg },
			{
				action: 'get-confirmed-customers',
				setState: setConfirmedCustomers,
			},
			{
				action: 'gmina-dashboard',
				setState: setDashboardData,
			},
			{
				action: 'check-shopping-group-by-ppe',
				setState: setCheckShoppingGroup,
			},
		];

		Promise.all(
			requests.map(({ action, setState }) =>
				fetchData({ action }, setState)
			)
		).finally(() => setIsLoading(false));
	}, []);

	const modifyShoppingGroupNames = (data) => {
		return data.map(item => {
			if (item.shopping_group_name === "Umowa Kompleksowa") {
				item.shopping_group_name = "Grupa zakupowa kompleksowa";
			} else if (item.shopping_group_name === "Umowa Sprzedaży EE (rodzielona)") {
				item.shopping_group_name = "Grupa zakupowa rozdzielona";
			}
			return item;
		});
	};

	const getOption = (
		data,
		title,
		xAxisName,
		yAxisName,
		yAxisTitle,
		isPieChart = false
	) => {
		const axisOption = {
			type: 'value',
			name: yAxisTitle,
			nameRotate: 90,
			nameLocation: 'middle',
			nameTextStyle: {
				color: theme.fontcolor,
				fontSize: 16,
				align: 'center',
				verticalAlign: 'middle',
				padding: [0, 0, 0, 0],
			},
			nameGap: 50,
			axisLabel: {
				textStyle: {
					color: theme.fontcolor,
				},
			},
		};

		if (isPieChart) {
			return {
				title: {
					text: title,
					left: 'center',
					textStyle: {
						color: theme.fontcolor,
					},
				},
				tooltip: {
					trigger: 'item',
				},
				series: [
					{
						name: title,
						type: 'pie',
						radius: '55%',
						data: data.map((item) => ({
							value: item[yAxisName],
							name: item[xAxisName],
						})),
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)',
							},
						},
						label: {
							show: true,
							fontSize: '16',
							formatter: function (params) {
								let label = params.name;
								return label + '\n' + params.percent + '%';
							},
							color: theme.fontcolor,
							rich: {
								name: {
									fontSize: 14,
									color: theme.fontcolor,
									lineHeight: 16,
								},
							},
						},
					},
				],
			};
		}

		return {
			title: {
				text: title,
				textAlign: 'center',
				left: '50%',
				textStyle: {
					color: theme.fontcolor,
				},
			},
			tooltip: {},
			xAxis: {
				type: 'category',
				data: data.map((item) => item[xAxisName]),
				axisLabel: {
					interval: 0,
					formatter: function (value) {
						const words = value.split(' ');
						const maxLength = 20;
						let result = '';
						let line = '';

						for (let i = 0; i < words.length; i++) {
							if (line.length + words[i].length > maxLength) {
								result += line + '\n';
								line = words[i];
							} else {
								line += (line.length > 0 ? ' ' : '') + words[i];
							}
						}

						result += line;
						return result;
					},
					textStyle: {
						color: theme.fontcolor,
					},
				},
			},
			yAxis: axisOption,
			series: [
				{
					data: data.map((item) => item[yAxisName]),
					type: 'bar',
					itemStyle: {
						color: '#fee45b',
					},
				},
			],
		};
	};

	const processConfirmedCustomers = (data) => {
		const active =
			data.find((item) => item.edit_lock === 0)?.customers || 0;
		const resigned =
			data.find((item) => item.edit_lock === 2)?.customers || 0;
		const notConfirmed = data[0]?.customers_not_confirmed || 0;

		const processedData = [
			{ category: 'Aktywni', count: active },
			{ category: 'Rezygnacja', count: resigned },
			{ category: 'Niepotwierdzone zgłoszenia', count: notConfirmed },
		];

		return processedData;
	};

const formatNumber = (number) => {
	const formatter = new Intl.NumberFormat('pl-PL', { useGrouping: true });
	return formatter.format(Number(number));
  };
  

	if (isLoading) {
		return <Loading />;
	}

	return (
		<HomeLayout>
			{isAdmin ? (
				<>
					<div className="chart">
						<ReactECharts
							option={getOption(
								ppeByYear,
								'Porównanie ilości punktów poboru energii',
								'ppe_date',
								'ppe_count',
								'Ilość PPE'
							)}
						/>
					</div>
					<div className="chart">
						<ReactECharts
							option={getOption(
								energyUsageByYear,
								'Porównanie wolumenu energii w zamówieniu',
								'usage_year',
								'energy_year',
								'Wolumen GWh/rok'
							)}
						/>
					</div>
					<div className="chart">
						<ReactECharts
							option={getOption(
								odbiorcyByYear,
								'Porównanie ilości odbiorców',
								'odbiorca_year',
								'odbiorca_count',
								'Ilość odbiorców'
							)}
						/>
					</div>
					<div className="chart">
						<ReactECharts
							option={getOption(
								ppeBySg,
								'Porównanie ilości punktów poboru energii ze względu na grupę',
								'shopping_group_name',
								'ppe_count',
								'Ilość PPE',
								true
							)}
						/>
					</div>
					<div className="chart">
						<ReactECharts
							option={getOption(
								energyUsageBySg,
								'Porównanie wolumenu energii w zamówieniu ze względu na grupę',
								'shopping_group_name',
								'energy_count',
								'Wolumen GWh/rok',
								true
							)}
						/>
					</div>
					{isVisibleForAdmin && (
						<div className="chart">
							<ReactECharts
								option={getOption(
									confirmedCustomers,
									'Uczestnicy grupy zakupowej',
									'category',
									'count',
									'',
									true
								)}
							/>
						</div>
					)}
				</>
			) : (
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
				<div className="user-container">
					<div className="info-container">
						{checkShoppingGroup?.find((item) => item.shopping_group_id == '1')?.shopping_group_enabled && 
						(<div className='info kompleksowa-info'>
							<h2>{dashboardData.stawka_termin?.find((item) => item.shopping_group_name === 'Umowa Kompleksowa')?.shopping_group_name}</h2>
							<div>
								<table>
									<tr>
										<th colSpan="3">Cena EE na rok - {dashboardData.rok_EE}</th>
									</tr>
									<tr>
										<th>EE Bxx</th>
										<th>EE C1x</th>
										<th>EE C2x</th>
									</tr>
									<tr>
										<th>{dashboardData.cena_EE.find(item => item.parameter == 'GZK_cena_EE_Bxx').value} zł netto/kWh</th>
										<th>{dashboardData.cena_EE.find(item => item.parameter == 'GZK_cena_EE_C1x').value} zł netto/kWh</th>
										<th>{dashboardData.cena_EE.find(item => item.parameter == 'GZK_cena_EE_C2x').value} zł netto/kWh</th>
									</tr>
								</table>
								<p>Termin płatność za EE <span>{dashboardData.termin_platnosci} dni</span> </p>
								<p>Termin złożenia dokumentów w OUiD <span>{dashboardData.stawka_termin?.find((item) => item.shopping_group_name === 'Umowa Kompleksowa')?.termin_przekazania}</span> </p>
								<p>Liczba zgłoszonych PPE <span>{dashboardData.liczba_ppe?.find((item) => item.shopping_group_id == '1')?.ilosc_ppe}</span> </p>
							</div>
							<div>
								<p>Planowane zużycie na kolejny rok <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Kompleksowa')?.suma_zone || '0')} kWh</span> </p>
								<p>Strefa 1 <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Kompleksowa')?.zone1 || '0')} kWh</span> </p>
								<p>Strefa 2 <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Kompleksowa')?.zone2 || '0')} kWh</span> </p>
								<p>Strefa 3 <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Kompleksowa')?.zone3 || '0')} kWh</span> </p>
								<p>Suma <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Kompleksowa')?.suma_zone || '0')} kWh</span> </p>
							</div>
						</div>)}
						{checkShoppingGroup?.find((item) => item.shopping_group_id == '2')?.shopping_group_enabled && 
						(<div className='info rozdzielona-info'>
						<h2>{dashboardData.stawka_termin?.find((item) => item.shopping_group_name === 'Umowa Sprzedaży EE (rodzielona)')?.shopping_group_name}</h2>
							<div>
								<table>
									<tr>
										<th colSpan="3">Cena EE na rok - {dashboardData.rok_EE}</th>
									</tr>
									<tr>
										<th>EE Bxx</th>
										<th>EE C1x</th>
										<th>EE C2x</th>
									</tr>
									<tr>
										<th>{dashboardData.cena_EE.find(item => item.parameter == 'GZR_cena_EE_Bxx').value} zł netto/kWh</th>
										<th>{dashboardData.cena_EE.find(item => item.parameter == 'GZR_cena_EE_C1x').value} zł netto/kWh</th>
										<th>{dashboardData.cena_EE.find(item => item.parameter == 'GZR_cena_EE_C2x').value} zł netto/kWh</th>
									</tr>
								</table>
								<p>Termin płatność za EE <span>{dashboardData.termin_platnosci} dni</span> </p>
								<p>Termin złożenia dokumentów w OUiD <span>{dashboardData.stawka_termin?.find((item) => item.shopping_group_name === 'Umowa Sprzedaży EE (rodzielona)')?.termin_przekazania}</span> </p>
								<p>Liczba zgłoszonych PPE <span>{dashboardData.liczba_ppe?.find((item) => item.shopping_group_id == '2')?.ilosc_ppe}</span> </p>
							</div>
							<div>
								<p>Planowane zużycie na kolejny rok <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Sprzedaży EE (rodzielona)')?.suma_zone || '0')} kWh</span> </p>
								<p>Strefa 1 <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Sprzedaży EE (rodzielona)')?.zone1 || '0')} kWh</span> </p>
								<p>Strefa 2 <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Sprzedaży EE (rodzielona)')?.zone2 || '0')} kWh</span> </p>
								<p>Strefa 3 <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Sprzedaży EE (rodzielona)')?.zone3 || '0')} kWh</span> </p>
								<p>Suma <span>{formatNumber(dashboardData.uzycie?.find((item) => item.shopping_group_name === 'Umowa Sprzedaży EE (rodzielona)')?.suma_zone || '0')} kWh</span> </p>
							</div>
						</div>)}
					</div>
					<div className='contact'>
						<div className='contact-content'>						
							<p>Pytania i pomoc w obsłudze platformy GZE:</p>
							<p>{dashboardData.uzytkownik_1}</p>
							<p>{dashboardData.uzytkownik_2}</p>
							<p>E-mail: {dashboardData.email_kontakt}</p>
						</div>
						<div>
							<div className='footer-container'>
								<div>
									<Logo/>
								</div>
								<div className='footer'>
									<p>OŚWIETLENIE</p>
									<p>ULICZNE I DROGOWE SP. Z O. O.</p>
									<p>ul. Wrocławska 71A, 62-800 Kalisz</p>
									<p><a href="http://www.oswietlenie.kalisz.pl" target="_blank">www.oswietlenie.kalisz.pl</a></p>
									<p>Tel. 62 598-52-70</p>
									<p><a href="mailto:poczta@ouid.pl">E-mail poczta@ouid.pl</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p className='note'>Zgodnie z Rozporządzeniem Parlamentu i Rady Unii Europejskiej Administratorem Twoich Danych Osobowych będzie Oświetlenie Uliczne i Drogowe sp. z o.o. z siedzibą w Kaliszu. Administrator powołał w swoich strukturach Inspektora Ochrony Danych Osobowych. Więcej informacji na temat polityki ochrony danych osobowych znajdziesz na naszej stronie internetowej pod adresem www.oswietlenie.kalisz.pl w zakładce Ochrona Danych Osobowych.</p>
				</div>
			)}
		</HomeLayout>
	);
};

export default Home;
