import { useState, useRef, useEffect } from 'react';
import useAccessControl from '../../hooks/useAccessControl';
import { TariffsLayout } from './Tariffs.style';
import { Button } from '../../components/Button/Button';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { TariffsDataTable } from './Tariffs.style';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { useTheme } from 'styled-components';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import { useNavigate } from 'react-router-dom';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';

const Tariffs = () => {
	const customStyles = useStyledSelect();
	const navigate = useNavigate();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const isAccessVerified = useAccessControl();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [deletingTariff, setDeletingTariff] = useState([]);
	const [dateRange, setDateRange] = useState([new Date(), new Date()]);
	const [startDate, setStartDate] = useState('');
	const [dateError, setDateError] = useState('');
	const [stopDate, setStopDate] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [tariffs, setTariffs] = useState([]);
	const [tariffId, setTariffId] = useState('');
	const [tariffName, setTariffName] = useState('');
	const [tariffShoppingGroup, setTariffShoppingGroup] = useState('');
	const [groups, setGroups] = useState('');
	const [tariffStrefa, setTariffStrefa] = useState('');
	const [tariffEnergy, setTariffEnergy] = useState({
		energy_1: 0,
		energy_2: 0,
		energy_3: 0,
		// energy_4: 0,
		// energy_5: 0,
		// energy_6: 0,
	});
	const [tariffEnergyError, setTariffEnergyError] = useState('');
	const [tariffNameError, setTariffNameError] = useState('');
	const [tariffStrefaError, setTariffStrefaError] = useState('');
	const strefy = [
		{
			value: 1,
			label: 'Strefa 1',
		},
		{
			value: 2,
			label: 'Strefa 2',
		},
		{
			value: 3,
			label: 'Strefa 3',
		},
		// {
		// 	value: 4,
		// 	label: 'Strefa 4',
		// },
		// {
		// 	value: 5,
		// 	label: 'Strefa 5',
		// },
		// {
		// 	value: 6,
		// 	label: 'Strefa 6',
		// },
	];

	const fetchData = async () => {
		try {
			const getTariffs = {
				action: 'get-taryfy',
			};
			const getShoppingGroup = {
				action: 'get-shopping-group',
			};

			const [getTariffsResponse, getShoppingGroupResponse] =
				await Promise.all([
					sendRequest(getTariffs),
					sendRequest(getShoppingGroup),
				]);
			setTariffs(getTariffsResponse.data._data_);
			setGroups(
				getShoppingGroupResponse.data._data_.map((group) => ({
					value: group.id,
					label: group.name,
				}))
			);
			setIsLoading(false);
			setIsLoadingRequest(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const [start, end] = dateRange.map(
			(dateString) => new Date(dateString)
		);
		setStartDate(formatDate(start));
		setStopDate(formatDate(end));
	}, [dateRange]);

	useEffect(() => {
		fetchData();
	}, []);

	if (!isAccessVerified) {
		return null;
	}

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '15px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							handleEditClick(row);
						}}
					/>
					<Icozamknij
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							setDeletingTariff(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
		{
			name: 'Nazwa',
			selector: (row) => row.nazwa,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Stawka 1',
			selector: (row) => (row.energy_1 === 0 ? '' : row.energy_1),
			sortable: true,
			sortField: '0',
			minWidth: '130px',
			maxWidth: '130px',
		},
		{
			name: 'Stawka 2',
			selector: (row) => (row.energy_2 === 0 || '0' ? '' : row.energy_2),
			sortable: true,
			sortField: '0',
			minWidth: '130px',
			maxWidth: '130px',
		},
		{
			name: 'Stawka 3',
			selector: (row) => (row.energy_3 === 0 ? '' : row.energy_3),
			sortable: true,
			sortField: '0',
			minWidth: '130px',
			maxWidth: '130px',
		},
		// {
		// 	name: 'Stawka 4',
		// 	selector: (row) => (row.energy_4 === 0 ? '' : row.energy_4),
		// 	sortable: true,
		// 	sortField: '0',
		// 	minWidth: '130px',
		// 	maxWidth: '130px',
		// },
		// {
		// 	name: 'Stawka 5',
		// 	selector: (row) => (row.energy_5 === 0 ? '' : row.energy_5),
		// 	sortable: true,
		// 	sortField: '0',
		// 	minWidth: '130px',
		// 	maxWidth: '130px',
		// },
		// {
		// 	name: 'Stawka 6',
		// 	selector: (row) => (row.energy_6 === 0 ? '' : row.energy_6),
		// 	sortable: true,
		// 	sortField: '0',
		// 	minWidth: '130px',
		// 	maxWidth: '130px',
		// },
		{
			name: 'Strefa',
			selector: (row) => row.strefa,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Grupa',
			selector: (row) => row.shopping_group_nazwa,
			grow: 5,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Data rozpoczęcia',
			selector: (row) => row.start.slice(0, 10),
			grow: 3,
			sortable: true,
			sortField: '1',
		},
		{
			name: 'Data zakończenia',
			selector: (row) => row.stop.slice(0, 10),
			grow: 3,
			sortable: true,
			sortField: '2',
		},
	];

	function formatDate(date) {
		return `${date.getFullYear()}-${(date.getMonth() + 1)
			.toString()
			.padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
	}

	const validateTariff = () => {
		let isValid = true;

		if (!tariffName) {
			setTariffNameError('Nazwa jest wymagana.');
			isValid = false;
		} else {
			setTariffNameError('');
		}

		if (!tariffEnergy) {
			setTariffEnergyError('Stawka jest wymagana.');
			isValid = false;
		} else {
			setTariffEnergyError('');
		}

		if (!tariffStrefa) {
			setTariffStrefaError('Strefa jest wymagana.');
			isValid = false;
		} else {
			setTariffStrefaError('');
		}

		if (startDate === '1970-01-01' || stopDate === '1970-01-01') {
			setDateError('Pełen zakres dat jest wymagany.');
			isValid = false;
		} else {
			if (startDate > stopDate) {
				setDateError('Data start jest późniejsza niż data stop.');
				isValid = false;
			} else {
				if (startDate === stopDate) {
					setDateError('Daty start i stop muszą być różne.');
					isValid = false;
				} else {
					setDateError('');
				}
			}
		}

		for (let i = 1; i <= tariffStrefa.value; i++) {
			const energyValue = parseFloat(tariffEnergy[`energy_${i}`]);

			if (isNaN(energyValue) || energyValue <= 0) {
				setTariffStrefaError(`Stawki muszą być większe od 0.`);
				isValid = false;
				break;
			} else {
				setTariffStrefaError('');
			}
		}

		return isValid;
	};

	const clearForm = () => {
		setTariffId('');
		setTariffName('');
		setDateRange([new Date(), new Date()]);
		setTariffStrefa('');
		setTariffEnergy({
			energy_1: 0,
			energy_2: 0,
			energy_3: 0,
			// energy_4: 0,
			// energy_5: 0,
			// energy_6: 0,
		});
		setTariffStrefaError('');
		setTariffNameError('');
		setDateError('');
	};
	const closeModal = () => {
		setIsModalOpen(false);
		setIsEditMode(false);
		setIsModalDeleteOpen(false);
		clearForm();
	};

	const handleEditClick = async (row) => {
		try {
			setTariffId(row.id);
			setTariffName(row.nazwa);
			setTariffEnergy({
				energy_1: row.energy_1,
				energy_2: row.energy_2,
				energy_3: row.energy_3,
				// energy_4: row.energy_4,
				// energy_5: row.energy_5,
				// energy_6: row.energy_6,
			});
			setStartDate(row.start);
			setStopDate(row.stop);
			const foundStrefa = strefy.find(
				(item) => item.value === row.strefa
			);
			setTariffStrefa(foundStrefa);
			setDateRange([new Date(row.start), new Date(row.stop)]);
			const foundShoppingGroup = groups.find(
				(item) => item.label === row.shopping_group_nazwa
			);
			setTariffShoppingGroup(foundShoppingGroup);

			setIsModalOpen(true);
			setIsEditMode(true);
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddTariff = async () => {
		const isValid = validateTariff();
		if (isValid) {
			setIsLoadingRequest(true);
			const addTariff = {
				action: 'add-taryfa',
				_data_: {
					nazwa: tariffName,
					shopping_group_id: tariffShoppingGroup.value,
					energy_1: tariffEnergy.energy_1 || 0,
					energy_2: tariffEnergy.energy_2 || 0,
					energy_3: tariffEnergy.energy_3 || 0,
					// energy_4: tariffEnergy.energy_4 || 0,
					// energy_5: tariffEnergy.energy_5 || 0,
					// energy_6: tariffEnergy.energy_6 || 0,
					strefa: tariffStrefa.value,
					start: startDate,
					stop: stopDate,
				},
			};
			try {
				await sendRequest(addTariff);
			} catch (error) {
				console.error(error);
			} finally {
				closeModal();
			}
			await fetchData();
		}
	};

	const handleEditTariff = async () => {
		const isValid = validateTariff();
		if (isValid) {
			setIsLoadingRequest(true);
			const editTariff = {
				action: 'edit-taryfa',
				_data_: {
					id: tariffId,
					nazwa: tariffName,
					shopping_group_id: tariffShoppingGroup.value,
					strefa: tariffStrefa.value,
					energy_1: tariffEnergy.energy_1 || 0,
					energy_2: tariffEnergy.energy_2 || 0,
					// energy_3: tariffEnergy.energy_3 || 0,
					// energy_4: tariffEnergy.energy_4 || 0,
					// energy_5: tariffEnergy.energy_5 || 0,
					// energy_6: tariffEnergy.energy_6 || 0,
					start: startDate,
					stop: stopDate,
				},
			};
			try {
				await sendRequest(editTariff);
				closeModal();
			} catch (error) {
				console.error(error);
			}
			await fetchData();
		}
	};

	const handleDeleteTariff = async () => {
		setIsLoadingRequest(true);
		const deleteTariff = {
			action: 'delete-taryfa',
			id: deletingTariff.id,
		};
		try {
			await sendRequest(deleteTariff);
			closeModal();
		} catch (error) {
			console.error(error);
		}
		await fetchData();
	};

	const handleDateChange = (ranges) => {
		setDateRange(ranges);

		const [start, end] = ranges.map((dateString) => new Date(dateString));

		setStartDate(formatDate(start));
		setStopDate(formatDate(end));
	};

	const generateTariffInputs = () => {
		let inputs = [];
		for (let i = 0; i < (tariffStrefa ? tariffStrefa.value : 0); i++) {
			let key = `energy_${i + 1}`;

			inputs.push(
				<div key={i}>
					<label>{`Stawka ${i + 1}:`}</label>
					<input
						placeholder={`Stawka ${i + 1} za 1kWh`}
						className="input-form"
						value={tariffEnergy[key] || ''}
						onChange={(e) => {
							let value = e.target.value;
							value = value.replace(',', '.');
							const reg = /^(\d+)?([.]?\d{0,5})?$/;

							if (value === '' || reg.test(value)) {
								setTariffEnergy({
									...tariffEnergy,
									[key]: value,
								});
							}
						}}
					/>
				</div>
			);
		}
		return inputs;
	};

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	return (
		<TariffsLayout>
			<h2 className="page-title">Taryfy</h2>
			<div className="table-container">
				<TariffsDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={tariffs}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<Button
					className="add-button"
					onClick={() => setIsModalOpen(true)}
					style={{
						fontSize: '14px',
					}}>
					Dodaj taryfę
				</Button>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{isEditMode ? (
							<h2>Edytuj Taryfę</h2>
						) : (
							<h2>Dodaj Taryfę</h2>
						)}
						<div>
							<label>Nazwa Taryfy:</label>
							<input
								placeholder="Nazwa profilu"
								className="input-form"
								value={tariffName}
								onChange={(e) => setTariffName(e.target.value)}
							/>
							<p className="err-msg">{tariffNameError}</p>
						</div>
						<div>
							<label>Grupa:</label>
							<Select
								options={groups}
								classNamePrefix="react-select"
								placeholder="Wybierz grupę..."
								styles={customStyles}
								value={tariffShoppingGroup}
								onChange={setTariffShoppingGroup}
							/>
							<p className="err-msg"></p>
						</div>
						<div>
							<label>Data:</label>
							<div>
								<DateRangePicker
									disableClock={true}
									onChange={handleDateChange}
									value={dateRange}
									clearIcon={null}
								/>
							</div>
							<p className="err-msg">{dateError}</p>
						</div>
						<div>
							<label>Strefa:</label>
							<Select
								options={strefy}
								classNamePrefix="react-select"
								placeholder="Wybierz strefe..."
								styles={customStyles}
								value={tariffStrefa}
								onChange={setTariffStrefa}
							/>
							<p className="err-msg">{tariffStrefaError}</p>
						</div>
						{generateTariffInputs()}
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{isEditMode ? (
								<Button
									className="add"
									onClick={handleEditTariff}>
									Zapisz
								</Button>
							) : (
								<Button
									className="add"
									onClick={handleAddTariff}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć taryfę:</h2>
						<h2>{deletingTariff.nazwa} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeleteTariff()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</TariffsLayout>
	);
};

export default Tariffs;
