import { useState, useRef, useEffect } from 'react';
import { PPEDataTable } from './PPE.style';
import { PPELayout } from './PPE.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import { ReactComponent as Icokonfiguracja } from './../../svg/ico-konfiguracja.svg';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';
import { jwtDecode } from 'jwt-decode';
import useAccessControl from '../../hooks/useAccessControl';
import Switch from 'rc-switch';
import * as XLSX from 'xlsx';

const PPE = () => {
	const isAccessVerified = useAccessControl();
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [pending, setPending] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isHistoryUsageModal, setIsHistoryUsageModal] = useState(false);
	const [isOdbiorcaModalOpen, setIsOdbiorcaModalOpen] = useState(false);
	const [isNabywcaModalOpen, setIsNabywcaModalOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [dataRoles, setDataRoles] = useState();
	const [gminy, setGminy] = useState();
	const [data, setData] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [recipients, setRecipients] = useState();
	const [sellers, setSellers] = useState();
	const [customers, setCustomers] = useState();
	const [osds, setOsds] = useState();
	const [tariffs, setTariffs] = useState();
	const [shoppingGroups, setShoppingGroups] = useState();
	const [tariffsData, setTariffsData] = useState();
	const [deletingRow, setDeletingRow] = useState();
	const currentDate = new Date();
	const [oldPPE, setOldPPE] = useState({});
	const [newPPE, setNewPPE] = useState({
		ppe_id: '',
		nazwa: '',
		gmina: '',
		ulica: '',
		budynek: '',
		kod: '',
		miasto: '',
		poczta: '',
		lokal: '',
		odbiorca_id: '',
		sprzedawca_id: '',
		shopping_group_id: '',
		current_shopping_group_id: '',
		osd_id: '',
		prosument: '',
		moc_umowna: '',
		moc_foto: '',
		umowa_start: currentDate,
		zmiana_sprzedawcy: '',
		czy_wypowiedziec: '',
		okres_wypowiedzenia: '',
		taryfa_nazwa: '',
		customer_id: '',
	});
	const [historyUsage, setHistoryUsage] = useState({
		ppe_id: '',
		zone1: '',
		zone2: '',
		zone3: '',
		zone: '',
	});
	const [errors, setErrors] = useState({
		ppe_id: '',
		nazwa: '',
		gmina: '',
		ulica: '',
		budynek: '',
		kod: '',
		miasto: '',
		poczta: '',
		lokal: '',
		odbiorca_id: '',
		sprzedawca_id: '',
		shopping_group_id: '',
		current_shopping_group_id: '',
		osd_id: '',
		prosument: '',
		moc_umowna: '',
		moc_foto: '',
		umowa_start: '',
		zmiana_sprzedawcy: '',
		czy_wypowiedziec: '',
		okres_wypowiedzenia: '',
		taryfa_nazwa: '',
		customer_id: '',
	});
	const [newRecipient, setNewRecipient] = useState({
		id: '',
		nazwa: '',
		ulica: '',
		budynek: '',
		gmina_id: '',
		miasto: '',
		nip: '',
		regon: '',
		kod: '',
		lokal: '',
		poczta: '',
		opis: '',
		odrebna_umowa: false,
	});
	const [recipientErrors, setRecipientErrors] = useState({
		nazwa: '',
		ulica: '',
		budynek: '',
		gmina_id: '',
		miasto: '',
		nip: '',
		regon: '',
		kod: '',
		lokal: '',
		poczta: '',
		opis: '',
	});
	const [newCustomer, setNewCustomer] = useState({
		id: '',
		contact: '',
		nip: '',
		regon: '',
		email: '',
		rep_role_id: [],
		gmina_id: [],
		ppe_id: [],
		budynek: '',
		ulica: '',
		miasto: '',
		kod: '',
	});
	const [customerErrors, setCustomerErrors] = useState({
		contact: '',
		nip: '',
		regon: '',
		email: '',
		rep_role_id: '',
		gmina_id: '',
		ppe_id: '',
		budynek: '',
		ulica: '',
		miasto: '',
		kod: '',
	});
	const prosumentOptions = [
		{
			value: 0,
			label: 'Nie',
		},
		{
			value: 1,
			label: 'Tak',
		},
	];
	const [finishedProcedure, setFinishedProcedure] = useState(() => {
		const saved = localStorage.getItem('finishedProcedure');
		return saved ? saved === 'true' : false;
	});

	const decodeToken = () => {
		const token = localStorage.getItem('token');
		if (token) {
			const decoded = jwtDecode(token);
			return decoded.adm;
		}
		return 0;
	};

	const isAdmin = decodeToken() === 1;

	const fetchData = async () => {
		const getPPES = {
			action: 'get-ppes',
		};
		const getRecipients = {
			action: 'get-odbiorcy',
		};
		const getSellers = {
			action: 'get-sprzedawcy',
		};
		const getOSDS = {
			action: 'get-osds',
		};
		const getTariffs = {
			action: 'get-taryfy-sg',
		};
		const getCustomers = {
			action: 'get-customers',
		};
		const getShoppingGroups = {
			action: 'get-shopping-group',
		};
		const getRepRoles = { action: 'get-rep-role' };

		const checkProcedure = { action: 'check-procedure' };

		try {
			const checkProcedureResponse = await sendRequest(checkProcedure);
			const isFinished = checkProcedureResponse.data._data_ !== 0;
			setFinishedProcedure(isFinished);
			localStorage.setItem('finishedProcedure', isFinished.toString());

			const response = await sendRequest(getPPES);
			const enrichedData = response.data._data_.map((item, index) => ({
				...item,
				lp: index + 1,
			}));
			setData(enrichedData);

			const repRolesResponse = await sendRequest(getRepRoles);
			setDataRoles(
				repRolesResponse.data._data_.map((role) => ({
					value: role.id,
					label: role.description,
				}))
			);

			const recipientsResponse = await sendRequest(getRecipients);
			setRecipients(
				recipientsResponse.data._data_.map((recipient) => ({
					value: recipient.id,
					label: recipient.nazwa + ` (${recipient.nip})`,
				}))
			);

			const sellersResponse = await sendRequest(getSellers);
			setSellers(
				sellersResponse.data._data_.map((seller) => ({
					value: seller.id,
					label: seller.nazwa,
				}))
			);

			const osdsResponse = await sendRequest(getOSDS);
			setOsds(
				osdsResponse.data._data_.map((osd) => ({
					value: osd.id,
					label: osd.nazwa,
				}))
			);

			const tariffsResponse = await sendRequest(getTariffs);
			setTariffsData(tariffsResponse.data._data_);
			setTariffs(
				tariffsResponse.data._data_.map((tariff) => ({
					value: tariff.id,
					label: tariff.nazwa,
				}))
			);

			const customersResponse = await sendRequest(getCustomers);
			setCustomers(
				customersResponse.data._data_.map((customer) => ({
					value: customer.id,
					label: customer.contact + ` (${customer.nip})`,
				}))
			);

			const shoppingGroupsResponse = await sendRequest(getShoppingGroups);
			setShoppingGroups(
				shoppingGroupsResponse.data._data_.map((group) => ({
					value: group.id,
					label: group.name,
				}))
			);

			const gminaIdString = localStorage.getItem('gmina_id');
			const gminaIdArray = JSON.parse(gminaIdString);

			setGminy(
				gminaIdArray.map((gmina) => ({
					value: gmina.gmina_id,
					label: gmina.gmina_nazwa,
				}))
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
			setPending(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const clearForm = () => {
		setNewPPE({
			ppe_id: '',
			nazwa: '',
			gmina: '',
			ulica: '',
			budynek: '',
			kod: '',
			miasto: '',
			poczta: '',
			lokal: '',
			odbiorca_id: '',
			sprzedawca_id: '',
			shopping_group_id: '',
			current_shopping_group_id: '',
			osd_id: '',
			prosument: '',
			moc_foto: '',
			umowa_start: currentDate,
			zmiana_sprzedawcy: '',
			czy_wypowiedziec: '',
			okres_wypowiedzenia: '',
			taryfa_nazwa: '',
			customer_id: '',
		});
		setHistoryUsage({
			ppe_id: '',
			zone1: '',
			zone2: '',
			zone3: '',
			zone: '',
		});
		setNewRecipient({
			id: '',
			nazwa: '',
			ulica: '',
			budynek: '',
			gmina_id: '',
			miasto: '',
			nip: '',
			regon: '',
			kod: '',
			lokal: '',
			poczta: '',
			opis: '',
			odrebna_umowa: false,
		});
		setNewCustomer({
			id: '',
			contact: '',
			nip: '',
			regon: '',
			email: '',
			rep_role_id: [],
			gmina_id: [],
			ppe_id: [],
			budynek: '',
			ulica: '',
			miasto: '',
			kod: '',
		});
	};

	const clearErrors = () => {
		setErrors({
			ppe_id: '',
			nazwa: '',
			gmina: '',
			ulica: '',
			budynek: '',
			kod: '',
			miasto: '',
			poczta: '',
			lokal: '',
			odbiorca_id: '',
			sprzedawca_id: '',
			shopping_group_id: '',
			current_shopping_group_id: '',
			osd_id: '',
			prosument: '',
			moc_foto: '',
			umowa_start: '',
			zmiana_sprzedawcy: '',
			czy_wypowiedziec: '',
			okres_wypowiedzenia: '',
			taryfa_nazwa: '',
			customer_id: '',
		});
		setCustomerErrors({
			contact: '',
			nip: '',
			regon: '',
			email: '',
			rep_role_id: '',
			gmina_id: '',
			ppe_id: '',
			budynek: '',
			ulica: '',
			miasto: '',
			kod: '',
		});
		setRecipientErrors({
			nazwa: '',
			ulica: '',
			budynek: '',
			gmina_id: '',
			miasto: '',
			nip: '',
			regon: '',
			kod: '',
			lokal: '',
			poczta: '',
			opis: '',
		});
	};

	const closeModal = () => {
		if (isEditMode || isHistoryUsageModal) {
			clearForm();
			setIsEditMode(false);
			setIsHistoryUsageModal(false);
		}
		clearErrors();
		setIsModalOpen(false);
		setIsModalDeleteOpen(false);
		setDeletingRow();
	};

	const handleUsageClick = async (row) => {
		const getHistoryUsage = {
			action: 'get-history-usage',
			ppe_id: row.ppe_id,
		};
		try {
			const response = await sendRequest(getHistoryUsage);
			if (response.data._data_) {
				const tariff = tariffsData.find(
					(tariff) => tariff.nazwa === row.taryfa_nazwa
				);
				if (tariff) {
					const zone = tariff.strefa;
					setHistoryUsage({
						ppe_id: response.data._data_.ppe_id,
						zone1: response.data._data_.zone1,
						zone2: response.data._data_.zone2,
						zone3: response.data._data_.zone3,
						zone: zone,
					});
					setIsHistoryUsageModal(true);
				} else {
					console.error('Taryfa nie została znaleziona');
				}
			} else {
				const tariff = tariffsData.find(
					(tariff) => tariff.nazwa === row.taryfa_nazwa
				);
				if (tariff) {
					setHistoryUsage((prevState) => ({
						...prevState,
						ppe_id: row.ppe_id,
						zone: tariff.strefa,
					}));
					setIsHistoryUsageModal(true);
				} else {
					alert(`Brak przypisanej taryfy w punkcie: ${row.nazwa}`);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddHistoryUsage = async () => {
		let historyUsageData = {
			ppe_id: historyUsage.ppe_id,
		};

		for (let i = 1; i <= historyUsage.zone; i++) {
			const zoneKey = `zone${i}`;
			historyUsageData[zoneKey] = historyUsage[zoneKey];
		}

		if (historyUsage.zone < 3) {
			for (let i = historyUsage.zone + 1; i <= 3; i++) {
				historyUsageData[`zone${i}`] = 0;
			}
		}

		const addHistoryUsage = {
			action: 'add-history-usage',
			_data_: historyUsageData,
		};

		try {
			await sendRequest(addHistoryUsage);

			setData((prevData) => {
				const updatedData = [...prevData];
				const dataIndex = updatedData.findIndex(
					(item) => item.ppe_id === historyUsage.ppe_id
				);

				if (dataIndex !== -1) {
					for (let i = 1; i <= historyUsage.zone; i++) {
						const zoneKey = `zone${i}`;
						const energyKey = `energy_${i}`;
						updatedData[dataIndex][energyKey] =
							historyUsage[zoneKey];
					}

					if (historyUsage.zone < 3) {
						for (let i = historyUsage.zone + 1; i <= 3; i++) {
							const energyKey = `energy_${i}`;
							updatedData[dataIndex][energyKey] = 0;
						}
					}
				} else {
					console.error(
						'Nie znaleziono obiektu z ppe_id:',
						historyUsage.ppe_id
					);
				}

				return updatedData;
			});
		} catch (error) {
			console.error(error);
		} finally {
			closeModal();
		}
	};

	const handleEditClick = (row) => {
		const selectedRecipient = recipients.find(
			(recipient) => recipient.value === row.odbiorca_id
		);
		const selectedSeller = sellers.find(
			(seller) => seller.value === row.sprzedawca_id
		);
		const selectedOsd = osds.find((osd) => osd.value === row.osd_id);
		const selectedTariff = tariffs.find(
			(tariff) => tariff.label === row.taryfa_nazwa
		);
		const selectedShoppingGroup = shoppingGroups.find(
			(group) => group.value === row.shopping_group_id
		);
		const selectedCurrentShoppingGroup = shoppingGroups.find(
			(group) => group.value === row.current_shopping_group_id
		);
		const selectedProsument = prosumentOptions.find(
			(option) => option.value === row.prosument
		);
		const selectedCustomer = customers.find(
			(option) => option.value === row.customer_id
		);

		const umowaStartDate =
			row.umowa_start && row.umowa_start !== '0000-00-00'
				? new Date(row.umowa_start)
				: null;
		setNewPPE({
			ppe_id: row.ppe_id,
			nazwa: row.nazwa,
			ulica: row.ulica,
			gmina: row.gmina,
			budynek: row.budynek,
			kod: row.kod,
			poczta: row.poczta,
			lokal: row.lokal,
			miasto: row.miasto,
			czy_wypowiedziec:
				row.czy_wypowiedziec == 1
					? { value: 1, label: 'Tak' }
					: { value: 0, label: 'Nie' },
			zmiana_sprzedawcy:
				row.zmiana_sprzedawcy == 1
					? { value: 1, label: 'Pierwsza' }
					: (row.zmiana_sprzedawcy == 2
						? { value: 2, label: 'Nie dotyczy' }
						: { value: 0, label: 'Kolejna' }),				
			okres_wypowiedzenia: row.okres_wypowiedzenia,
			odbiorca_id: selectedRecipient,
			sprzedawca_id: selectedSeller,
			shopping_group_id: selectedShoppingGroup,
			current_shopping_group_id: selectedCurrentShoppingGroup,
			osd_id: selectedOsd,
			prosument: selectedProsument,
			moc_umowna: row.moc_umowna,
			moc_foto: row.moc_foto,
			umowa_start: umowaStartDate,
			taryfa_nazwa: selectedTariff,
			customer_id: selectedCustomer,
		});
		setOldPPE({
			shopping_group_id: selectedShoppingGroup,
			taryfa_nazwa: selectedTariff,
		});
		setIsEditMode(true);
		setIsModalOpen(true);
	};

	const handleEditPpe = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const formattedDate = newPPE.umowa_start
				? `${newPPE.umowa_start.getFullYear()}-${String(
						newPPE.umowa_start.getMonth() + 1
				  ).padStart(2, '0')}-${String(
						newPPE.umowa_start.getDate()
				  ).padStart(2, '0')}`
				: '';
			const ppeData = {
				ppe_id: newPPE.ppe_id,
				nazwa: newPPE.nazwa,
				gmina: newPPE.gmina,
				ulica: newPPE.ulica,
				budynek: newPPE.budynek,
				kod: newPPE.kod,
				miasto: newPPE.miasto,
				poczta: newPPE.poczta,
				lokal: newPPE.lokal,
				zmiana_sprzedawcy: newPPE.zmiana_sprzedawcy.value,
				czy_wypowiedziec: newPPE.czy_wypowiedziec.value,
				okres_wypowiedzenia: newPPE.okres_wypowiedzenia,
				odbiorca_id: newPPE.odbiorca_id.value,
				sprzedawca_id: newPPE.sprzedawca_id.value,
				shopping_group_id: newPPE.shopping_group_id.value,
				current_shopping_group_id:
					newPPE.current_shopping_group_id.value,
				osd_id: newPPE.osd_id.value,
				prosument: newPPE.prosument.value,
				moc_umowna: newPPE.moc_umowna,
				moc_foto: newPPE.moc_foto,
				umowa_start: formattedDate,
				taryfa_nazwa: newPPE.taryfa_nazwa.label,
				customer_id: newPPE.customer_id.value,
			};
			const addPpe = {
				action: 'edit-ppe',
				_data_: ppeData,
			};
			try {
				const response = await sendRequest(addPpe);
				if (response.data.status === 0) {
					setErrors((prevState) => ({
						...prevState,
						ppe_id: 'Błąd dodawania puntu',
					}));
				} else {
					setData((prevData) =>
						prevData.map((item) =>
							item.ppe_id === ppeData.ppe_id
								? { ...item, ...ppeData }
								: item
						)
					);
					clearErrors();
					closeModal();
					clearForm();
					setPending(true);
					if (
						newPPE.taryfa_nazwa !== oldPPE.taryfa_nazwa ||
						newPPE.shopping_group_id !== oldPPE.shopping_group_id
					) {
						const getHistoryUsage = {
							action: 'get-history-usage',
							ppe_id: ppeData.ppe_id,
						};
						try {
							const response = await sendRequest(getHistoryUsage);
							if (response.data._data_) {
								const tariff = tariffsData.find(
									(tariff) =>
										tariff.nazwa === ppeData.taryfa_nazwa
								);
								if (tariff) {
									const zone = tariff.strefa;
									setHistoryUsage({
										ppe_id: response.data._data_.ppe_id,
										zone1: response.data._data_.zone1,
										zone2: response.data._data_.zone2,
										zone3: response.data._data_.zone3,
										zone: zone,
									});
									setIsHistoryUsageModal(true);
								} else {
									console.error(
										'Taryfa nie została znaleziona'
									);
								}
							} else {
								const tariff = tariffsData.find(
									(tariff) =>
										tariff.nazwa === ppeData.taryfa_nazwa
								);
								if (tariff) {
									setHistoryUsage((prevState) => ({
										...prevState,
										ppe_id: ppeData.ppe_id,
										zone: tariff.strefa,
									}));
									setIsHistoryUsageModal(true);
								} else {
									alert(
										`Brak przypisanej taryfy w punkcie: ${ppeData.nazwa}`
									);
								}
							}
						} catch (error) {
							console.error(error);
						}
					}
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const handleDeletePpe = async () => {
		const deletePpe = {
			action: 'delete-ppe',
			ppe_id: deletingRow.ppe_id,
		};
		try {
			await sendRequest(deletePpe);
			setData((prevData) => {
				return prevData.filter(
					(item) => item.ppe_id !== deletingRow.ppe_id
				);
			});
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddPpe = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const formattedDate = newPPE.umowa_start
				? `${newPPE.umowa_start.getFullYear()}-${String(
						newPPE.umowa_start.getMonth() + 1
				  ).padStart(2, '0')}-${String(
						newPPE.umowa_start.getDate()
				  ).padStart(2, '0')}`
				: '';

			const ppeData = {
				ppe_id: newPPE.ppe_id,
				nazwa: newPPE.nazwa,
				gmina: newPPE.gmina,
				ulica: newPPE.ulica,
				budynek: newPPE.budynek,
				kod: newPPE.kod,
				miasto: newPPE.miasto,
				lokal: newPPE.lokal,
				poczta: newPPE.poczta,
				odbiorca_id: newPPE.odbiorca_id.value,
				sprzedawca_id: newPPE.sprzedawca_id.value,
				shopping_group_id: newPPE.shopping_group_id.value,
				current_shopping_group_id:
					newPPE.current_shopping_group_id.value,
				osd_id: newPPE.osd_id.value,
				zmiana_sprzedawcy: newPPE.zmiana_sprzedawcy.value,
				czy_wypowiedziec: newPPE.czy_wypowiedziec.value,
				okres_wypowiedzenia: newPPE.okres_wypowiedzenia,
				prosument: newPPE.prosument.value,
				moc_umowna: newPPE.moc_umowna,
				moc_foto: newPPE.moc_foto,
				umowa_start: formattedDate,
				taryfa_nazwa: newPPE.taryfa_nazwa.label,
				customer_id: newPPE.customer_id.value,
			};

			const addPpe = {
				action: 'add-ppe',
				_data_: ppeData,
			};

			try {
				const response = await sendRequest(addPpe);
				if (response.data.status == 0) {
					setErrors((prevState) => ({
						...prevState,
						ppe_id: 'Błąd dodawania puntu',
					}));
				} else if (response.data.status == 2) {
					setErrors((prevState) => ({
						...prevState,
						ppe_id: 'Taki numer PPE już istnieje',
					}));
				} else {
					setData((prevData) => [...prevData, ppeData]);
					clearErrors();
					closeModal();
					clearForm();
					fetchData();

					const getHistoryUsage = {
						action: 'get-history-usage',
						ppe_id: ppeData.ppe_id,
					};
					try {
						const response = await sendRequest(getHistoryUsage);
						if (response.data._data_) {
							const tariff = tariffsData.find(
								(tariff) =>
									tariff.nazwa === ppeData.taryfa_nazwa
							);
							if (tariff) {
								const zone = tariff.strefa;
								setHistoryUsage({
									ppe_id: response.data._data_.ppe_id,
									zone1: response.data._data_.zone1,
									zone2: response.data._data_.zone2,
									zone3: response.data._data_.zone3,
									zone: zone,
								});
								setIsHistoryUsageModal(true);
							} else {
								console.error('Taryfa nie została znaleziona');
							}
						} else {
							const tariff = tariffsData.find(
								(tariff) =>
									tariff.nazwa === ppeData.taryfa_nazwa
							);
							if (tariff) {
								setHistoryUsage((prevState) => ({
									...prevState,
									ppe_id: ppeData.ppe_id,
									zone: tariff.strefa,
								}));
								setIsHistoryUsageModal(true);
							} else {
								alert(
									`Brak przypisanej taryfy w punkcie: ${ppeData.nazwa}`
								);
							}
						}
					} catch (error) {
						console.error(error);
					}
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const validateForm = () => {
		let isValid = true;
		let newErrors = {
			nazwa: '',
			ulica: '',
			budynek: '',
			kod: '',
			miasto: '',
			odbiorca_id: '',
			sprzedawca_id: '',
			shopping_group_id: '',
			current_shopping_group_id: '',
			osd_id: '',
			prosument: '',
			umowa_start: '',
			taryfa_nazwa: '',
			moc_foto: '',
		};

		for (const key in newPPE) {
			if (
				key === 'ulica' ||
				key === 'budynek' ||
				key === 'lokal' ||
				key === 'moc_umowna' ||
				key === 'umowa_start' ||
				(key === 'moc_foto' && newPPE.prosument.value == '0')
			)
				continue;
			if (newPPE.hasOwnProperty(key)) {
				const value = newPPE[key];
				if (key === 'ppe_id') {
					const ppeIdString = String(value);
					if (ppeIdString.length !== 18) {
						newErrors[key] =
							'Pole ppe_id musi zawierać dokładnie 18 znaków';
						isValid = false;
					}
					continue;
				}
				if (typeof value === 'object') {
					if (key === 'umowa_start') {
						if (!value || isNaN(Date.parse(value))) {
							newErrors[key] = 'To pole jest wymagane';
							isValid = false;
						}
					} else if (
						!value ||
						value.value === undefined ||
						value.value === ''
					) {
						newErrors[key] = 'To pole jest wymagane';
						isValid = false;
					}
				} else {
					if (
						value === undefined ||
						value === '' ||
						value.length === 0
					) {
						newErrors[key] = 'To pole jest wymagane';
						isValid = false;
					}
				}
			}
		}

		if (
			newPPE.prosument.value === '1' &&
			(newPPE.moc_foto === undefined ||
				newPPE.moc_foto === '' ||
				newPPE.moc_foto.length == 0)
		) {
			newErrors.moc_foto = 'To pole jest wymagane';
			isValid = false;
		}
		setErrors(newErrors);
		return isValid;
	};

	const handleInputChangePPE = (e) => {
		const { name, value } = e.target;

		if (name === 'kod') {
			if (/^[0-9-]*$/.test(value)) {
				setNewPPE((prevState) => ({
					...prevState,
					[name]: value,
				}));
			}
		} else if (name === 'ppe_id') {
			if (/^\d*$/.test(value)) {
				setNewPPE((prevState) => ({
					...prevState,
					[name]: value,
				}));
			}
		} else if (name === 'moc_umowna' || name === 'moc_foto') {
			const newValue = value.replace(',', '.');
			if (/^\d*\.?\d*$/.test(newValue)) {
				setNewPPE((prevState) => ({
					...prevState,
					[name]: newValue,
				}));
			}
		} else {
			setNewPPE((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleInputChangeRecipients = (e) => {
		const { name, value } = e.target;

		if (name === 'kod' && /^[0-9-]*$/.test(value)) {
			setNewRecipient((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else if (name === 'nip' && /^[0-9]*$/.test(value)) {
			setNewRecipient((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else if (name === 'regon' && /^[0-9]*$/.test(value)) {
			setNewRecipient((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else if (name !== 'kod' && name !== 'nip' && name !== 'regon') {
			setNewRecipient((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleAddRecipient = async () => {
		if (validateFormRecipient()) {
			setIsLoadingRequest(true);
			const recipientData = {
				nazwa: newRecipient.nazwa,
				ulica: newRecipient.ulica,
				budynek: newRecipient.budynek,
				gmina_id: newRecipient.gmina_id.value,
				miasto: newRecipient.miasto,
				nip: newRecipient.nip,
				regon: newRecipient.regon,
				kod: newRecipient.kod,
				lokal: newRecipient.lokal,
				poczta: newRecipient.poczta,
				opis: newRecipient.opis,
				odrebna_umowa: newRecipient.odrebna_umowa ? 1 : 0,
			};

			const addRecipient = {
				action: 'add-odbiorca',
				_data_: recipientData,
			};

			try {
				const response = await sendRequest(addRecipient);
				if (response.data.status === 0) {
					setRecipientErrors((prevState) => ({
						...prevState,
						nazwa: 'Błąd dodawania nabywcy',
					}));
				} else if (response.data.status === 2) {
					setRecipientErrors((prevState) => ({
						...prevState,
						nip: response.data.message,
					}));
				} else {
					setIsOdbiorcaModalOpen(false);
					setNewRecipient({
						id: '',
						nazwa: '',
						ulica: '',
						budynek: '',
						gmina_id: '',
						miasto: '',
						nip: '',
						regon: '',
						kod: '',
						lokal: '',
						poczta: '',
						opis: '',
						odrebna_umowa: false,
					});
				}
			} catch (error) {
				console.error(error);
			} finally {
				const getRecipients = {
					action: 'get-odbiorcy',
				};
				try {
					const recipientsResponse = await sendRequest(getRecipients);
					setRecipients(
						recipientsResponse.data._data_.map((recipient) => ({
							value: recipient.id,
							label: recipient.nazwa,
						}))
					);
					setIsLoadingRequest(false);
				} catch (error) {
					console.error(error);
				}
			}
		}
	};

	const validateFormRecipient = () => {
		let isValid = true;
		let newErrors = {
			nazwa: '',
			ulica: '',
			budynek: '',
			gmina_id: '',
			miasto: '',
			nip: '',
			regon: '',
			kod: '',
			poczta: '',
			lokal: '',
		};

		const isNumeric = (value, allowDashes = false) => {
			const regex = allowDashes ? /^[0-9-]+$/ : /^[0-9]+$/;
			return regex.test(value);
		};

		for (const key in newRecipient) {
			if (key === 'id' || key === 'lokal') continue;

			const value =
				newRecipient[key] != null
					? newRecipient[key].toString().trim()
					: '';
			if (value === '') {
				newErrors[key] = 'To pole jest wymagane';
				isValid = false;
			} else if (key === 'nip') {
				if (!isNumeric(value) || value.length !== 10) {
					newErrors[key] = 'NIP powinien zawierać 10 cyfr';
					isValid = false;
				}
			} else if (key === 'regon') {
				if (
					!isNumeric(value) ||
					(value.length !== 9 && value.length !== 14)
				) {
					newErrors[key] = 'REGON powinien zawierać 9 lub 14 cyfr';
					isValid = false;
				}
			}
		}

		setRecipientErrors(newErrors);
		return isValid;
	};

	const handleInputChangeCustomer = (e) => {
		const { name, value } = e.target;

		if (name === 'nip' || name === 'regon') {
			if (/^\d*$/.test(value)) {
				setNewCustomer((prevState) => ({
					...prevState,
					[name]: value,
				}));
			}
		} else if (name === 'kod') {
			if (/^[0-9-]*$/.test(value)) {
				setNewCustomer((prevState) => ({
					...prevState,
					[name]: value,
				}));
			}
		} else {
			setNewCustomer((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const exportToExcel = () => {
		const dataToExport = data.map((item) => ({
			'Numer PPE': item.ppe_id,
			Nazwa: item.nazwa,
			Ulica: item.ulica,
			Budynek: item.budynek,
			'Nr lokalu': item.lokal,
			Miasto: item.miasto,
			'Kod pocztowy': item.kod,
			Poczta: item.poczta,
			Gmina: item.gmina,
			'Grupa taryfowa': item.taryfa_nazwa,
			'Moc umowna [kW]': item.moc_umowna,
			'Strefa 1': item.energy_1,
			'Strefa 2': item.energy_2,
			'Strefa 3': item.energy_3,
			// 'Strefa 4': item.energy_4,
			// 'Strefa 5': item.energy_5,
			// 'Strefa 6': item.energy_6,
			'Docelowy Rodzaj umowy':
				item.shopping_group_id == 1 ? 'Kompleksowa' : 'Rozdzielona',
			'Aktualny Rodzaj umowy':
				item.current_shopping_group_id == 1
					? 'Kompleksowa'
					: 'Rozdzielona',
			'Data zakończenia umowy': item.umowa_start,
			'Zmiana sprzedawcy':
				item.zmiana_sprzedawcy == 1 ? 'Pierwsza' : (item.zmiana_sprzedawcy == 2 ? 'Nie dotyczy' : 'Kolejna'),			  
			'Czy aktualną umowę należy wypowiedzieć?':
				item.czy_wypowiedziec == 1 ? 'Tak' : 'Nie',
			'Jaki jest okres wypowiedzenia aktualnej umowy?':
				item.okres_wypowiedzenia,
			'Prosument (Moc źródła OZE [kW])': item.ppe_prosument,
		}));

		const ws = XLSX.utils.json_to_sheet(dataToExport);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'PPE');

		const exportFileName = `PPE_${new Date().toLocaleDateString()}.xlsx`;

		XLSX.writeFile(wb, exportFileName);
	};

	const handleAddCustomer = async () => {
		if (validateFormCustomer()) {
			setIsLoadingRequest(true);
			const customerData = {
				contact: newCustomer.contact,
				nip: newCustomer.nip,
				regon: newCustomer.regon,
				email: newCustomer.email,
				rep_role_id: newCustomer.rep_role_id.map((role) => role.value),
				gmina_id: newCustomer.gmina_id.value,
				ppe_id: newCustomer.ppe_id.map((ppe) => ppe.value),
				budynek: newCustomer.budynek,
				ulica: newCustomer.ulica,
				miasto: newCustomer.miasto,
				kod: newCustomer.kod,
			};

			const addCustomer = {
				action: 'add-customer',
				_data_: customerData,
			};

			try {
				const response = await sendRequest(addCustomer);
				if (response.data.status === 0) {
					setCustomerErrors((prevState) => ({
						...prevState,
						contact: 'Błąd dodawania nabywcy',
					}));
				} else if (response.data.status === 2) {
					setCustomerErrors((prevState) => ({
						...prevState,
						nip: response.data.message,
					}));
				} else {
					setIsNabywcaModalOpen(false);
					setNewCustomer({
						id: '',
						contact: '',
						nip: '',
						regon: '',
						email: '',
						rep_role_id: [],
						gmina_id: [],
						ppe_id: [],
						budynek: '',
						ulica: '',
						miasto: '',
						kod: '',
					});
				}
			} catch (error) {
				console.error(error);
			} finally {
				const getCustomers = {
					action: 'get-customers',
				};
				try {
					const customersResponse = await sendRequest(getCustomers);
					setCustomers(
						customersResponse.data._data_.map((customer) => ({
							value: customer.id,
							label: customer.contact + ` (${customer.nip})`,
						}))
					);
					setIsLoadingRequest(false);
				} catch (error) {
					console.error(error);
				}
			}
		}
	};

	const validateFormCustomer = () => {
		let isValid = true;
		let newErrors = {
			contact: '',
			nip: '',
			regon: '',
			email: '',
			rep_role_id: '',
			gmina_id: '',
			ppe_id: '',
			budynek: '',
			ulica: '',
			miasto: '',
			kod: '',
		};

		for (const key in newCustomer) {
			if (key === 'id') continue;

			if (
				newCustomer[key] === null ||
				newCustomer[key] === undefined ||
				newCustomer[key] === '' ||
				(typeof newCustomer[key] === 'string' &&
					newCustomer[key].length === 0)
			) {
				newErrors[key] = 'To pole jest wymagane';
				isValid = false;
			}
		}

		if (
			newCustomer.email !== null &&
			!/\S+@\S+\.\S+/.test(newCustomer.email)
		) {
			newErrors.email = 'Nieprawidłowy adres email';
			isValid = false;
		}

		if (newCustomer.nip !== null && newCustomer.nip.length !== 10) {
			newErrors.nip = 'NIP powinien zawierać 10 cyfr';
			isValid = false;
		}

		if (
			newCustomer.regon !== null &&
			![9, 14].includes(newCustomer.regon.length)
		) {
			newErrors.regon = 'REGON powinien zawierać 9 lub 14 cyfr';
			isValid = false;
		}

		setCustomerErrors(newErrors);
		return isValid;
	};

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '7px' }}>
					<Icokonfiguracja
						title="Edytuj zużycie"
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							handleUsageClick(row);
						}}
					/>
					<Icoedytuj
						title="Edytuj wiersz"
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							handleEditClick(row);
						}}
					/>
					<Icozamknij
						title="Usuń wiersz"
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							setDeletingRow(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
			omit: !(!finishedProcedure || isAdmin),
		},
		{
			name: 'Lp.',
			selector: (row) => row.lp,
			grow: 1,
			minWidth: '50px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Numer PPE',
			selector: (row) => row.ppe_id,
			grow: 3,
			minWidth: '170px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nazwa',
			selector: (row) => row.nazwa,
			grow: 3,
			minWidth: '400px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Ulica',
			selector: (row) => row.ulica,
			grow: 2,
			minWidth: '200px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nr budynku',
			selector: (row) => row.budynek,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nr lokalu',
			selector: (row) => row.lokal,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Miasto',
			selector: (row) => row.miasto,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Kod pocztowy',
			selector: (row) => row.kod,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Poczta',
			selector: (row) => row.poczta,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Gmina',
			selector: (row) => row.gmina,
			grow: 2,
			minWidth: '230px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Grupa taryfowa',
			selector: (row) => row.taryfa_nazwa,
			grow: 1,
			width: '75px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Moc umowna [kW]',
			selector: (row) => row.moc_umowna,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Strefa 1',
			selector: (row) => row.energy_1,
			grow: 1,
			width: '65px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Strefa 2',
			selector: (row) => row.energy_2,
			grow: 1,
			width: '65px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Strefa 3',
			selector: (row) => row.energy_3,
			grow: 1,
			width: '65px',
			sortable: true,
			sortField: '0',
		},
		// {
		// 	name: 'Strefa 4',
		// 	selector: (row) => row.energy_4,
		// 	grow: 1,
		// 	width: '65px',
		// 	sortable: true,
		// 	sortField: '0',
		// },
		// {
		// 	name: 'Strefa 5',
		// 	selector: (row) => row.energy_5,
		// 	grow: 1,
		// 	width: '65px',
		// 	sortable: true,
		// 	sortField: '0',
		// },
		// {
		// 	name: 'Strefa 6',
		// 	selector: (row) => row.energy_6,
		// 	grow: 1,
		// 	width: '65px',
		// 	sortable: true,
		// 	sortField: '0',
		// },
		{
			name: 'Docelowy Rodzaj umowy',
			selector: (row) =>
				row.shopping_group_id === 1 ? 'Kompleksowa' : 'Rozdzielona',
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Aktualny Rodzaj umowy',
			selector: (row) =>
				row.current_shopping_group_id === 1
					? 'Kompleksowa'
					: 'Rozdzielona',
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Data zakończenia umowy',
			selector: (row) => row.umowa_start,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Zmiana sprzedawcy',
			selector: (row) =>
				row.zmiana_sprzedawcy == 1 ? 'Pierwsza' : (row.zmiana_sprzedawcy == 2 ? 'Nie dotyczy' : 'Kolejna'),
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Czy aktualną umowę należy wypowiedzieć?',
			selector: (row) => (row.czy_wypowiedziec == 1 ? 'Tak' : 'Nie'),
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Jaki jest okres wypowiedzenia aktualnej umowy?',
			selector: (row) => row.okres_wypowiedzenia,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Prosument (Moc źródła OZE [kW])',
			selector: (row) => row.ppe_prosument,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
	];

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	if (!isAccessVerified) {
		return null;
	}

	return (
		<PPELayout>
			<h2 className="page-title">Punkty poboru energii</h2>
			<div className="table-container">
				<PPEDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={
						data && data.length > 0
							? data.filter((customer) =>
									Object.values(customer).some((value) =>
										String(value)
											.toLowerCase()
											.includes(searchValue.toLowerCase())
									)
							  )
							: []
					}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<div className="table-menu">
					{(!finishedProcedure || isAdmin) && (
						<Button
							className="add-button"
							onClick={() => setIsModalOpen(true)}
							style={{
								fontSize: '14px',
							}}>
							Dodaj nowy PPE
						</Button>
					)}
					<input
						placeholder="Szukaj..."
						className="search-input"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<Button
						style={{
							fontSize: '14px',
							marginBottom: '10px',
							marginLeft: 'auto',
						}}
						onClick={exportToExcel}>
						Eksportuj do Excela
					</Button>
				</div>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{isEditMode ? <h2>Edytuj PPE</h2> : <h2>Dodaj PPE</h2>}
						<div className="form">
							<div className="column">
								{isEditMode ? (
									<div>
										<label>Numer PPE:</label>
										<input
											name="nazwa"
											placeholder="Numer PPE"
											value={newPPE.ppe_id}
											className="input-form"
											disabled
										/>
										<p className="err-msg">
											{errors.nazwa}
										</p>
									</div>
								) : (
									<div>
										<label>Numer PPE:</label>
										<input
											name="ppe_id"
											placeholder="Numer PPE"
											value={newPPE.ppe_id}
											className="input-form"
											onChange={handleInputChangePPE}
										/>
										<p className="err-msg">
											{errors.ppe_id}
										</p>
									</div>
								)}
								<div>
									<label>Nazwa PPE:</label>
									<input
										name="nazwa"
										placeholder="Nazwa PPE"
										value={newPPE.nazwa}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">{errors.nazwa}</p>
								</div>
								<div>
									<label>Ulica:</label>
									<input
										name="ulica"
										placeholder="Nazwa ulicy PPE:"
										value={newPPE.ulica}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">{errors.ulica}</p>
								</div>
								<div>
									<label>Numer budynku:</label>
									<input
										name="budynek"
										placeholder="Numer budynku PPE:"
										value={newPPE.budynek}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">{errors.budynek}</p>
								</div>
								<div>
									<label>Numer lokalu:</label>
									<input
										name="lokal"
										placeholder="Numer lokalu PPE:"
										value={newPPE.lokal}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">{errors.lokal}</p>
								</div>
								<div>
									<label>Miejscowość:</label>
									<input
										name="miasto"
										placeholder="Nazwa miasto"
										value={newPPE.miasto}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">{errors.miasto}</p>
								</div>
								<div>
									<label>Kod pocztowy:</label>
									<input
										name="kod"
										placeholder="Kod pocztowy PPE:"
										value={newPPE.kod}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">{errors.kod}</p>
								</div>
								<div>
									<label>Poczta:</label>
									<input
										name="poczta"
										placeholder="Poczta PPE:"
										value={newPPE.poczta}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">{errors.poczta}</p>
								</div>
								<div>
									<label>Gmina:</label>
									<input
										name="gmina"
										placeholder="Gmina PPE:"
										value={newPPE.gmina}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">{errors.gmina}</p>
								</div>
								<div>
									<label>Moc umowna [kW]:</label>
									<input
										name="moc_umowna"
										placeholder="Moc umowna [kW]:"
										value={newPPE.moc_umowna}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">
										{errors.moc_umowna}
									</p>
								</div>
								<div>
									<label>
										Okres wypowiedzenia aktualnej umowy
										(miesiące):
									</label>
									<input
										name="okres_wypowiedzenia"
										placeholder="Okres wypowiedzenia aktualnej umowy (miesiące)"
										value={newPPE.okres_wypowiedzenia}
										className="input-form"
										onChange={handleInputChangePPE}
									/>
									<p className="err-msg">
										{errors.okres_wypowiedzenia}
									</p>
								</div>
							</div>
							<div className="column">
								<div>
									<label>
										Czy aktualną umowę wypowiedzieć?
									</label>
									<Select
										name="czy_wypowiedziec"
										options={[
											{ value: 1, label: 'Tak' },
											{ value: 0, label: 'Nie' },
										]}
										classNamePrefix="react-select"
										placeholder="Czy aktualną umowę wypowiedzieć?"
										styles={customStyles}
										value={newPPE.czy_wypowiedziec}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												czy_wypowiedziec:
													selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.czy_wypowiedziec}
									</p>
								</div>
								<div>
									<label>Zmiana sprzedawcy</label>
									<Select
										name="zmiana_sprzedawcy"
										options={[
											{ value: 2, label: 'Nie dotyczy' },
											{ value: 1, label: 'Pierwsza' },
											{ value: 0, label: 'Kolejna' },
										]}
										classNamePrefix="react-select"
										placeholder="Zmiana sprzedawcy"
										styles={customStyles}
										value={newPPE.zmiana_sprzedawcy}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												zmiana_sprzedawcy:
													selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.zmiana_sprzedawcy}
									</p>
								</div>

								<div>
									<label>Nabywca:</label>
									<Select
										name="customer_id"
										options={customers}
										classNamePrefix="react-select"
										placeholder="Wybierz nabywcę..."
										styles={customStyles}
										value={newPPE.customer_id}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												customer_id: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.customer_id}
									</p>
								</div>
								<div>
									<label>Data zakończenia umowy:</label>
									<DatePicker
										value={newPPE.umowa_start}
										dateFormat="yyyy-MM-dd"
										onChange={(date) => {
											setNewPPE((prevState) => ({
												...prevState,
												umowa_start: date,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.umowa_start}
									</p>
								</div>
								<div>
									<label>Odbiorca:</label>
									<Select
										name="odbiorca_id"
										options={recipients}
										classNamePrefix="react-select"
										placeholder="Wybierz odbiorcę..."
										styles={customStyles}
										value={newPPE.odbiorca_id}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												odbiorca_id: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.odbiorca_id}
									</p>
								</div>
								<div>
									<label>Sprzedawcy:</label>
									<Select
										name="sprzedawca_id"
										options={sellers}
										classNamePrefix="react-select"
										placeholder="Wybierz sprzedawców..."
										styles={customStyles}
										value={newPPE.sprzedawca_id}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												sprzedawca_id: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.sprzedawca_id}
									</p>
								</div>
								<div>
									<label>Docelowy Rodzaj umowy:</label>
									<Select
										name="shopping_group_id"
										options={shoppingGroups}
										classNamePrefix="react-select"
										placeholder="Wybierz docelowy rodzaj umowy..."
										styles={customStyles}
										value={newPPE.shopping_group_id}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												shopping_group_id:
													selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.shopping_group_id}
									</p>
								</div>
								<div>
									<label>Aktualny Rodzaj umowy:</label>
									<Select
										name="current_shopping_group_id"
										options={shoppingGroups}
										classNamePrefix="react-select"
										placeholder="Wybierz aktualny rodzaj umowy..."
										styles={customStyles}
										value={newPPE.current_shopping_group_id}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												current_shopping_group_id:
													selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.current_shopping_group_id}
									</p>
								</div>
								<div>
									<label>OSD:</label>
									<Select
										name="osd_id"
										options={osds}
										classNamePrefix="react-select"
										placeholder="Wybierz typ..."
										styles={customStyles}
										value={newPPE.osd_id}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												osd_id: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">{errors.osd_id}</p>
								</div>
								<div>
									<label>Taryfa:</label>
									<Select
										name="taryfa_nazwa"
										options={tariffs}
										classNamePrefix="react-select"
										placeholder="Wybierz taryfę..."
										styles={customStyles}
										value={newPPE.taryfa_nazwa}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												taryfa_nazwa: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.taryfa_nazwa}
									</p>
								</div>
								<div>
									<label>Prosument:</label>
									<Select
										name="prosument"
										options={prosumentOptions}
										classNamePrefix="react-select"
										placeholder="Wybierz typ..."
										styles={customStyles}
										value={newPPE.prosument}
										onChange={(selectedOption) => {
											setNewPPE((prevState) => ({
												...prevState,
												prosument: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.prosument}
									</p>
								</div>
								{newPPE.prosument.value == 1 && (
									<div>
										<label>Moc źródła OZE [kW]:</label>
										<input
											name="moc_foto"
											placeholder="Moc źródła OZE [kW]:"
											value={newPPE.moc_foto}
											className="input-form"
											onChange={handleInputChangePPE}
										/>
										<p className="err-msg">
											{errors.moc_foto}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="modal-actions">
							<Button
								className="cancel"
								onClick={() => setIsOdbiorcaModalOpen(true)}>
								Dodaj Odbiorcę
							</Button>
							<Button
								className="cancel"
								onClick={() => setIsNabywcaModalOpen(true)}>
								Dodaj Nabywcę
							</Button>
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{isEditMode ? (
								<Button className="add" onClick={handleEditPpe}>
									Zapisz
								</Button>
							) : (
								<Button className="add" onClick={handleAddPpe}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isOdbiorcaModalOpen && (
				<div
					onMouseDown={() => setIsOdbiorcaModalOpen(false)}
					className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Dodaj Odbiorcę</h2>
						<div className="form">
							<div className="column">
								<div>
									<label>Nazwa odbiorcy:</label>
									<input
										name="nazwa"
										placeholder="Nazwa odbiorcy"
										value={newRecipient.nazwa}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>

									<p className="err-msg">
										{recipientErrors.nazwa}
									</p>
								</div>
								<div>
									<label>Uczestnik Grupy Zakupowej:</label>
									<Select
										name="gmina_id"
										classNamePrefix="react-select"
										placeholder="Wybierz uczestnika..."
										options={gminy}
										styles={customStyles}
										value={newRecipient.gmina_id}
										onChange={(selectedOption) => {
											setNewRecipient((prevState) => ({
												...prevState,
												gmina_id: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{recipientErrors.gmina_id}
									</p>
								</div>
								<div>
									<label>Ulica:</label>
									<input
										name="ulica"
										placeholder="Ulica odbiorcy"
										value={newRecipient.ulica}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>
									<p className="err-msg">
										{recipientErrors.ulica}
									</p>
								</div>
								<div>
									<label>Nr budynku:</label>
									<input
										name="budynek"
										placeholder="Nr budynku odbiorcy"
										value={newRecipient.budynek}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>
									<p className="err-msg">
										{recipientErrors.budynek}
									</p>
								</div>
								<div>
									<label>Nr lokalu:</label>
									<input
										name="lokal"
										placeholder="Nr lokalu odbiorcy"
										value={newRecipient.lokal}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>
									<p className="err-msg">
										{recipientErrors.lokal}
									</p>
								</div>
								<div>
									<label>NIP:</label>
									<input
										name="nip"
										placeholder="NIP odbiorcy"
										value={newRecipient.nip}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>
									<p className="err-msg">
										{recipientErrors.nip}
									</p>
								</div>
							</div>
							<div className="column">
								<div>
									<label>REGON:</label>
									<input
										name="regon"
										placeholder="Regon odbiorcy"
										value={newRecipient.regon}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>
									<p className="err-msg">
										{recipientErrors.regon}
									</p>
								</div>
								<div>
									<label>Miasto:</label>
									<input
										name="miasto"
										placeholder="Miasto odbiorcy"
										value={newRecipient.miasto}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>
									<p className="err-msg">
										{recipientErrors.miasto}
									</p>
								</div>
								<div>
									<label>Kod pocztowy:</label>
									<input
										name="kod"
										placeholder="Kod pocztowy odbiorcy"
										value={newRecipient.kod}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>
									<p className="err-msg">
										{recipientErrors.kod}
									</p>
								</div>
								<div>
									<label>Poczta:</label>
									<input
										name="poczta"
										placeholder="Poczta odbiorcy"
										value={newRecipient.poczta}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>
									<p className="err-msg">
										{recipientErrors.poczta}
									</p>
								</div>
								<div>
									<label>Opis:</label>
									<input
										name="opis"
										placeholder="Opis odbiorcy"
										value={newRecipient.opis}
										className="input-form"
										onChange={handleInputChangeRecipients}
									/>
									<p className="err-msg">
										{recipientErrors.opis}
									</p>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'start',
									}}>
									<label
										htmlFor="odrebnaUmowaSwitch"
										style={{ marginBottom: '5px' }}>
										Czy odrębna umowa?
									</label>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '7px',
										}}>
										<span>NIE</span>
										<Switch
											id="odrebnaUmowaSwitch"
											checked={newRecipient.odrebna_umowa}
											onChange={(checked) => {
												setNewRecipient((prevState) => ({
													...prevState,
													odrebna_umowa: checked,
												}));
											}}
										/>
										<span>TAK</span>
									</div>
									<p
										style={{
											fontSize: '11px',
											marginTop: '5px',
										}}>
										TAK - jeżeli dana jednostka chce odrębną
										umowę
									</p>
									<p
										style={{
											fontSize: '11px',
											marginTop: '5px',
										}}>
										NIE - jeżeli dana jednostka chce jedną
										wspólną umowę z gminą
									</p>
								</div>
							</div>
						</div>
						<div className="modal-actions">
							<Button
								className="cancel"
								onClick={() => setIsOdbiorcaModalOpen(false)}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={handleAddRecipient}>
								Dodaj
							</Button>
						</div>
					</div>
				</div>
			)}
			{isNabywcaModalOpen && (
				<div
					onMouseDown={() => setIsNabywcaModalOpen(false)}
					className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Dodaj nabywcę</h2>
						<div className="form">
							<div className="column">
								<div>
									<label>Opis:</label>
									<input
										name="contact"
										placeholder="Opis nabywcy"
										value={newCustomer.contact}
										className="input-form"
										onChange={handleInputChangeCustomer}
									/>

									<p className="err-msg">
										{customerErrors.contact}
									</p>
								</div>
								<div>
									<label>NIP:</label>
									<input
										name="nip"
										placeholder="NIP nabywcy"
										value={newCustomer.nip}
										className="input-form"
										onChange={handleInputChangeCustomer}
									/>
									<p className="err-msg">
										{customerErrors.nip}
									</p>
								</div>
								<div>
									<label>REGON:</label>
									<input
										name="regon"
										placeholder="Regon nabywcy"
										value={newCustomer.regon}
										className="input-form"
										onChange={handleInputChangeCustomer}
									/>
									<p className="err-msg">
										{customerErrors.regon}
									</p>
								</div>
								<div>
									<label>Email:</label>
									<input
										name="email"
										placeholder="Email nabywcy"
										value={newCustomer.email}
										className="input-form"
										onChange={handleInputChangeCustomer}
									/>
									<p className="err-msg">
										{customerErrors.email}
									</p>
								</div>
								<div>
									<label>Kod pocztowy:</label>
									<input
										name="kod"
										placeholder="Kod pocztowy nabywcy"
										value={newCustomer.kod}
										className="input-form"
										onChange={handleInputChangeCustomer}
									/>
									<p className="err-msg">
										{customerErrors.kod}
									</p>
								</div>
								<div>
									<label>Miasto:</label>
									<input
										name="miasto"
										placeholder="Miasto nabywcy"
										value={newCustomer.miasto}
										className="input-form"
										onChange={handleInputChangeCustomer}
									/>
									<p className="err-msg">
										{customerErrors.miasto}
									</p>
								</div>
							</div>
							<div className="column">
								<div>
									<label>Ulica:</label>
									<input
										name="ulica"
										placeholder="Ulica nabywcy"
										value={newCustomer.ulica}
										className="input-form"
										onChange={handleInputChangeCustomer}
									/>
									<p className="err-msg">
										{customerErrors.ulica}
									</p>
								</div>
								<div>
									<label>Budynek:</label>
									<input
										name="budynek"
										placeholder="Budynek nabywcy"
										value={newCustomer.budynek}
										className="input-form"
										onChange={handleInputChangeCustomer}
									/>
									<p className="err-msg">
										{customerErrors.budynek}
									</p>
								</div>
								<div>
									<label>Reprezentowana/y przez:</label>
									<Select
										name="rep_role_id"
										options={dataRoles}
										classNamePrefix="react-select"
										placeholder="Wybierz reprezentanta..."
										styles={customStyles}
										isMulti
										value={newCustomer.rep_role_id}
										onChange={(selectedOptions) => {
											setNewCustomer((prevState) => ({
												...prevState,
												rep_role_id: selectedOptions,
											}));
										}}
									/>
									<p className="err-msg">
										{customerErrors.rep_role_id}
									</p>
								</div>
								<div>
									<label>Uczestnik Grupy Zakupowej:</label>
									<Select
										name="gmina_id"
										classNamePrefix="react-select"
										placeholder="Wybierz uczestnika..."
										options={gminy}
										styles={customStyles}
										value={newCustomer.gmina_id}
										onChange={(selectedOption) => {
											setNewCustomer((prevState) => ({
												...prevState,
												gmina_id: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">
										{customerErrors.gmina_id}
									</p>
								</div>
							</div>
						</div>
						<div className="modal-actions">
							<Button
								className="cancel"
								onClick={() => setIsNabywcaModalOpen(false)}>
								Anuluj
							</Button>
							<Button className="add" onClick={handleAddCustomer}>
								Dodaj
							</Button>
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć PPE:</h2>
						<h2>{deletingRow.nazwa} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeletePpe()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isHistoryUsageModal && (!finishedProcedure || isAdmin) && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>
							Edytuj Zużycie ee {new Date().getFullYear() + 1}
						</h2>
						<div className="form">
							<div className="column">
								{[...Array(historyUsage.zone)].map(
									(_, index) => {
										const zoneNumber = index + 1;
										const zoneKey = `zone${zoneNumber}`;
										return (
											<div key={zoneNumber}>
												<label>
													Strefa {zoneNumber} (kWh):
												</label>
												<input
													name={zoneKey}
													placeholder={`Strefa ${zoneNumber} (kWh)`}
													value={
														historyUsage[zoneKey]
													}
													className="input-form"
													onChange={(e) =>
														setHistoryUsage(
															(prevState) => ({
																...prevState,
																[zoneKey]:
																	e.target
																		.value,
															})
														)
													}
												/>
												<p className="err-msg"></p>
											</div>
										);
									}
								)}
							</div>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={handleAddHistoryUsage}>
								Zapisz
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</PPELayout>
	);
};

export default PPE;
