import React, { useEffect, useState } from 'react';
import { ImportDataPageLayout } from './ImportDataPage.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { uploadFile } from '../../api/uploadFile';
import Select from 'react-select';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const ImportDataPage = () => {
    const validTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
    ];

    const [files, setFiles] = useState({
        file1: null,
        file2: null,
    });
    const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState({
		value: 2,
		label: 'Grupa zakupowa rozdzielona',
	});
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [error, setError] = useState('');
    const [file1Uploaded, setFile1Uploaded] = useState(false);
    const customStyles = useStyledSelect();
    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                if(decoded.adm != 1){
                    navigate('/noauth')
                }
            } catch (error) {
                console.error('Failed to decode token', error);
            }
        }

        const fetchData = async () => {
            try {
                const response = await sendRequest({
                    action: 'get-shopping-group',
                });
                setOptions(
                    response.data._data_.map((group) => ({
                        value: group.id,
                        label: group.name,
                    }))
                );
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const isValidFile = (file) => {
        return validTypes.includes(file.type);
    };

    const handleFileChange = (file, number) => {
        if (!file) {
            setFiles(prev => ({
                ...prev,
                [number === 1 ? 'file1' : 'file2']: null,
            }));
            return;
        }

        if (!isValidFile(file)) {
            alert('Wybierz plik z rozszerzeniem .xls, .xlsx lub .csv');
            return;
        }

        setFiles(prev => ({
            ...prev,
            [number === 1 ? 'file1' : 'file2']: file,
        }));
    };

    const handleUpload = async (fileNumber) => {
        setIsLoading(true);
        const fileKey = `file${fileNumber}`;
        const formData = new FormData();
        formData.append('file', files[fileKey]);
        formData.append('action', `import-xls-${fileNumber}`);
        formData.append('shopping_group_id', selectedOption.value);
    
        try {
            const response = await uploadFile(formData);
    
            if (response.data.status === 1) {
                if (fileNumber === 1) {
                    setFile1Uploaded(true);
                    setMsg('');
                    setError('');
                } else if (fileNumber === 2) {
                    setMsg('Import zakończony pomyślnie');
                    setError('');
                }
            } else {
                setError('Import nie powiódł się');
            }
        } catch (error) {
            console.error('Error during file upload:', error);
            setError('Wystąpił błąd podczas importu');
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <ImportDataPageLayout>
            <h1 className="header">Import danych z XLS</h1>
            <p style={{color: 'red'}}>{error}</p>
            {isLoading ? (
                <Loading />
            ) : msg ? (
                <h2 className='msg'>{msg}</h2>
            ) : (
                <>
                    <div className="input-group">
                        <label className="label">
                            Pozycja 1. Wybierz plik z załącznikiem nr. 1:
                            <input
                                type="file"
                                className="input"
                                disabled={file1Uploaded}
                                onChange={(e) => handleFileChange(e.target.files[0], 1)}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </label>
                        {!file1Uploaded && (<button className='skip-button' onClick={() => setFile1Uploaded(true)}>Pomiń</button>)}
                        {!file1Uploaded && files.file1 && (
                            <Button className="button" onClick={() => handleUpload(1)}>
                                Wyślij
                            </Button>
                        )}
                    </div>
                    {file1Uploaded && (
                        <div className="input-group">
                            <label className="label">
                                Pozycja 2. Wybierz plik z załącznikiem nr. 2:
                                <Select
                                    options={options}
                                    styles={customStyles}
                                    value={selectedOption}
                                    onChange={setSelectedOption}
                                />
                                <input
                                    type="file"
                                    className="input"
                                    onChange={(e) => handleFileChange(e.target.files[0], 2)}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                            </label>
                            {files.file2 && (
                                <Button className="button" onClick={() => handleUpload(2)}>
                                    Wyślij
                                </Button>
                            )}
                        </div>
                    )}
                </>
            )}
        </ImportDataPageLayout>
    );
};

export default ImportDataPage;
