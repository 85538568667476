import React, { useRef } from 'react';
import styled from 'styled-components';

const ModalLayout = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 15;

	.modal-content {
		background-color: ${(props) => props.theme.white};
		padding: 20px;
		border-radius: 8px;
		min-width: 400px;
		max-width: 600px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		font-size: 12px;
	}
	.modal-actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;

		.cancel {
			margin-right: 10px;
		}
		.add {
			margin-left: 10px;
		}
	}
	h2 {
		margin-bottom: 25px;
	}
`;

const Modal = ({ children, setIsModalOpen }) => {
	const modalContentRef = useRef(null);

	return (
		<ModalLayout
			onMouseDown={() => setIsModalOpen(false)}
			className="modal-overlay">
			<div
				onMouseDown={(e) => e.stopPropagation()}
				className="modal-content"
				ref={modalContentRef}>
				{children}
			</div>
		</ModalLayout>
	);
};

export default Modal;
