import React from "react";
import { TailSpin } from "react-loader-spinner";
import { useTheme } from "styled-components";

const LoadingComponent = () => {
    const theme = useTheme()
	return (
		<TailSpin
			height="80"
			width="80"
			color={theme.primary}
			ariaLabel="tail-spin-loading"
			radius="1"
			wrapperStyle={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "55vh",
				width: "100%",
				backgroundColor: theme.white,
			}}
			visible={true}
		/>
	);
};

export default LoadingComponent;
