import { ThreeDots } from "react-loader-spinner";
import { useTheme } from "styled-components";

const LoadingThreeDots = () => {
	const theme = useTheme();
	return (
		<ThreeDots
			height="20"
			width="70"
			radius="9"
			color={theme.primary}
			ariaLabel="three-dots-loading"
			wrapperStyle={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				height: "100%",
			}}
			wrapperClassName=""
			visible={true}
		/>
	);
};

export default LoadingThreeDots;
