import DataTable from 'react-data-table-component';
import styled from 'styled-components';

export const TariffsLayout = styled.div`
	margin: 60px 0 50px 0;

	.table-container {
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;

		.add-button {
			width: 242px;
			margin-bottom: 10px;
		}
	}
	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 15;

		.modal-content {
			background-color: ${(props) => props.theme.white};
			padding: 20px;
			border-radius: 8px;
			min-width: 400px;
			max-width: 600px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			font-size: 12px;

			.modal-actions {
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;
			}

			.modal-actions {
				.cancel {
					margin-right: 10px;
				}
				.add {
					margin-left: 10px;
				}
			}

			.err-msg {
				color: ${(props) => props.theme.danger};
				margin-top: 2px;
				min-height: 17px;
				font-size: 11px;
			}

			h2 {
				margin-bottom: 25px;
			}

			.input-form {
				width: 100%;
				margin-top: 5px;
				padding: 10px 10px;
				border: none;
				outline: none;
				border-radius: 5px;
				background-color: ${(props) => props.theme.secondary};
				color: ${(props) => props.theme.fontcolor};
			}
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px
					${(props) => props.theme.secondary} inset !important;
			}
		}
	}
`;

export const TariffsDataTable = styled(DataTable)`	overflow: auto;

.rdt_Table {
	margin-top: 29px;
	background-color: ${(props) => props.theme.white};
}

.rdt_TableCell {
	justify-content: center;
	color: ${(props) => props.theme.tablebodyfontcolor};
	background-color: ${(props) => props.theme.secondary};
	border: 1px solid ${(props) => props.theme.white};
	font-size: 11px;
	color: ${(props) => props.theme.fontcolor};
	padding: 4px 4px;
}

.rdt_TableRow {
	border: none;
	min-height: 1px;
	background-color: transparent;
}

.rdt_TableCol {
	background-color: ${(props) => props.theme.primary};
	border: 1px solid ${(props) => props.theme.white};
	color: #282828;
	font-size: 11px;
	font-weight: 700;
	justify-content: center;
	padding: 0 4px;
}

.rdt_TableCol_Sortable {
	div {
		text-align: center;
		vertical-align: middle;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: normal;
		overflow-wrap: normal;
		word-wrap: normal;
	}
}

.rdt_TableCol::after {
	position: absolute;
	top: -30px;
	left: 0;
	right: 0;
	background-color: ${(props) => props.theme.primary};
	color: #282828;
	text-align: center;
	font-weight: bold;
	padding: 9px;
	box-sizing: border-box;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	z-index: 1;
}

.rdt_TableCol:nth-child(3)::after {
	content: 'Stawka za kWh';
	width: 388px;
	height: 28px;
}

.rdt_TableHeadRow {
	border: none;
	background-color: ${(props) => props.theme.white};
	min-height: 38px;

	.rdt_TableCol:first-child {
		border-top-left-radius: 5px;
	}

	.rdt_TableCol:last-child {
		border-top-right-radius: 5px;
	}
}

.rdt_TableBody {
	background-color: ${(props) => props.theme.white};
	.rdt_TableRow:last-child {
		background-color: ${(props) => props.theme.white};
		.rdt_TableCell:first-child {
			border-bottom-left-radius: 5px;
		}

		.rdt_TableCell:last-child {
			border-bottom-right-radius: 5px;
		}
	}
}

.rdt_ExpanderRow {
	background-color: ${(props) => props.theme.white};
}

.gOTktU,
.dEaAMx {
	background-color: ${(props) => props.theme.white} !important;
	color: ${(props) => props.theme.fontcolor};
}
`;
