import { useState, useRef, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { StawkaLayout } from './Stawka.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import DatePicker from 'react-date-picker';

const Stawka = () => {
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [data, setData] = useState();
	const [groups, setGroups] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [deletingRow, setDeletingRow] = useState();
	const currentDate = new Date();
	const [newStawka, setNewStawka] = useState({
		stawka: '',
		shopping_group_id: '',
		termin_przekazania: currentDate,
	});
	const [errors, setErrors] = useState({
		stawka: '',
		shopping_group_id: '',
		termin_przekazania: '',
	});

	const fetchData = async () => {
		const getStawka = {
			action: 'get-stawka',
		};
		const getShoppingGroup = {
			action: 'get-shopping-group',
		};
		try {
			const response = await sendRequest(getStawka);
			setData(response.data._data_);
			const groupsResponse = await sendRequest(getShoppingGroup);
			setGroups(
				groupsResponse.data._data_.map((group) => ({
					value: group.id,
					label: group.name,
				}))
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const clearForm = () => {
		setNewStawka({
			stawka: '',
			shopping_group_id: '',
			termin_przekazania: currentDate,
		});
		setErrors({
			stawka: '',
			shopping_group_id: '',
			termin_przekazania: '',
		});
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setIsModalDeleteOpen(false);
		setDeletingRow();
	};

	const handleDeleteStawka = async () => {
		const deleteStawka = {
			action: 'delete-stawka',
			_data_: {
				stawka: deletingRow.stawka,
				termin_przekazania: deletingRow.termin_przekazania,
			},
		};
		try {
			await sendRequest(deleteStawka);
			fetchData();
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddStawka = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const formattedDate = newStawka.termin_przekazania
				? `${newStawka.termin_przekazania.getFullYear()}-${String(
						newStawka.termin_przekazania.getMonth() + 1
				  ).padStart(2, '0')}-${String(
						newStawka.termin_przekazania.getDate()
				  ).padStart(2, '0')}`
				: '';

			const stawkaData = {
				stawka: newStawka.stawka,
				termin_przekazania: formattedDate,
				shopping_group_id: newStawka.shopping_group_id.value,
			};

			const addStawka = {
				action: 'add-stawka',
				_data_: stawkaData,
			};

			try {
				await sendRequest(addStawka);
				closeModal();
				clearForm();
				fetchData();
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const validateForm = () => {
		let isValid = true;
		let newErrors = {
			stawka: '',
			termin_przekazania: '',
		};

		const decimalPattern = /^\d+(\.\d+)?$/;
		['stawka'].forEach((key) => {
			if (!newStawka[key] || !decimalPattern.test(newStawka[key])) {
				newErrors[key] =
					'To pole jest wymagane i musi zawierać tylko liczby.';
				isValid = false;
			}
		});

		if (
			!newStawka.termin_przekazania || 
			isNaN(new Date(newStawka.termin_przekazania).getTime())
		) {
			newErrors.termin_przekazania =
				'To pole musi zawierać prawidłową datę.';
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewStawka((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '15px' }}>
					<Icozamknij
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							setDeletingRow(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
		{
			name: 'Stawka (zł / kWh)',
			selector: (row) => row.stawka,
			grow: 10,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Stawka Rok',
			selector: (row) => row.stawka_rok,
			grow: 10,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Termin przekazania',
			selector: (row) => row.termin_przekazania,
			grow: 10,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Grupa zakupowa',
			selector: (row) => row.shopping_group_nazwa,
			grow: 10,
			sortable: true,
			sortField: '0',
		},
	];

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	return (
		<StawkaLayout>
			<h2 className="page-title">Stawki wartości zamówienia</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={
						data && data.length > 0
							? data.filter((customer) =>
									Object.values(customer).some((value) =>
										String(value)
											.toLowerCase()
											.includes(searchValue.toLowerCase())
									)
							  )
							: []
					}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<div className="table-menu">
					<Button
						className="add-button"
						onClick={() => setIsModalOpen(true)}
						style={{
							fontSize: '14px',
						}}>
						Dodaj stawkę
					</Button>
					<input
						placeholder="Szukaj..."
						className="search-input"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
				</div>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Dodaj Stawkę</h2>
						<div>
							<label>Stawka:</label>
							<input
								name="stawka"
								placeholder="Stawka"
								value={newStawka.stawka}
								className="input-form"
								onChange={handleInputChange}
							/>
							<p className="err-msg">{errors.stawka}</p>
						</div>
						<div>
							<label>Termin przekazania:</label>
							<DatePicker
								value={newStawka.termin_przekazania}
								dateFormat="yyyy-MM-dd"
								onChange={(date) => {
									setNewStawka((prevState) => ({
										...prevState,
										termin_przekazania: date,
									}));
								}}
							/>
							<p className="err-msg">
								{errors.termin_przekazania}
							</p>
						</div>
						<div>
							<label>Grupa:</label>
							<Select
								options={groups}
								classNamePrefix="react-select"
								placeholder="Wybierz grupę..."
								styles={customStyles}
								value={newStawka.shopping_group_id}
								onChange={(selectedOptions) => {
									setNewStawka((prevState) => ({
										...prevState,
										shopping_group_id: selectedOptions,
									}));
								}}
							/>
							<p className="err-msg">
								{errors.shopping_group_id}
							</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button className="add" onClick={handleAddStawka}>
								Dodaj
							</Button>
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>
							Czy na pewno chcesz usunąć stawkę:{' '}
							{deletingRow.stawka}?
						</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeleteStawka()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</StawkaLayout>
	);
};

export default Stawka;
