import { useState, useRef, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { Zalacznik2KompleksowaLayout } from './Zalacznik2Kompleksowa.style';
import { UsageDataTable } from './Zalacznik2Kompleksowa.style';
import { sendRequest } from '../../../api/sendRequest';
import Loading from '../../../components/Loading/Loading';
import { useStyledSelect } from '../../../components/Select/StyledSelect';
import { font } from '../../../font/font';

const Zalacznik2Kompleksowa = () => {
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(true);
	const [gminaFilter, setGminaFilter] = useState([]);
	const [contactFilter, setContactFilter] = useState([]);
	const [odbiorcaFilter, setOdbiorcaFilter] = useState([]);
	const [sprzedawcaFilter, setSprzedawcaFilter] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const [sumData, setSumData] = useState();
	const [data, setData] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [tableTitle, setTableTitle] = useState('');
	const [gminaId, setGminaId] = useState([]);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const groupType = searchParams.get('groupType');
	const type = searchParams.get('type');
	const recipient_id = searchParams.get('recipient_id');

	const fetchData = async () => {
		const getGminy = {
			action: 'get-ppe-list',
			shopping_group_id: groupType,
			odbiorca_id: recipient_id,
		};
		const getGroups = {
			action: 'get-shopping-group',
		};
	
		const numberFormatter = new Intl.NumberFormat('pl-PL', { useGrouping: true });
	
		try {
			const groupsResponse = await sendRequest(getGroups);
			const response = await sendRequest(getGminy);
	
			let totalUsageZone1 = 0;
			let totalUsageZone2 = 0;
			let totalUsageZone3 = 0;
			let totalUsage = 0;
	
			const enrichedData = response.data._data_.map((item, index) => {
				const usageZone1 = Number(item.usage_zone1) || 0;
				const usageZone2 = Number(item.usage_zone2) || 0;
				const usageZone3 = Number(item.usage_zone3) || 0;
				const usage = Number(item.usage_zone3) + Number(item.usage_zone2) + Number(item.usage_zone1) || 0;
	
				totalUsageZone1 += usageZone1;
				totalUsageZone2 += usageZone2;
				totalUsageZone3 += usageZone3;
				totalUsage += usageZone1 + usageZone2 + usageZone3;
	
				return {
					...item,
					lp: index + 1,
					usage_zone1: numberFormatter.format(usageZone1),
					usage_zone2: numberFormatter.format(usageZone2),
					usage_zone3: numberFormatter.format(usageZone3),
					usage: numberFormatter.format(usage)
				};
			});
			setData(enrichedData);
			setSumData({
				usage_zone1: numberFormatter.format(totalUsageZone1),
				usage_zone2: numberFormatter.format(totalUsageZone2),
				usage_zone3: numberFormatter.format(totalUsageZone3),
				usage: numberFormatter.format(totalUsage)
			});
	
			const matchingGroup = groupsResponse.data._data_.find(
				(group) => group.id == groupType
			);
			if (matchingGroup) {
				if (type === 'porozumienie') {
					setTableTitle(`do Porozumienia: ${matchingGroup.name}`);
				} else if (type === 'umowa') {
					setTableTitle(`do Umowy: ${matchingGroup.name}`);
				} else {
					setTableTitle(matchingGroup.name);
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};
	
	useEffect(() => {
		fetchData();
		const storedGminaId = localStorage.getItem('gmina_id');
		if (storedGminaId) {
			setGminaId(JSON.parse(storedGminaId));
		}
	}, []);

	useEffect(() => {
		setIsLoading(true);
		fetchData();
	}, [location]);

	const getUniqueOptions = (key) => {
		const unique = [...new Set(data.map((item) => item[key]).filter(value => value !== null))];
		const sortedUnique = unique.sort((a, b) => a.localeCompare(b));
		return sortedUnique.map((value) => ({ value, label: value }));
	};	
	
	const exportToExcel = () => {
		let selectedGminaNazwa;
		const selectedGminaId = sessionStorage.getItem('selectedGminaId');
		if (selectedGminaId) {
			if (selectedGminaId === 'all') {
				selectedGminaNazwa = gminaId[0].gmina_nazwa;
			} else {
				const selectedGmina = gminaId.find((g) => g.gmina_id == selectedGminaId);
				selectedGminaNazwa = selectedGmina ? selectedGmina.gmina_nazwa : gminaId[0].gmina_nazwa;
			}
		} else {
			selectedGminaNazwa = gminaId[0].gmina_nazwa;
		}
	
		const transformedData = filteredData.map((item, index) => {
			const usageZone1 = parseFormattedNumber(item.usage_zone1) || 0;
			const usageZone2 = parseFormattedNumber(item.usage_zone2) || 0;
			const usageZone3 = parseFormattedNumber(item.usage_zone3) || 0;
	
			return {
				'Lp.': index + 1,
				Gmina: item.gmina_nazwa,
				'Nabywca / Płatnik': item.customer_contact,
				'Ulica Nabywca': item.customer_ulica,
				'Nr budynku Nabywca': item.customer_budynek,
				'Kod pocztowy Nabywca': item.customer_kod,
				'Miejscowość Nabywca': item.customer_miasto,
				'NIP Nabywca': item.customer_nip,
				'Nazwa Odbiorca': item.odbiorca_nazwa,
				'Miejscowość Odbiorca': item.odbiorca_miasto,
				'Ulica Odbiorca': item.odbiorca_ulica,
				'Nr budynku Odbiorca': item.odbiorca_budynek,
				'Nr lokalu Odbiorca': item.odbiorca_lokal,
				'Kod pocztowy Odbiorca': item.odbiorca_kod,
				'Poczta Odbiorca': item.odbiorca_poczta,
				'Nazwa punktu (PPE)': item.ppe_nazwa,
				'Miejscowość punktu (PPE)': item.ppe_miasto,
				'Ulica punktu (PPE)': item.ppe_ulica,
				'Nr budynku punktu (PPE)': item.ppe_budynek,
				'Nr lokalu punktu (PPE)': item.ppe_lokal,
				'Kod pocztowy punktu (PPE)': item.ppe_kod,
				'Poczta punktu (PPE)': item.ppe_poczta,
				'Gmina punktu (PPE)': item.ppe_gmina,
				'Numer PPE': item.ppe_id,
				'Moc umowna [kW]': item.ppe_moc_umowna,
				Taryfa: item.taryfa_nazwa,
				'STREFA 1 [kWh]': usageZone1,
				'STREFA 2 [kWh]': usageZone2,
				'STREFA 3 [kWh]': usageZone3,
				'Zużycie łączne [kWh]': usageZone1 + usageZone2 + usageZone3,
				'Aktualny sprzedawca energii elektr.': item.sprzedawca_nazwa,
				'Data obowiązywania umowy': item.ppe_umowa_start,
				'Rodzaj umowy': item.current_shopping_group_nazwa,
				'Nazwa OSD': item.osd_nazwa,
				'STATUS PROSUMENTA/ STATUS WYTWÓRCY': item.ppe_prosument,
			};
		});
	
		const totalRow = {
			Taryfa: 'Suma',
			'STREFA 1 [kWh]': transformedData.reduce((acc, item) => acc + item['STREFA 1 [kWh]'], 0),
			'STREFA 2 [kWh]': transformedData.reduce((acc, item) => acc + item['STREFA 2 [kWh]'], 0),
			'STREFA 3 [kWh]': transformedData.reduce((acc, item) => acc + item['STREFA 3 [kWh]'], 0),
			'Zużycie łączne [kWh]': transformedData.reduce((acc, item) => acc + item['Zużycie łączne [kWh]'], 0),
		};
		transformedData.push(totalRow);
	
		const ws = XLSX.utils.aoa_to_sheet([
			[null, `JST / inny podmiot: ${selectedGminaNazwa}`],
			[null, 'Załącznik nr 2'],
			[null, 'wykaz punktów poboru energii elektrycznej'],
		]);
	
		XLSX.utils.sheet_add_json(ws, [{}], { skipHeader: true, origin: -1 });
	
		XLSX.utils.sheet_add_json(ws, transformedData, { origin: 4 });
	
		const wb = XLSX.utils.book_new();
		const currentDateTime = new Date()
			.toLocaleString()
			.replace(/[\s:]/g, '_');
		const fileName = `zalacznik2_${currentDateTime}.xlsx`;
	
		XLSX.utils.book_append_sheet(wb, ws, 'Dane');
		XLSX.writeFile(wb, fileName);
	};
	
	const exportToPDF = () => {
		let selectedGminaNazwa;
		const selectedGminaId = sessionStorage.getItem('selectedGminaId');
		if (selectedGminaId) {
			if (selectedGminaId === 'all') {
				selectedGminaNazwa = gminaId[0].gmina_nazwa;
			} else {
				const selectedGmina = gminaId.find(
					(g) => g.gmina_id == selectedGminaId
				);
				selectedGminaNazwa = selectedGmina
					? selectedGmina.gmina_nazwa
					: gminaId[0].gmina_nazwa;
			}
		} else {
			selectedGminaNazwa = gminaId[0].gmina_nazwa;
		}
		const doc = new jsPDF({
			orientation: 'landscape',
			unit: 'mm',
			format: 'a3',
		});

		doc.addFileToVFS('CustomFont.ttf', font);
		doc.addFont('CustomFont.ttf', 'CustomFont', 'normal');
		doc.setFont('CustomFont');
		doc.setFontSize(12);

		const pageWidth = doc.internal.pageSize.getWidth();

		doc.text(`JST / inny podmiot: ${selectedGminaNazwa}`, 20, 10);
		doc.text(
			'Załącznik nr 2 - wykaz punktów poboru energii elektrycznej',
			pageWidth - 20 - doc.getTextWidth('Załącznik nr 2 - wykaz punktów poboru energii elektrycznej'),
			10
		);

		const styles = {
			font: 'CustomFont',
			lineWidth: 0.15,
			lineColor: [0, 0, 0],
			fontSize: 5.5,
			cellPadding: 1,
			valign: 'middle',
		};

		const headStyles = {
			fillColor: [254, 228, 91],
			textColor: [0, 0, 0],
			lineWidth: 0.15,
			lineColor: [0, 0, 0],
		};

		const columnStyles = {
			3: {
				columnWidth: 5,
			},
			6: {
				columnWidth: 13,
			},
			10: {
				columnWidth: 5,
			},
			15: {
				columnWidth: 13,
			},
			17: {
				columnWidth: 6,
			},
			22: {
				columnWidth: 22,
			},
			28: {
				columnWidth: 10,
			},
			29: {
				columnWidth: 14,
			},
			31: {
				columnWidth: 22,
			},
			32: {
				columnWidth: 18,
			},
			33: {
				columnWidth: 10,
			},
		};

		const summaryRow = [
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'Suma',
			sumData.usage_zone1,
			sumData.usage_zone2,
			sumData.usage_zone3,
			sumData.usage,
		];

		const columnNumbers = [
			'1.',
			'2.',
			'3.',
			'4.',
			'5.',
			'6.',
			'7.',
			'8.',
			'9.',
			'10.',
			'11.',
			'12.',
			'13.',
			'14.',
			'15.',
			'16.',
			'17.',
			'18.',
			'19.',
			'20.',
			'21.',
			'22.',
			'23.',
			'24.',
			'25.',
			'26.',
			'27.',
			'28.',
			'29.',
			'30.',
			'31.',
			'32.',
			'33.',
			'34.',
		];

		const head = [
			columnNumbers,
			[
				'Gmina',
				'Nabywca / Płatnik (Nazwa Gminy/Miasta/Firma)',
				'Ulica',
				'Nr budynku',
				'kod pocztowy',
				'Miejscowość',
				'NIP',
				'Nazwa Odbiorcy',
				'Miejscowość',
				'Ulica',
				'Nr budynku',
				'Nr Lokalu',
				'Kod pocztowy',
				'Poczta',
				'Nazwa punktu poboru (PPE)',
				'PPE_Miejscowość',
				'PPE_Ulica',
				'PPE_Nr budynku',
				'PPE_Nr lokalu',
				'PPE_Kod pocztowy',
				'PPE_Poczta',
				'PPE_Gmina',
				'Numer PPE wg numeracji: 5902438xxxxxxxxxxx',
				'Moc umowna [kW]',
				'Taryfa',
				'STREFA 1 [kWh]',
				'STREFA 2 [kWh]',
				'STREFA 3 [kWh]',
				// 'STREFA 4 [kWh]',
				// 'STREFA 5 [kWh]',
				// 'STREFA 6 [kWh]',
				'Zużycie energii łącznie strefa 1, 2, 3 ',
				'Aktualny sprzedawca energii elektr.',
				'Data obowiązywania umowy',
				'Rodzaj umowy: Umowa Kompleskowa/ Umowa sprzedaży (rozdzielona)',
				'Nazwa OSD (Oddział Dystrybutora)',
				'STATUS PROSUMENTA/ STATUS WYTWÓRCY (np. fotowoltaika)',
			],
		];

		const body = filteredData
			.filter((item) =>
				Object.values(item).some((value) =>
					String(value)
						.toLowerCase()
						.includes(searchValue.toLowerCase())
				)
			)
			.map((item) => [
				item.gmina_nazwa,
				item.customer_contact,
				item.customer_ulica,
				item.customer_budynek,
				item.customer_kod,
				item.customer_miasto,
				item.customer_nip,
				item.odbiorca_nazwa,
				item.odbiorca_miasto,
				item.odbiorca_ulica,
				item.odbiorca_budynek,
				item.odbiorca_lokal,
				item.odbiorca_kod,
				item.odbiorca_poczta,
				item.ppe_nazwa,
				item.ppe_miasto,
				item.ppe_ulica,
				item.ppe_budynek,
				item.ppe_lokal,
				item.ppe_kod,
				item.ppe_poczta,
				item.ppe_gmina,
				item.ppe_id,
				item.ppe_moc_umowna,
				item.taryfa_nazwa,
				item.usage_zone1,
				item.usage_zone2,
				item.usage_zone3,
				// item.usage_zone4,
				// item.usage_zone5,
				// item.usage_zone6,
				item.usage_zone1 + item.usage_zone2 + item.usage_zone3,
				// item.usage_zone4 +
				// item.usage_zone5 +
				// item.usage_zone6,
				item.sprzedawca_nazwa,
				item.ppe_umowa_start,
				item.current_shopping_group_nazwa,
				item.osd_nazwa,
				item.ppe_prosument,
			]);

		body.push(summaryRow);

		doc.autoTable({
			head: head,
			body: body,
			styles: styles,
			headStyles: headStyles,
			bodyStyles: styles,
			columnStyles: columnStyles,
			rowPageBreak: 'avoid',
		});

		const currentDateTime = new Date()
			.toLocaleString()
			.replace(/[\s:]/g, '_');
		doc.save(`zalacznik2_${currentDateTime}.pdf`);
	};

	const filteredData = data
		? data.filter((item) => {
				return (
					(gminaFilter.length === 0 ||
						gminaFilter.some(
							(filter) => filter.value === item.gmina_nazwa
						)) &&
					(contactFilter.length === 0 ||
						contactFilter.some(
							(filter) => filter.value === item.customer_contact
						)) &&
					(odbiorcaFilter.length === 0 ||
						odbiorcaFilter.some(
							(filter) =>
								filter.value === item.odbiorca_adres_full
						)) &&
					(sprzedawcaFilter.length === 0 ||
						sprzedawcaFilter.some(
							(filter) => filter.value === item.sprzedawca_nazwa
						))
				);
		  })
		: [];

	const toggleFilters = () => setShowFilters(!showFilters);

	const parseFormattedNumber = (formattedNumber) => {
		return parseFloat(formattedNumber.replace(/\s+/g, '').replace(',', '.'));
	};

	const columns = [
		{
			name: 'Lp.',
			selector: (row) => row.lp,
			grow: 1,
			width: '45px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Gmina',
			selector: (row) => row.gmina_nazwa,
			minWidth: '140px',
			sortable: true,
		},
		{
			name: 'Nabywca / Płatnik (Nazwa Gminy/Miasta/Firma)',
			selector: (row) => row.customer_contact,
			minWidth: '435px',
			maxWidth: '435px',
			sortable: true,
		},
		{
			name: 'Ulica',
			selector: (row) => row.customer_ulica,
			minWidth: '275px',
			maxWidth: '275px',
			sortable: true,
		},
		{
			name: 'Nr budynku',
			selector: (row) => row.customer_budynek,
			minWidth: '115px',
			maxWidth: '115px',
			sortable: true,
		},
		{
			name: 'Kod pocztowy',
			selector: (row) => row.customer_kod,
			minWidth: '115px',
			maxWidth: '115px',
			sortable: true,
		},
		{
			name: 'Miejscowość',
			selector: (row) => row.customer_miasto,
			minWidth: '140px',
			maxWidth: '140px',
			sortable: true,
		},
		{
			name: 'NIP',
			selector: (row) => row.customer_nip,
			minWidth: '140px',
			maxWidth: '140px',
			sortable: true,
		},
		{
			name: 'Nazwa odbiorcy',
			selector: (row) => row.odbiorca_nazwa,
			minWidth: '455px',
			sortable: true,
		},
		{
			name: 'Miejscowość',
			selector: (row) => row.odbiorca_miasto,
			minWidth: '140px',
			maxWidth: '140px',
			sortable: true,
		},
		{
			name: 'Ulica',
			selector: (row) => row.odbiorca_ulica,
			minWidth: '275px',
			maxWidth: '275px',
			sortable: true,
		},
		{
			name: 'Nr budynku',
			selector: (row) => row.odbiorca_budynek,
			minWidth: '140px',
			maxWidth: '140px',
			sortable: true,
		},
		{
			name: 'Nr lokalu',
			selector: (row) => row.odbiorca_lokal,
			minWidth: '140px',
			maxWidth: '140px',
			sortable: true,
		},
		{
			name: 'Kod pocztowy',
			selector: (row) => row.odbiorca_kod,
			minWidth: '140px',
			maxWidth: '140px',
			sortable: true,
		},
		{
			name: 'Poczta',
			selector: (row) => row.odbiorca_poczta,
			minWidth: '140px',
			maxWidth: '140px',
			sortable: true,
		},
		{
			name: 'Nazwa punktu poboru (PPE) np. szkoła, przedszkole, orlik, oświetlenie uliczne, itp.',
			selector: (row) => row.ppe_nazwa,
			minWidth: '435px',
			maxWidth: '435px',
			sortable: true,
		},
		{
			name: 'PPE_Miejscowość',
			selector: (row) => row.ppe_miasto,
			minWidth: '140px',
			maxWidth: '140px',
			sortable: true,
		},
		{
			name: 'PPE_Ulica',
			selector: (row) => row.ppe_ulica,
			minWidth: '225px',
			maxWidth: '225px',
			sortable: true,
		},
		{
			name: 'PPE_Nr budynku',
			selector: (row) => row.ppe_budynek,
			minWidth: '115px',
			maxWidth: '115px',
			sortable: true,
		},
		{
			name: 'PPE_Nr lokalu',
			selector: (row) => row.ppe_lokal,
			minWidth: '115px',
			maxWidth: '115px',
			sortable: true,
		},
		{
			name: 'PPE_Kod pocztowy',
			selector: (row) => row.ppe_kod,
			minWidth: '115px',
			maxWidth: '115px',
			sortable: true,
		},
		{
			name: 'PPE_Poczta',
			selector: (row) => row.ppe_poczta,
			minWidth: '115px',
			maxWidth: '115px',
			sortable: true,
		},
		{
			name: 'PPE_Gmina',
			selector: (row) => row.ppe_gmina,
			minWidth: '115px',
			maxWidth: '115px',
			sortable: true,
		},
		{
			name: 'Numer PPE wg numeracji: 5902438xxxxxxxxxxx',
			selector: (row) => row.ppe_id,
			minWidth: '140px',
			maxWidth: '140px',
			sortable: true,
		},
		{
			name: 'Moc umowna [kW]',
			selector: (row) => row.ppe_moc_umowna,
			minWidth: '105px',
			sortable: true,
		},
		{
			name: 'Taryfa',
			selector: (row) => row.taryfa_nazwa,
			minWidth: '95px',
			sortable: true,
		},
		{
			name: 'STREFA 1 [kWh]',
			selector: (row) => row.usage_zone1 ?? 0,
			minWidth: '75px',
			maxWidth: '75px',
			sortable: true,
			sortFunction: (a, b) => parseFormattedNumber(a.usage_zone1) - parseFormattedNumber(b.usage_zone1),
			cell: (row) => row.usage_zone1,
		},
		{
			name: 'STREFA 2 [kWh]',
			selector: (row) => row.usage_zone2 ?? 0,
			minWidth: '75px',
			maxWidth: '75px',
			sortable: true,
			sortFunction: (a, b) => parseFormattedNumber(a.usage_zone2) - parseFormattedNumber(b.usage_zone2),
			cell: (row) => row.usage_zone2,
		},
		{
			name: 'STREFA 3 [kWh]',
			selector: (row) => row.usage_zone3 ?? 0,
			minWidth: '75px',
			maxWidth: '75px',
			sortable: true,
			sortFunction: (a, b) => parseFormattedNumber(a.usage_zone3) - parseFormattedNumber(b.usage_zone3),
			cell: (row) => row.usage_zone3,
		},
		// {
		// 	name: 'STREFA 4 [kWh]',
		// 	selector: (row) => row.usage_zone4 ?? 0,
		// 	minWidth: '75px',
		// 	maxWidth: '75px',
		// 	sortable: true,
		// },
		// {
		// 	name: 'STREFA 5 [kWh]',
		// 	selector: (row) => row.usage_zone5 ?? 0,
		// 	minWidth: '75px',
		// 	maxWidth: '75px',
		// 	sortable: true,
		// },
		// {
		// 	name: 'STREFA 6 [kWh]',
		// 	selector: (row) => row.usage_zone6 ?? 0,
		// 	minWidth: '75px',
		// 	maxWidth: '75px',
		// 	sortable: true,
		// },
		{
			name: 'Zużycie łączne [kWh]',
			selector: (row) => row.usage ?? 0,
			minWidth: '95px',
			maxWidth: '95px',
			sortable: true,
			sortFunction: (a, b) => parseFormattedNumber(a.usage) - parseFormattedNumber(b.usage),
			cell: (row) => row.usage,
		},
		{
			name: 'Aktualny	sprzedawca energii elektr.',
			selector: (row) => row.sprzedawca_nazwa,
			minWidth: '225px',
			maxWidth: '225px',
			sortable: true,
		},
		{
			name: 'Data obowiązywania obecnej umowy',
			selector: (row) => row.ppe_umowa_start,
			minWidth: '135px',
			maxWidth: '135px',
			sortable: true,
		},
		{
			name: 'Rodzaj umowy',
			selector: (row) => row.current_shopping_group_nazwa,
			minWidth: '195px',
			maxWidth: '195px',
			sortable: true,
		},
		{
			name: 'Nazwa OSD',
			selector: (row) => row.osd_nazwa,
			minWidth: '175px',
			maxWidth: '175px',
			sortable: true,
		},
		{
			name: 'STATUS PROSUMENTA/ STATUS WYTWÓRCY',
			selector: (row) => row.ppe_prosument,
			minWidth: '105px',
			maxWidth: '105px',
			sortable: true,
		},
	];

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	console.log(filteredData)

	return (
		<Zalacznik2KompleksowaLayout>
			<h2 className="page-title">Załącznik nr 2 {tableTitle}</h2>
			<div className="table-container">
				<UsageDataTable
					sumData={sumData}
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={
						filteredData && filteredData.length > 0
							? filteredData.filter((customer) =>
									Object.values(customer).some((value) =>
										String(value)
											.toLowerCase()
											.includes(searchValue.toLowerCase())
									)
							  )
							: []
					}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[
						10, 20, 25, 30, 50, 75, 100, 150, 250, 500,
					]}
					fixedHeader
					defaultSortFieldId={1}
				/>
				<div className="table-menu">
					<input
						placeholder="Szukaj..."
						className="search-input"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<Button
						style={{
							fontSize: '14px',
						}}
						onClick={toggleFilters}>
						{showFilters ? 'Ukryj filtry' : 'Pokaż filtry'}
					</Button>
					<div style={{ marginLeft: 'auto' }}>
						<Button onClick={exportToPDF}>Eksportuj do PDF</Button>
						<Button
							style={{ marginLeft: '15px' }}
							onClick={exportToExcel}>
							Eksportuj do Excela
						</Button>
					</div>
					{showFilters && (
						<div className="filters-dropdown">
							<Select
								options={getUniqueOptions('gmina_nazwa')}
								onChange={(selectedOptions) =>
									setGminaFilter(selectedOptions || [])
								}
								value={gminaFilter}
								placeholder="Gminy"
								styles={customStyles}
								isMulti
							/>

							<Select
								options={getUniqueOptions('customer_contact')}
								onChange={(selectedOptions) =>
									setContactFilter(selectedOptions || [])
								}
								value={contactFilter}
								placeholder="Nazwa nabywcy"
								styles={customStyles}
								isMulti
							/>

							<Select
								options={getUniqueOptions(
									'odbiorca_adres_full'
								)}
								onChange={(selectedOptions) =>
									setOdbiorcaFilter(selectedOptions || [])
								}
								value={odbiorcaFilter}
								placeholder="Adres odbiorcy"
								styles={customStyles}
								isMulti
							/>

							<Select
								options={getUniqueOptions('sprzedawca_nazwa')}
								onChange={(selectedOptions) =>
									setSprzedawcaFilter(selectedOptions || [])
								}
								value={sprzedawcaFilter}
								placeholder="Nazwa sprzedawcy"
								styles={customStyles}
								isMulti
							/>
						</div>
					)}
				</div>
			</div>
		</Zalacznik2KompleksowaLayout>
	);
};

export default Zalacznik2Kompleksowa;
