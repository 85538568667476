import React, { useEffect, useState, useRef } from 'react'; // Import useRef
import { ImportDataPageLayout } from './ImportZalPage.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { uploadFile } from '../../api/uploadFile';
import Select from 'react-select';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import Loading from '../../components/Loading/Loading';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const ImportZalPage = () => {
	const validTypes = ['application/pdf'];

	const [file, setFile] = useState(null);
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [msg, setMsg] = useState('');
	const [error, setError] = useState('');
	const customStyles = useStyledSelect();
	const fileInputRef = useRef(null);
	const navigate = useNavigate();

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			try {
				const decoded = jwtDecode(token);
				if (decoded.adm != 1) {
					navigate('/noauth');
				}
			} catch (error) {
				console.error('Failed to decode token', error);
			}
		}

		const fetchData = async () => {
			try {
				const response = await sendRequest({
					action: 'get-shopping-group',
				});
				setOptions(
					response.data._data_.map((group) => ({
						value: group.id,
						label: group.name,
					}))
				);
			} catch (error) {
				console.error(error);
			}
		};
		fetchData();
	}, []);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (!file) {
			setFile(null);
			return;
		}

		setFile(file);
	};

	const handleUpload = async () => {
		if (!file) {
			setError('Proszę wybrać plik do importu');
			return;
		}
		if (!selectedOption) {
			setError('Proszę wybrać grupę zakupową');
			return;
		}

		setIsLoading(true);
		const formData = new FormData();
		formData.append('file', file);
		formData.append('action', 'upload-zal-3');
		formData.append('shopping_group_id', selectedOption.value);

		try {
			const response = await uploadFile(formData);
			if (response.data.status === 1) {
				setMsg('Import zakończony pomyślnie');
				setError('');
			} else {
				setError('Import nie powiódł się');
			}
		} catch (error) {
			console.error('Error during file upload:', error);
			setError('Wystąpił błąd podczas importu');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<ImportDataPageLayout>
			<h1 className="header">Import Załącznika nr 3</h1>
			<p style={{ color: 'red' }}>{error}</p>
			{isLoading ? (
				<Loading />
			) : msg ? (
				<h2 className="msg">{msg}</h2>
			) : (
				<>
					<div className="input-group">
						<label className="label">Wybierz grupę:</label>
						<Select
							options={options}
							styles={customStyles}
							value={selectedOption}
							onChange={setSelectedOption}
							placeholder="Wybierz grupę zakupową"
						/>
						<label className="label">
							Wybierz plik do importu:
							<input
								type="file"
								className="input"
								onChange={handleFileChange}
								accept="*/*"
								ref={fileInputRef}
							/>
						</label>
						{file && selectedOption && (
							<Button className="button" onClick={handleUpload}>
								Wyślij
							</Button>
						)}
					</div>
				</>
			)}
		</ImportDataPageLayout>
	);
};

export default ImportZalPage;
