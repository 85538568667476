import { useState, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { DocumentsLayout } from './Documents.style';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import Loading from '../../components/Loading/Loading';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useStyledSelect } from '../../components/Select/StyledSelect';

const Documents = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const customStyles = useStyledSelect();
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [tableTitle, setTableTitle] = useState('');
	const [err, setErr] = useState('');
	const [recipients, setRecipients] = useState('');
	const [selectedRecipient, setSelectedRecipient] = useState('');
	const [tableData, setTableData] = useState();
	const urlParams = new URLSearchParams(window.location.search);
	const groupType = urlParams.get('groupType');
	const type = urlParams.get('type');
	const selectedGmina = sessionStorage.getItem('selectedGminaId')
	const gminaIds = JSON.parse(localStorage.getItem('gmina_id'))

	const fetchData = async () => {
		try {
			const checkResponse = await sendRequest({
				action: 'check-shopping-group-by-ppe',
			});
			const hasAccess = checkResponse.data._data_.some(group => 
				group.shopping_group_enabled == 1 && group.shopping_group_id == groupType
			);
			if (!hasAccess){
				navigate('/noauth');
			}
		}catch(error){
			console.error(error)
		}
		setErr('');
		const getDocuments = {
			action: 'get-documents-list',
			type: type,
		};
		const getGroups = {
			action: 'get-shopping-group',
		};
		const getRecipients = {
			action: 'get-odbiorcy-umowa',
		};

		const fixedDocuments = [
			{
				id: 'fixed-1',
				name: 'Załącznik nr 1 do Porozumienia',
				ctime: 'N/A',
				description: 'Stały dokument',
				active: 1,
			},
			{
				id: 'fixed-2',
				name: 'Załącznik nr 1 do Umowy kompleksowej',
				ctime: 'N/A',
				description: 'Stały dokument',
				active: 1,
			},
			{
				id: 'fixed-2',
				name: 'Załącznik nr 1 do Umowy Sprzedaży',
				ctime: 'N/A',
				description: 'Stały dokument',
				active: 1,
			},
			{
				id: 'fixed-3',
				name: 'Załącznik nr 2 do Porozumienia',
				ctime: 'N/A',
				description: 'Stały dokument',
				active: 1,
			},
			{
				id: 'fixed-4',
				name: 'Załącznik nr 2 do Umowy kompleksowej',
				ctime: 'N/A',
				description: 'Stały dokument',
				active: 1,
			},
			{
				id: 'fixed-4',
				name: 'Załącznik nr 2 do Umowy Sprzedaży',
				ctime: 'N/A',
				description: 'Stały dokument',
				active: 1,
			},
			{
				id: 'fixed-5',
				name: 'Załącznik nr 3 do Umowy kompleksowej',
				ctime: 'N/A',
				description: 'Stały dokument',
				active: 1,
			},
			{
				id: 'fixed-5',
				name: 'Załącznik nr 3 do Umowy Sprzedaży',
				ctime: 'N/A',
				description: 'Stały dokument',
				active: 1,
			},
			{
				id: 'fixed-5',
				name: 'Załącznik nr 3 do Porozumienia',
				ctime: 'N/A',
				description: 'Stały dokument',
				active: 1,
			}
		];

		const filteredFixedDocuments = fixedDocuments.filter((document) => {
			return (
				(type == 1 && document.name.includes('Porozumienia')) ||
				(type == 2 &&
					document.name.includes('Umowy') &&
					((groupType == 1 &&
						document.name.includes('kompleksowej')) ||
						(groupType == 2 &&
							document.name.includes('Sprzedaży'))) &&
					!document.name.includes('Cennik grupy zakupowej')) ||
				(type != 2 && document.name.includes('Cennik grupy zakupowej'))
			);
		});

		try {
			const recipientsResponse = await sendRequest(getRecipients);

			setRecipients(
				recipientsResponse.data._data_
					.map((recipient) => ({
						value: recipient.odbiorca_id,
						label: recipient.odbiorca_nazwa,
					}))
					.sort((a, b) => a.label.localeCompare(b.label))
			);	

			const groupsResponse = await sendRequest(getGroups);
			const matchingGroup = groupsResponse.data._data_.find(
				(group) => group.id == groupType
			);

			if (matchingGroup) {
				let appendix = '';
				if (type == 1) {
					appendix = ': Dokumenty - porozumienie';
				} else if (type == 2) {
					appendix = ': Dokumenty - umowa';
				}
				setTableTitle(matchingGroup.name + appendix);
			}

			const documentsResponse = await sendRequest(getDocuments);
			const activeDocuments = documentsResponse.data._data_.filter(
				(doc) => doc.active === 1 && doc.shopping_group_id == groupType
			);
			const combinedDocuments = [
				...filteredFixedDocuments,
				...activeDocuments,
			];
			combinedDocuments.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});

			setTableData(combinedDocuments);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [groupType, type]);

	const onRowClicked = (row) => {
		const queryParams = new URLSearchParams();
		if (groupType) {
			queryParams.append('groupType', groupType);
		}
		if (type != 1 && !selectedRecipient) {
			setErr('Należy wybrać odbiorcę.');
			return;
		} else if (selectedRecipient) {
			queryParams.append('recipient_id', selectedRecipient.value);
		}
	
		if (gminaIds.length > 1 && (selectedGmina === null || selectedGmina === 'all')) {
			setErr('Należy wybrać uczestnika grupy zakupowej.');
			return;
		}
	
		let path = '';
		if (!String(row.id).startsWith('fixed')) {
			path = `/document/${row.id}`;
		} else {
			if (row.name.includes('Załącznik nr 1')) {
				path = '/zalacznik1';
				queryParams.set(
					'type',
					row.name.toLowerCase().includes('porozumienia')
						? 'porozumienie'
						: 'umowa'
				);
			} else if (row.name.includes('Załącznik nr 2')) {
				path = groupType == 2
					? '/zalacznik2/rozdzielona'
					: '/zalacznik2/kompleksowa';
				queryParams.set(
					'type',
					row.name.toLowerCase().includes('porozumienia')
						? 'porozumienie'
						: 'umowa'
				);
			} else if (row.name.includes('Załącznik nr 3')) {
				if (type == 1) {
					window.open('/files/cennik_grupy_zakupowej.pdf', '_blank');
				} else {
					path = groupType == 2
						? '/files/umowa_zal_nr3_gzr.pdf'
						: '/files/umowa_zal_nr3_gzk.pdf';
					window.open(path, '_blank');
				}
				return;
			} else if (row.name === 'Cennik grupy zakupowej') {
				window.open('/files/cennik_grupy_zakupowej.pdf', '_blank');
				return;
			}
		}
	
		path += `?${queryParams.toString()}`;
		navigate(path);
	};	

	const columns = [
		{
			name: 'Nazwa',
			selector: (row) => row.name,
			grow: 2,
			sortField: '0',
		}
	];
	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	// if (!isAccessVerified) {
	// 	return null;
	// }

	return (
		<DocumentsLayout>
			<h2 className="page-title">{tableTitle}</h2>
			<p className="err">{err}</p>
			{type == 2 && (
				<div className="select-container">
					<Select
						options={recipients}
						classNamePrefix="react-select"
						placeholder="Wybierz odbiorcę..."
						styles={customStyles}
						value={selectedRecipient}
						onChange={setSelectedRecipient}
					/>
				</div>
			)}
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={tableData}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
					onRowClicked={onRowClicked}
					pointerOnHover={type == 2 ? selectedRecipient : true}
				/>
				{/* <Button
					className='add-button'
					onClick={() => setIsModalOpen(true)}
					style={{
						fontSize: '14px',
					}}>
					Dodaj dokument
				</Button> */}
			</div>
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</DocumentsLayout>
	);
};

export default Documents;
