import axios from 'axios';

export async function uploadFile(formData) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const headers = {};

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    try {
        return await axios.post(apiUrl, formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
        });
    } catch (error) {
        alert(`Request error: ${error.response ? error.response.data : error.message}`);
        throw error;
    }
}
