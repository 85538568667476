import styled from 'styled-components';
import DataTable from 'react-data-table-component';

export const Zalacznik2KompleksowaLayout = styled.div`
	margin: 60px 0 50px 0;
	.table-container {
		position: relative;
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
		max-height: 90vh;

		.datable-sum {
			position: absolute;
			font-size: 11px;
			display: flex;
			gap: 10px;
			bottom: 40px;

			div{
				background-color: ${(props) => props.theme.secondary};
				padding: 4px;
			}
		}

		.add-button {
			width: 242px;
			margin-bottom: 10px;
		}
		.table-menu {
			display: flex;
			gap: 15px;
			margin-bottom: 15px;

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px
					${(props) => props.theme.secondary} inset !important;
			}

			.search-input {
				border: none;
				outline: none;
				border-radius: 5px;
				background-color: ${(props) => props.theme.secondary};
				color: ${(props) => props.theme.fontcolor};
				padding: 10px 5px;
				width: 225px;
			}
		}
	}

	.filters-dropdown {
		position: absolute;
		top: 70px;
		left: 220px;
		background-color: ${(props) => props.theme.white};
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		padding: 20px;
		border-radius: 8px;
		z-index: 100;
	}

	.filters-dropdown > *:not(:last-child) {
		margin-bottom: 10px;
	}

	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 15;

		.modal-content {
			background-color: ${(props) => props.theme.white};
			padding: 20px;
			border-radius: 8px;
			min-width: 400px;
			max-width: 600px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			font-size: 12px;

			.form {
				display: flex;
				justify-content: space-between;
				.column {
					flex: 1;
					margin: 0 10px;
					font-size: 12px;
				}
			}

			.modal-actions {
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;
			}

			.modal-actions {
				.cancel {
					margin-right: 10px;
				}
				.add {
					margin-left: 10px;
				}
			}

			.err-msg {
				color: ${(props) => props.theme.danger};
				margin-top: 2px;
				min-height: 17px;
				font-size: 11px;
			}

			h2 {
				margin-bottom: 25px;
			}

			.input-form {
				width: 100%;
				margin-top: 5px;
				padding: 10px 10px;
				border: none;
				outline: none;
				border-radius: 5px;
				background-color: ${(props) => props.theme.secondary};
				color: ${(props) => props.theme.fontcolor};
			}
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px
					${(props) => props.theme.secondary} inset !important;
			}
		}
	}
`;

export const UsageDataTable = styled(DataTable)`
	overflow: auto;

	.rdt_Table {
		margin-top: 29px;
		margin-bottom: 59px;
		background-color: ${(props) => props.theme.white};
	}

	.rdt_TableCell {
		justify-content: center;
		color: ${(props) => props.theme.tablebodyfontcolor};
		background-color: ${(props) => props.theme.secondary};
		border: 1px solid ${(props) => props.theme.white};
		font-size: 11px;
		color: ${(props) => props.theme.fontcolor};
		padding: 4px 4px;
	}

	.rdt_TableRow {
		border: none;
		min-height: 1px;
		background-color: transparent;
	}

	.rdt_TableCol {
		background-color: ${(props) => props.theme.primary};
		border: 1px solid ${(props) => props.theme.white};
		color: #282828;
		font-size: 11px;
		font-weight: 700;
		justify-content: center;
		padding: 0 4px;
	}

	.rdt_TableCol_Sortable {
		div {
			text-align: center;
			vertical-align: middle;
			display: flex;
			align-items: center;
			justify-content: center;
			white-space: normal;
			overflow-wrap: normal;
			word-wrap: normal;
		}
	}

	.rdt_TableCol::after {
		position: absolute;
		top: -30px;
		left: 0;
		right: 0;
		background-color: ${(props) => props.theme.primary};
		color: #282828;
		text-align: center;
		font-weight: bold;
		padding: 9px;
		box-sizing: border-box;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		z-index: 1;
	}

	.rdt_TableCol:nth-child(3)::after {
		content: 'NABYWCA / PŁATNIK';
		width: 1218px;
		height: 28px;
	}

	.rdt_TableCol:nth-child(9)::after {
		content: 'ODBIORCA';
		width: 1428px;
		height: 28px;
	}
	.rdt_TableCol:nth-child(16)::after {
		content: 'Punkt Poboru Energii (PPE)';
		width: 1713px;
		height: 28px;
	}

	.rdt_TableCol:nth-child(27)::after {
		content: 'Zużycie energii elektr. na podstawie danych historycznych';
		width: 318px;
		height: 28px;
	}

	.rdt_TableCol:nth-child(31)::after {
		content: 'Umowa';
		width: 833px;
		height: 28px;
	}

	.rdt_TableRow:last-child {
		.rdt_TableCell::after, .rdt_TableCell::before{
			position: absolute;
			left: 0;
			right: 0;
			height: 10px;
			background-color: ${(props) => props.theme.primary};
			color: #282828;
			text-align: center;
			font-weight: bold;
			padding: 9px;
			z-index: 1;
		}
		.rdt_TableCell::after{
			top: 100%;
		}
		.rdt_TableCell::before{
			top: 245%;
		}
		.rdt_TableCell:nth-child(26)::before {
			content: 'Suma:';
			border-bottom-left-radius: 5px;
			
		}
		.rdt_TableCell:nth-child(27)::before {
			content: '${props => props.sumData ? `${props.sumData.usage_zone1}` : ''}';
		}
		.rdt_TableCell:nth-child(28)::before {
			content: '${props => props.sumData ? `${props.sumData.usage_zone2}` : ''}';
		}
		.rdt_TableCell:nth-child(29)::before {
			content: '${props => props.sumData ? `${props.sumData.usage_zone3}` : ''}';
		}
		.rdt_TableCell:nth-child(30)::before {
			content: '${props => props.sumData ? `${props.sumData.usage}` : ''}';
			border-bottom-right-radius: 5px;
		}

		.rdt_TableCell:nth-child(2)::after {
			content: '1.';
			border-bottom-left-radius: 5px;
		}
		.rdt_TableCell:nth-child(3)::after {
			content: '2.';
		}
		.rdt_TableCell:nth-child(4)::after {
			content: '3.';
		}
		.rdt_TableCell:nth-child(5)::after {
			content: '4.';
		}
		.rdt_TableCell:nth-child(6)::after {
			content: '5.';
		}
		.rdt_TableCell:nth-child(7)::after {
			content: '6.';
		}
		.rdt_TableCell:nth-child(8)::after {
			content: '7.';
		}
		.rdt_TableCell:nth-child(9)::after {
			content: '8.';
		}
		.rdt_TableCell:nth-child(10)::after {
			content: '9.';
		}
		.rdt_TableCell:nth-child(11)::after {
			content: '10.';
		}
		.rdt_TableCell:nth-child(12)::after {
			content: '11.';
		}
		.rdt_TableCell:nth-child(13)::after {
			content: '12.';
		}
		.rdt_TableCell:nth-child(14)::after {
			content: '13.';
		}
		.rdt_TableCell:nth-child(15)::after {
			content: '14.';
		}
		.rdt_TableCell:nth-child(16)::after {
			content: '15.';
		}
		.rdt_TableCell:nth-child(17)::after {
			content: '16.';
		}
		.rdt_TableCell:nth-child(18)::after {
			content: '17.';
		}
		.rdt_TableCell:nth-child(19)::after {
			content: '18.';
		}
		.rdt_TableCell:nth-child(20)::after {
			content: '19.';
		}
		.rdt_TableCell:nth-child(21)::after {
			content: '20.';
		}
		.rdt_TableCell:nth-child(22)::after {
			content: '21.';
		}
		.rdt_TableCell:nth-child(23)::after {
			content: '22.';
		}
		.rdt_TableCell:nth-child(24)::after {
			content: '23.';
		}
		.rdt_TableCell:nth-child(25)::after {
			content: '24.';
		}
		.rdt_TableCell:nth-child(26)::after {
			content: '25.';
		}
		.rdt_TableCell:nth-child(27)::after {
			content: '26.';
		}
		.rdt_TableCell:nth-child(28)::after {
			content: '27.';
		}
		.rdt_TableCell:nth-child(29)::after {
			content: '28.';
		}
		.rdt_TableCell:nth-child(30)::after {
			content: '29.';
		}
		.rdt_TableCell:nth-child(31)::after {
			content: '30.';
		}
		.rdt_TableCell:nth-child(32)::after {
			content: '31.';
		}
		.rdt_TableCell:nth-child(33)::after {
			content: '32.';
		}
		.rdt_TableCell:nth-child(34)::after {
			content: '33.';
		}
		.rdt_TableCell:nth-child(35)::after {
			content: '34.';
			border-bottom-right-radius: 5px;
		}
	}

	.rdt_TableHeadRow {
		border: none;
		background-color: ${(props) => props.theme.white};
		min-height: 38px;

		.rdt_TableCol:first-child {
			border-top-left-radius: 5px;
		}

		.rdt_TableCol:last-child {
			border-top-right-radius: 0;
		}
	}

	.rdt_TableBody {
		background-color: ${(props) => props.theme.white};
		.rdt_TableRow:last-child {
			background-color: ${(props) => props.theme.white};
			.rdt_TableCell:first-child {
				border-bottom-left-radius: 5px;
			}

			.rdt_TableCell:last-child {
				border-bottom-right-radius: 5px;
			}
		}
	}

	.rdt_ExpanderRow {
		background-color: ${(props) => props.theme.white};
	}

	.gOTktU,
	.dEaAMx {
		background-color: ${(props) => props.theme.white} !important;
		color: ${(props) => props.theme.fontcolor};
	}
`;
