import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFile } from '../../api/uploadFile';
import { DocumentsAnalyserLayout } from './DocumentsAnalyser.style';
import { Button } from '../../components/Button/Button';
import useAccessControl from '../../hooks/useAccessControl';
import { TailSpin } from 'react-loader-spinner';
import { useTheme } from 'styled-components';
import { StyledDataTable } from '../../components/Table/Table.styles';
import * as XLSX from 'xlsx';

const DocumentsAnalyser = () => {
	const theme = useTheme();
	const isAccessVerified = useAccessControl();
	const [file, setFile] = useState(null);
	const [data, setData] = useState(null);
	const [info, setInfo] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const onDrop = (acceptedFiles) => {
		const acceptedFile = acceptedFiles[0];
		if (acceptedFile.type === 'application/pdf') {
			setFile(acceptedFile);
		} else {
			console.error('Dozwolone są tylko pliki PDF.');
		}
	};

	const handleUpload = async () => {
		if (!file) {
			return;
		}

		setIsLoading(true);

		const formData = new FormData();
		formData.append('file', file);
		formData.append('action', 'upload-pdf');

		try {
			const response = await uploadFile(formData);
			const data = Object.entries(response.data.info.ppe).map(
				([ppeNumber, ppeData]) => ({
					numer_ppe: ppeNumber,
					...ppeData,
				})
			);
			setInfo(response.data.info);
			setData(data);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const columns = [
		{
			name: 'Numer PPE',
			selector: (row) => row.numer_ppe,
			sortable: true,
			sortField: '0',
			maxWidth: '160px',
		},
		{
			name: 'Nazwa PPE',
			selector: (row) => row.nazwa,
			sortable: true,
			sortField: '0',
			maxWidth: '350px',
		},
		{
			name: 'Taryfa',
			selector: (row) => row.grupa_taryfowa,
			sortable: true,
			maxWidth: '70px',
			sortField: '0',
		},
		{
			name: 'Energia czynna - zużycie 1 (kWh)',
			selector: 'stawka_1_ilosc',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Stawka 1 (zł/kWh)',
			selector: 'stawka_1',
			sortable: true,
			sortField: '0',
			maxWidth: '145px',
			conditionalCellStyles: [
				{
					when: (row) => row.status_stawka_1 === 1,
					style: {
						backgroundColor: theme.danger,
						color: 'white',
						justifyContent: 'center',
					},
				},
			],
		},
		{
			name: 'Energia czynna - zużycie 2 (kWh)',
			selector: 'stawka_2_ilosc',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Stawka 2 (zł/kWh)',
			selector: 'stawka_2',
			sortable: true,
			sortField: '0',
			maxWidth: '145px',
			conditionalCellStyles: [
				{
					when: (row) => row.status_stawka_2 === 1,
					style: {
						backgroundColor: theme.danger,
						color: 'white',
						justifyContent: 'center',
					},
				},
			],
		},
		{
			name: 'Energia bierna',
			selector: (row) =>
				row.energia_bierna == 1 ? 'Tak' : 'Nie',
			sortable: true,
			sortField: '0',
			maxWidth: '120px',
			conditionalCellStyles: [
				{
					when: (row) => row.energia_bierna == 1,
					style: {
						backgroundColor: theme.success,
						color: 'white',
						justifyContent: 'center',
					},
				},
			],
		},
	];

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: { 'application/pdf': ['.pdf'] },
	});

	const handleReset = () => {
		setFile(null);
		setData(null);
		setInfo(null);
	};

	const exportToExcel = () => {
		const dataToExport = data.map((item) => ({
			'Numer PPE': item.numer_ppe,
			'Taryfa': item.grupa_taryfowa,
			'Energia czynna - zużycie 1 (kWh)': item.stawka_1_ilosc,
			'Stawka 1 (zł/kWh)': item.stawka_1,
			'Energia czynna - zużycie 2 (kWh)': item.stawka_2_ilosc,
			'Stawka 2 (zł/kWh)': item.stawka_2,
		}));

		const ws = XLSX.utils.json_to_sheet(dataToExport);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Analiza Dokumentów');

		const exportFileName = `AnalizaDokumentow_${new Date().toLocaleDateString()}.xlsx`;

		XLSX.writeFile(wb, exportFileName);
	};

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	if (!isAccessVerified) {
		return null;
	}

	return (
		<DocumentsAnalyserLayout>
			<h2>Zweryfikuj stawkę zakupu energii z faktury i ilość PPE</h2>
			{data === null ? (
				<>
					<div {...getRootProps()} style={dropzoneStyles}>
						<input {...getInputProps()} />
						{!file && (
							<p>
								<em>
									Kliknij tutaj lub przeciągnij plik PDF...
								</em>
							</p>
						)}
						{file && (
							<div>
								<h4>Wybrany plik:</h4>
								<p>{file.name}</p>
							</div>
						)}
					</div>
					{file && <Button onClick={handleUpload}>Zweryfikuj</Button>}
				</>
			) : (
				<div className="table-container">
					<StyledDataTable
						noDataComponent="Brak danych spełniających kryteria"
						columns={columns}
						data={data}
						pagination
						paginationPerPage={25}
						paginationRowsPerPageOptions={[
							10, 20, 25, 30, 50, 75, 100, 150, 250, 500,
						]}
						fixedHeader
					/>
					<div className='buttons'>
						<Button className="button" onClick={handleReset}>
							Wczytaj nowy dokument
						</Button>
						<Button
							className="export-button"
							onClick={exportToExcel}>
							Eksportuj do Excela
						</Button>
					</div>
					<div className="ppe-dif">
						<p>Ilość PPE</p>
						<div>
							<div>
								<p>System:</p>
								<p>{info.ppe_system}</p>
							</div>
							<div>
								<p>Faktura:</p>
								<p>{info.ppe_invoice}</p>
							</div>
						</div>
					</div>
					<div className='legend'>
						<div className='square'/><span> - wartość niezgodna z systemem</span>

					</div>
				</div>
			)}
		</DocumentsAnalyserLayout>
	);
};

const dropzoneStyles = {
	border: '2px dashed white',
	borderRadius: '4px',
	padding: '20px',
	textAlign: 'center',
	cursor: 'pointer',
};

export default DocumentsAnalyser;
