import { useState, useRef, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { AdminDocumentsLayout } from './AdminDocuments.style';
import { EditorLayout } from './AdminDocuments.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import Loading from '../../components/Loading/Loading';
import { useTheme } from 'styled-components';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import Switch from 'rc-switch';
import Select from 'react-select';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import useAccessControl from '../../hooks/useAccessControl';

const AdminDocuments = () => {
	const isAccessVerified = useAccessControl();
	const [content, setContent] = useState('');
	const [deltaContent, setDeltaContent] = useState({});
	const [editor, setEditor] = useState(null);
	const quillRef = useRef(null);
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [initialContent, setInitialContent] = useState(null);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
		useState(false);
	const [tableData, setTableData] = useState();
	const [users, setUsers] = useState();
	const [groups, setGroups] = useState();
	const [deletingRow, setDeletingRow] = useState();
	const [newDocuments, setNewDocuments] = useState({
		id: '',
		name: '',
		type: '',
		shopping_group_id: '',
		data_json: '',
	});
	const [errors, setErrors] = useState({
		name: '',
		type: '',
		shopping_group_id: '',
	});
	const [leftRightMarginOption, setLeftRightMarginOption] =
		useState('normal');
	const [topBottomMarginOption, setTopBottomMarginOption] =
		useState('normal');

	const handleLeftRightMarginChange = (e) => {
		setLeftRightMarginOption(e.target.value);
	};

	const handleTopBottomMarginChange = (e) => {
		setTopBottomMarginOption(e.target.value);
	};

	const types = [
		{ value: 2, label: 'Umowa' },
		{ value: 1, label: 'Porozumienie' },
	];

	useEffect(() => {
		if (isEditMode) {
		  const editor = document.querySelector('.ql-editor');
		  
		  let topBottomStyle = '2.5cm';
		  let leftRightStyle = '2.5cm';
	
		  switch (topBottomMarginOption) {
			case 'normal':
			  topBottomStyle = '2.5cm';
			  break;
			case 'narrow':
			  topBottomStyle = '1.27cm';
			  break;
			case 'moderate':
			  topBottomStyle = '2.54cm';
			  break;
			default:
			  break;
		  }
	
		  switch (leftRightMarginOption) {
			case 'normal':
			  leftRightStyle = '2.5cm';
			  break;
			case 'narrow':
			  leftRightStyle = '1.27cm';
			  break;
			case 'moderate':
			  leftRightStyle = '1.91cm';
			  break;
			default:
			  break;
		  }
	
		  editor.style.paddingTop = topBottomStyle;
		  editor.style.paddingBottom = topBottomStyle;
		  editor.style.paddingLeft = leftRightStyle;
		  editor.style.paddingRight = leftRightStyle;
		}
	  }, [leftRightMarginOption, topBottomMarginOption, isEditMode]);

	const fetchData = async () => {
		const getDocuments = {
			action: 'get-documents',
		};
		const getUsers = {
			action: 'get-users',
		};
		const getShoppingGroup = {
			action: 'get-shopping-group',
		};

		try {
			const documentsResponse = await sendRequest(getDocuments);
			setTableData(documentsResponse.data._data_);

			const usersResponse = await sendRequest(getUsers);
			setUsers(usersResponse.data._data_);

			const groupsResponse = await sendRequest(getShoppingGroup);
			setGroups(
				groupsResponse.data._data_.map((group) => ({
					value: group.id,
					label: group.name,
				}))
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();

		if (quillRef.current) {
			const quill = quillRef.current.getEditor();
			setEditor(quill);

			if (initialContent) {
				quill.setContents(initialContent);
				setInitialContent(null);
			}
		}
	}, []);

	useEffect(() => {
		if (editor && initialContent) {
			editor.setContents(initialContent);
			setContent(editor.getText());
			setInitialContent(null);
		}
	}, [editor, initialContent]);

	useEffect(() => {
		if (quillRef.current) {
			const quill = quillRef.current.getEditor();
			setEditor(quill);
		}
	}, [quillRef]);

	const handleEditorChange = (content, delta, source, editor) => {
		setContent(content);
		setDeltaContent(editor.getContents());
	};

	const modules = {
		toolbar: [
			[{ font: [] }],
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			['bold', 'italic', 'underline', 'strike'],
			[{ color: [] }, { background: [] }],
			[{ script: 'sub' }, { script: 'super' }],
			[
				{ list: 'ordered' },
				{ list: 'bullet' },
				{ indent: '-1' },
				{ indent: '+1' },
			],
			['direction', { align: [] }],
			['link', 'image', 'video', 'formula'],
			['table'],
			['clean'],
		],
		table: true,
	};

	const clearForm = () => {
		setNewDocuments({
			id: '',
			name: '',
			shopping_group_id: '',
			data_json: '',
		});
		setContent({
			ops: [
				{
					insert: '\n',
				},
			],
		});
	};

	const closeModal = () => {
		if (isEditMode) {
			clearForm();
			setIsEditMode(false);
		}
		setIsModalOpen(false);
		setIsModalDeleteOpen(false);
		setDeletingRow();
	};

	const handleEditClick = (row) => {
		const delta = JSON.parse(row.data_json);
		
		let newLeftRightMargin = 'normal';
		let newTopBottomMargin = 'normal';
	  
		if (delta) {
		  if (delta.leftRightMarginOption && delta.topBottomMarginOption) {
			newLeftRightMargin = delta.leftRightMarginOption;
			newTopBottomMargin = delta.topBottomMarginOption;
		  } else if (delta.marginOption) {
			switch (delta.marginOption) {
			  case 'normal':
				newLeftRightMargin = 'normal';
				newTopBottomMargin = 'normal';
				break;
			  case 'narrow':
				newLeftRightMargin = 'narrow';
				newTopBottomMargin = 'narrow';
				break;
			  case 'moderate':
				newLeftRightMargin = 'moderate';
				newTopBottomMargin = 'moderate';
				break;
			}
		  }
		  
		  else if (delta.leftRightMarginOption) {
			newLeftRightMargin = delta.leftRightMarginOption;
			newTopBottomMargin = 'normal';
		  }
	  
		  if (delta.ops) {
			setContent(delta.ops);
		  }
		}
	  
		setLeftRightMarginOption(newLeftRightMargin);
		setTopBottomMarginOption(newTopBottomMargin);
	  
		setNewDocuments({
		  id: row.id,
		  name: row.name,
		  shopping_group_id: groups.find(
			(group) => group.value === row.shopping_group_id
		  ),
		  type: types.find((type) => type.value == row.type),
		  data_json: row.data_json,
		});
	  
		setIsEditMode(true);
	  };
	  

	const handleEditDocument = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const updatedDeltaContent = {
				...deltaContent,
				leftRightMarginOption: leftRightMarginOption,
				topBottomMarginOption: topBottomMarginOption,
			};

			const documentData = {
				id: newDocuments.id,
				name: newDocuments.name,
				shopping_group_id: newDocuments.shopping_group_id.value,
				data_json: JSON.stringify(updatedDeltaContent),
				type: newDocuments.type.value,
			};

			const addDocument = {
				action: 'edit-document',
				_data_: documentData,
			};

			try {
				await sendRequest(addDocument);
				setIsConfirmationModalOpen(false);
				setIsEditMode(false);
				closeModal();
				clearForm();
				fetchData();
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const handleDeleteDocument = async () => {
		const deleteDocument = {
			action: 'delete-document',
			id: deletingRow.id,
		};
		try {
			await sendRequest(deleteDocument);
			fetchData();
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddDocument = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const documentData = {
				name: newDocuments.name,
				shopping_group_id: newDocuments.shopping_group_id.value,
				type: newDocuments.type.value,
			};

			const addDocument = {
				action: 'add-document',
				_data_: documentData,
			};

			try {
				await sendRequest(addDocument);
				closeModal();
				clearForm();
				fetchData();
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const validateForm = () => {
		let isValid = true;
		let newErrors = {
			name: '',
		};

		for (const key in newDocuments) {
			if (key === 'id') continue;
			if (key === 'data_json') continue;

			if (newDocuments[key] === '' || newDocuments[key].length === 0) {
				newErrors[key] = 'To pole jest wymagane';
				isValid = false;
			}
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewDocuments((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSwitchChange = async (id, currentState) => {
		setIsLoadingRequest(true);
		const enableDocument = {
			action: 'enable-document',
			_data_: { id: id, active: currentState === 1 ? 0 : 1 },
		};

		try {
			await sendRequest(enableDocument);
		} catch (error) {
			console.error(error);
		} finally {
			fetchData();
			setIsLoadingRequest(false);
		}
	};

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '15px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							handleEditClick(row);
						}}
					/>
					<Icozamknij
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							setDeletingRow(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
		{
			name: 'Nazwa',
			selector: (row) => row.name,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Przeznaczenie dokumentu',
			selector: (row) => {
				if (row.type === 1) {
					return 'Porozumienie';
				} else if (row.type === 2) {
					return 'Umowa';
				}
			},
			grow: 2,
			sortable: true,
			sortField: '0',
		},

		{
			name: 'Grupa Zakupowa',
			selector: (row) => row.shopping_group_name,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Data Utworzenia',
			selector: (row) => row.ctime,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Data Modyfikacji',
			selector: (row) => row.mtime,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nazwa użytkownika',
			selector: (row) => row.description,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Aktywny',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}>
					<Switch
						style={{ transform: 'scale(0.8)' }}
						checked={row.active === 1}
						onChange={() => handleSwitchChange(row.id, row.active)}
					/>
				</div>
			),
			grow: 1,
			sortable: 'true',
		},
	];
	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	if (!isAccessVerified) {
		return null;
	}

	if (isEditMode)
		return (
			<EditorLayout>
				<div className="toolbar">
					<Select
						options={groups}
						classNamePrefix="react-select"
						placeholder="Wybierz grupę..."
						styles={customStyles}
						value={newDocuments.shopping_group_id}
						onChange={(selectedOptions) => {
							setNewDocuments((prevState) => ({
								...prevState,
								shopping_group_id: selectedOptions,
							}));
						}}
					/>
					<Select
						options={types}
						classNamePrefix="react-select"
						placeholder="Wybierz przeznaczenie dokumentu..."
						styles={customStyles}
						value={newDocuments.type}
						onChange={(selectedOptions) => {
							setNewDocuments((prevState) => ({
								...prevState,
								type: selectedOptions,
							}));
						}}
					/>
					<input
						name="name"
						type="text"
						value={newDocuments.name}
						onChange={handleInputChange}
						placeholder="Nazwa dokumentu"
					/>
					<Button onClick={() => setIsConfirmationModalOpen(true)}>
						Zapisz
					</Button>
					<Button
						onClick={() => {
							setIsEditMode(false);
							clearForm();
						}}>
						Powrót
					</Button>
					<div className="margin-select">
						<label htmlFor="leftRightMarginOption">Marginesy Boczne:</label>
						<select
							id="leftRightMarginOption"
							onChange={handleLeftRightMarginChange}
							value={leftRightMarginOption}>
							<option value="normal">Normalne</option>
							<option value="narrow">Wąskie</option>
							<option value="moderate">Umiarkowane</option>
						</select>
					</div>
					<div className="margin-select-top-bottom">
						<label htmlFor="topBottomMarginOption">Margines Góra:</label>
						<select
							id="topBottomMarginOption"
							onChange={handleTopBottomMarginChange}
							value={topBottomMarginOption}>
							<option value="normal">Normalne</option>
							<option value="narrow">Wąskie</option>
							<option value="moderate">Umiarkowane</option>
						</select>
					</div>
				</div>
				<ReactQuill
					ref={quillRef}
					value={content}
					modules={modules}
					onChange={handleEditorChange}
				/>
				{isConfirmationModalOpen && (
					<div
						onMouseDown={() => setIsConfirmationModalOpen(false)}
						className="modal-overlay">
						<div
							onMouseDown={(e) => {
								e.stopPropagation();
							}}
							className="modal-content"
							ref={modalContentRef}>
							<h2>Czy na pewno chcesz nadpisać dokument:</h2>
							<h2>{newDocuments.name} ?</h2>
							<div className="modal-actions">
								<Button
									className="cancel"
									onClick={() => {
										setIsConfirmationModalOpen(false);
									}}>
									Anuluj
								</Button>
								<Button
									className="add"
									onClick={() => handleEditDocument()}>
									Zapisz
								</Button>
							</div>
						</div>
					</div>
				)}
				{isLoadingRequest && (
					<div className="modal-overlay">
						<Loading />
					</div>
				)}
			</EditorLayout>
		);
	return (
		<AdminDocumentsLayout>
			<h2 className="page-title">Dokumenty</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={tableData}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<Button
					className="add-button"
					onClick={() => setIsModalOpen(true)}
					style={{
						fontSize: '14px',
					}}>
					Dodaj dokument
				</Button>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Dodaj Dokument</h2>
						<div>
							<label>Nazwa dokumentu:</label>
							<input
								name="name"
								placeholder="Nazwa dokumentu"
								value={newDocuments.name}
								className="input-form"
								onChange={handleInputChange}
							/>
							<p className="err-msg">{errors.name}</p>
						</div>
						<div>
							<label>Grupa:</label>
							<Select
								options={groups}
								classNamePrefix="react-select"
								placeholder="Wybierz grupę..."
								styles={customStyles}
								value={newDocuments.shopping_group_id}
								onChange={(selectedOptions) => {
									setNewDocuments((prevState) => ({
										...prevState,
										shopping_group_id: selectedOptions,
									}));
								}}
							/>
							<p className="err-msg">
								{errors.shopping_group_id}
							</p>
						</div>
						<div>
							<label>Przeznaczenie dokumentu:</label>
							<Select
								options={types}
								classNamePrefix="react-select"
								placeholder="Wybierz przeznaczenie dokumentu..."
								styles={customStyles}
								value={newDocuments.type}
								onChange={(selectedOptions) => {
									setNewDocuments((prevState) => ({
										...prevState,
										type: selectedOptions,
									}));
								}}
							/>
							<p className="err-msg">{errors.type}</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button className="add" onClick={handleAddDocument}>
								Dodaj
							</Button>
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć dokument:</h2>
						<h2>{deletingRow.name} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeleteDocument()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</AdminDocumentsLayout>
	);
};

export default AdminDocuments;
