import React, { useState, useRef, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { UsersLayout } from './Users.style';
import { Button } from '../../components/Button/Button';
import Select from 'react-select';
import useAccessControl from '../../hooks/useAccessControl';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import { sendRequest } from '../../api/sendRequest';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';
import LoadingComponent from '../../components/Table/LoadingComponent';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import { useTheme } from 'styled-components';

const Users = () => {
	const customStyles = useStyledSelect();
	const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
	const modalContentRef = useRef(null);
	const [newUser, setNewUser] = useState({
		userId: '',
		login: '',
		password1: '',
		password2: '',
		mail: '',
		gmina_id: '',
		group: null,
		description: '',
		isActive: false,
		pdf: false,
		isEmailReports: false,
	});
	const [userId, setUserId] = useState(null);
	const [errors, setErrors] = useState({
		loginError: '',
		password1Error: '',
		password2Error: '',
		mailError: '',
		selectedGminyError: '',
		selectedGroupError: '',
		selectedRolesError: '',
	});
	const [searchValue, setSearchValue] = useState('');
	const [userData, setUserData] = useState([]);
	const [userGroups, setUserGroups] = useState([]);
	const [userGminy, setUserGminy] = useState([]);
	const [deletingUser, setDeletingUser] = useState('');
	const [deletingUserId, setDeletingUserId] = useState(null);
	const [confirmedDeletingUser, setConfirmedDeletingUser] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [msg, setMsg] = useState('');
	const isVerified = useAccessControl();
	const token = localStorage.getItem('token');
	const theme = useTheme();

	const fetchData = async () => {
		if (isVerified.isAdmin) {
			try {
				const getUsersRequest = { action: 'get-users' };
				const getGroupsRequest = { action: 'get-groups' };
				const getGminyRequest = { action: 'get-gminy-all' };

				const [
					usersResponse,
					groupsResponse,
					gminyResponse,
				] = await Promise.all([
					sendRequest(getUsersRequest),
					sendRequest(getGroupsRequest),
					sendRequest(getGminyRequest),
				]);
				setUserData(usersResponse.data._data_);
				setUserGroups(
					groupsResponse.data._data_.map((group) => ({
						value: group.id,
						label: group.name,
					}))
				);
				setUserGminy(
					gminyResponse.data._data_.map((gmina) => ({
						value: gmina.id,
						label: gmina.nazwa,
					}))
				);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		} else {
			if (token) {
				try {
					const payload = token.split('.')[1];
					const decodedPayload = JSON.parse(atob(payload));
					setUserId(decodedPayload.uId);
					const getUserRequest = {
						action: 'get-user',
						id: decodedPayload.uId,
					};
					const response = await sendRequest(getUserRequest);
					setUserData([response.data._data_]);
				} catch (error) {
					console.error(error);
				} finally {
					setIsLoading(false);
				}
			}
		}
	};

	useEffect(() => {
		fetchData();
	}, [isVerified.isAdmin]);

	useEffect(() => {
		if (confirmedDeletingUser && deletingUserId) {
			const deleteUserRequest = {
				action: 'delete-user',
				id: deletingUserId,
				confirmed: confirmedDeletingUser,
			};

			sendRequest(deleteUserRequest)
				.then((response) => {
					if (response.data.status === 1) {
						setMsg('Pomyślnie usunięto!');
						const getUsersRequest = {
							action: 'get-users',
						};

						sendRequest(getUsersRequest)
							.then((usersResponse) => {
								setUserData(usersResponse.data._data_);
							})
							.catch((error) => {
								console.error(error);
							});
					} else {
						setMsg('Błąd usuwania!');
						console.error('Error deleting user:', response.data);
					}
				})
				.catch((error) => {
					console.error(error);
				});

			setConfirmedDeletingUser(false);
			setDeletingUserId(null);
			setIsDeleteUserModalOpen(false);
		}
	}, [confirmedDeletingUser, deletingUserId]);

	const validateUserData = () => {
		let isValid = true;
		let newErrors = { ...errors };
	
		if (!newUser.login) {
			newErrors.loginError = 'Login jest wymagany';
			isValid = false;
		} else {
			newErrors.loginError = '';
		}
	
		if (!editMode) {
			if (!newUser.password1) {
				newErrors.password1Error = 'Hasło jest wymagane';
				isValid = false;
			} else {
				newErrors.password1Error = '';
			}
		}
	
		if (newUser.password1 || newUser.password2) {
			if (newUser.password1 !== newUser.password2) {
				newErrors.password2Error = 'Hasła muszą być takie same';
				isValid = false;
			} else {
				newErrors.password2Error = '';
			}
		}
	
		const validEmailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!newUser.mail || !validEmailRegex.test(newUser.mail)) {
			newErrors.mailError = 'Podaj prawidłowy adres e-mail';
			isValid = false;
		} else {
			newErrors.mailError = '';
		}
	
		if (newUser.group && newUser.group.value !== 1) {
			if (!newUser.gmina_id) {
				newErrors.selectedGminyError = 'Wybierz przynajmniej jedną gminę';
				isValid = false;
			} else {
				newErrors.selectedGminyError = '';
			}
		} else {
			newErrors.selectedGminyError = '';
		}
	
		if (!newUser.group) {
			newErrors.selectedGroupError = 'Wybór grupy jest wymagany';
			isValid = false;
		} else {
			newErrors.selectedGroupError = '';
		}
	
		setErrors(newErrors);
		return isValid;
	};	

	const clearForm = () => {
		setNewUser({
			userId: '',
			login: '',
			password1: '',
			password2: '',
			mail: '',
			gminy: null,
			group: null,
			probes: [],
			description: '',
			roles: [],
			isActive: false,
			pdf: false,
			isEmailReports: false,
		});
	};

	const clearErrors = () => {
		setErrors({
			loginError: '',
			password1Error: '',
			password2Error: '',
			mailError: '',
			selectedGroupError: '',
		});
	};

	const handleAddUser = () => {
		const isValid = validateUserData();

		if (isValid) {
			const userData = {
				active: newUser.isActive ? 1 : 0,
				pdf: newUser.pdf ? 1 : 0,
				description: newUser.description,
				email: newUser.mail,
				email_reports: newUser.isEmailReports ? 1 : 0,
				group_id: newUser.group ? newUser.group.value : null,
				password: newUser.password2,
				gmina_id: newUser.gmina_id ? newUser.gmina_id.value : null,
				username: newUser.login,
			};

			const addUserRequest = {
				action: 'add-user',
				_data_: userData,
			};
			sendRequest(addUserRequest).then((response) => {
				if (response.data.status === 1) {
					closeModal();
					clearForm();

					const getUsersRequest = {
						action: 'get-users',
					};

					sendRequest(getUsersRequest)
						.then((usersResponse) => {
							setUserData(usersResponse.data._data_);
							setMsg('Zapisano');
						})
						.catch((error) => {
							console.error(error);
							setMsg('Błąd zapisu!');
						});
				} else {
					setErrors((prevState) => ({
						...prevState,
						loginError: response.data._data_.username,
					}));
				}
			});
		}
	};

	const handleOpenModalEdit = (row) => {
		setMsg('');
		const getUserRequest = {
			action: 'get-user',
			id: row.id,
		};

		sendRequest(getUserRequest)
			.then((response) => {
				const userData = response.data._data_;

				setEditMode(true);
				setIsAddUserModalOpen(true);

				setNewUser({
					userId: userData.id,
					login: userData.username,
					password1: '',
					password2: '',
					mail: userData.email,
					group: userGroups.find(
						(group) => group.value === userData.group_id
					),
					gmina_id: userGminy.find(
						(gmina) => gmina.value === userData.gmina_id
					),
					description: userData.description,
					isActive: userData.active === 1,
					pdf: userData.pdf === 1,
					isEmailReports: userData.email_reports === 1,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleInputChange = (field, value) => {
		setNewUser((prevState) => ({
			...prevState,
			[field]: value,
		}));
	};

	const handleOpenModalEditSingleUser = () => {
		setMsg('');
		setEditMode(true);
		setIsAddUserModalOpen(true);
		setNewUser((prevState) => ({
			...prevState,
			description: userData[0].description,
			login: userData[0].username,
			mail: userData[0].email,
		}));
	};

	const handleEditSingleUser = () => {
		let isValid = true;
		let newErrors = { ...errors };

		if (newUser.password1 !== newUser.password2) {
			newErrors.password2Error = 'Hasła muszą być takie same';
			isValid = false;
		} else {
			newErrors.password2Error = '';
		}

		const validEmailRegex =
			/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!newUser.mail || !validEmailRegex.test(newUser.mail)) {
			newErrors.mailError = 'Podaj prawidłowy adres e-mail';
			isValid = false;
		} else {
			newErrors.mailError = '';
		}

		setErrors(newErrors);

		if (isValid) {
			const editUserRequest = {
				action: 'edit-user',
				_data_: {
					email: newUser.mail,
					id: userId,
					password: newUser.password1,
				},
			};

			sendRequest(editUserRequest).then((response) => {
				if (response.data.status === 1) {
					closeModal();
					clearErrors();
					clearForm();
					setMsg('Zapisano');

					const getUserRequest = {
						action: 'get-user',
						id: userId,
					};

					sendRequest(getUserRequest)
						.then((usersResponse) => {
							setUserData([usersResponse.data._data_]);
						})
						.catch((error) => {
							console.error(error);
						});
				} else {
					setErrors((prevState) => ({
						...prevState,
						loginError: response.data._data_.username,
					}));
					setMsg('Błąd edycji!');
				}
			});
		}
	};

	const closeModal = () => {
		clearErrors();
		if (editMode) {
			clearForm();
		}
		setIsAddUserModalOpen(false);
		setIsDeleteUserModalOpen(false);
		setEditMode(false);
	};

	const openModal = () => {
		setMsg('');
		setEditMode(false);
		setIsAddUserModalOpen(true);
	};

	const handleEditUser = () => {
		const isValid = validateUserData();

		if (isValid) {
			const editUserRequest = {
				action: 'edit-user',
				_data_: {
					id: newUser.userId,
					active: newUser.isActive ? 1 : 0,
					pdf: newUser.pdf ? 1 : 0,
					description: newUser.description,
					email: newUser.mail,
					email_reports: newUser.isEmailReports ? 1 : 0,
					group_id: newUser.group ? newUser.group.value : null,
					gmina_id: newUser.gmina_id ? newUser.gmina_id.value : null,
					password: newUser.password2,
					username: newUser.login,
				},
			};

			sendRequest(editUserRequest).then((response) => {
				if (response.data.status === 1) {
					closeModal();
					clearForm();
					clearErrors();

					setMsg('Zapisano');

					const getUsersRequest = { action: 'get-users' };
					sendRequest(getUsersRequest)
						.then((usersResponse) => {
							setUserData(usersResponse.data._data_);
						})
						.catch((error) => {
							console.error(error);
							setMsg(
								'Błąd podczas aktualizacji listy użytkowników'
							);
						});
				} else {
					setErrors((prevState) => ({
						...prevState,
						loginError: response.data._data_.username,
					}));
					setMsg('Błąd zapisu!');
				}
			});
		}
	};

	const handleDeleteUser = (row) => {
		setIsDeleteUserModalOpen(true);
		setDeletingUserId(row.id);
		setDeletingUser(row.description);
	};

	const handleSwitchChange = async (userId) => {
		const user = userData.find((u) => u.id === userId);

		if (user) {
			const updatedValue = user.email_reports === 1 ? 0 : 1;
			const updatedUser = { ...user, email_reports: updatedValue };
			const updatedUserData = userData.map((u) =>
				u.id === userId ? updatedUser : u
			);

			setUserData(updatedUserData);

			const editUserRequest = {
				action: 'edit-user',
				_data_: {
					email_reports: updatedValue,
					id: userId,
				},
			};
			try {
				await sendRequest(editUserRequest);
				fetchData();
				setMsg('Zapisano zmianę');
			} catch (error) {
				console.error(error);
				setMsg('Błąd zapisu!');
			}
		}
	};

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '5px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => handleOpenModalEdit(row)}
					/>
					<Icozamknij
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => handleDeleteUser(row)}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
		{
			name: 'Opis',
			selector: (row) => row.description,
			grow: 3,
			sortable: 'true',
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			grow: 3,
			sortable: 'true',
		},
		{
			name: 'Powiadomienia Email',
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}>
					<Switch
						style={{ transform: 'scale(0.8)' }}
						checked={row.email_reports === 1}
						onChange={() => handleSwitchChange(row.id)}
					/>
				</div>
			),
			grow: 3,
			sortable: 'true',
		},
		{
			name: 'Aktywny',
			selector: (row) => (row.active === 1 ? 'Tak' : 'Nie'),
			grow: 2,
			sortable: 'true',
			conditionalCellStyles: [
				{
					when: (row) => row.active === 1,
					style: {
						backgroundColor: theme.success,
						color: 'white',
						justifyContent: 'center',
					},
				},
				{
					when: (row) => row.active === 0,
					style: {
						backgroundColor: theme.blocked,
						color: 'white',
						justifyContent: 'center',
					},
				},
			],
		},
		{
			name: 'Ostatnie logowanie',
			selector: (row) => row.last_login,
			grow: 3,
			sortable: 'true',
		},
		{
			name: 'Uczestnik grupy zakupowej',
			selector: (row) =>
				typeof row.ugz_name === 'string'
					? row.ugz_name.split(',').join(', ')
					: row.ugz_name,
			grow: 3,
			sortable: 'true',
		},
		{
			name: 'Grupa',
			selector: (row) => row.group_name,
			grow: 4,
			sortable: 'true',
		},
		{
			name: 'Analiza PDF',
			selector: (row) => (row.pdf === 1 ? 'Tak' : 'Nie'),
			grow: 2,
			sortable: 'true',
			conditionalCellStyles: [
				{
					when: (row) => row.pdf === 1,
					style: {
						backgroundColor: theme.success,
						color: 'white',
						justifyContent: 'center',
					},
				},
				{
					when: (row) => row.pdf === 0,
					style: {
						backgroundColor: theme.blocked,
						color: 'white',
						justifyContent: 'center',
					},
				},
			],
		},
	];

	const singleUserColumns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '5px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => handleOpenModalEditSingleUser()}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
		{
			name: 'Opis',
			selector: (row) => row.description,
			grow: 3,
			sortable: 'true',
		},
		{
			name: 'Aktywny',
			selector: (row) => (row.active === 1 ? 'Tak' : 'Nie'),
			grow: 2,
			sortable: 'true',
			conditionalCellStyles: [
				{
					when: (row) => row.active === 1,
					style: {
						backgroundColor: theme.success,
						color: 'white',
						justifyContent: 'center',
					},
				},
				{
					when: (row) => row.active === 0,
					style: {
						backgroundColor: theme.blocked,
						color: 'white',
						justifyContent: 'center',
					},
				},
			],
		},
		{
			name: 'Uczestnik grupy zakupowej',
			selector: (row) =>
				typeof row.ugz_name === 'string'
					? row.ugz_name.split(',').join(', ')
					: row.ugz_name,
			grow: 3,
			sortable: 'true',
		},
		{
			name: 'Grupa',
			selector: (row) => row.group_name,
			grow: 4,
			sortable: 'true',
		},
		{
			name: 'Analiza PDF',
			selector: (row) => (row.pdf === 1 ? 'Tak' : 'Nie'),
			grow: 2,
			sortable: 'true',
			conditionalCellStyles: [
				{
					when: (row) => row.pdf === 1,
					style: {
						backgroundColor: theme.success,
						color: 'white',
						justifyContent: 'center',
					},
				},
				{
					when: (row) => row.pdf === 0,
					style: {
						backgroundColor: theme.blocked,
						color: 'white',
						justifyContent: 'center',
					},
				},
			],
		},
	];

	if (!isVerified.isVerified) {
		return null;
	}

	return (
		<UsersLayout>
			<h2 className="page-title">Użytkownicy</h2>
			<span style={{ marginLeft: '10px' }}>{msg}</span>
			<div className="table-container">
				<StyledDataTable
					columns={isVerified.isAdmin ? columns : singleUserColumns}
					data={userData.filter((user) =>
						Object.values(user).some((value) =>
							String(value)
								.toLowerCase()
								.includes(searchValue.toLowerCase())
						)
					)}
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
					noDataComponent="Brak danych spełniających kryteria"
					progressPending={isLoading}
					progressComponent={<LoadingComponent />}
				/>
				{isVerified.isAdmin && (
					<div className="table-menu">
						<Button
							className="add-user"
							onClick={openModal}
							style={{
								fontSize: '14px',
							}}>
							Dodaj użytkownika
						</Button>
						<input
							placeholder="Szukaj..."
							className="search-input"
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
					</div>
				)}
			</div>
			{isAddUserModalOpen && isVerified.isAdmin && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{editMode ? (
							<h2>Edytuj Użytkownika: {newUser.description}</h2>
						) : (
							<h2>Dodaj Użytkownika</h2>
						)}
						<div className="form">
							<div className="column">
								{editMode ? (
									<div>
										<label htmlFor="login">Login:</label>
										<input
											id="login"
											type="text"
											placeholder="Nazwa użytkownika"
											value={newUser.login}
											disabled
										/>
										<p className="err-msg"></p>
									</div>
								) : (
									<div>
										<label htmlFor="login">Login:</label>
										<input
											id="login"
											type="text"
											placeholder="Nazwa użytkownika"
											value={newUser.login}
											onChange={(e) =>
												handleInputChange(
													'login',
													e.target.value
												)
											}
										/>
										<p className="err-msg">
											{errors.loginError}
										</p>
									</div>
								)}
								<div>
									<label htmlFor="password1">Hasło:</label>
									<input
										id="password1"
										type="text"
										placeholder="Hasło"
										value={newUser.password1}
										onChange={(e) =>
											handleInputChange(
												'password1',
												e.target.value
											)
										}
									/>
									<p className="err-msg">
										{errors.password1Error}
									</p>
								</div>
								<div>
									<label htmlFor="password2">
										Powtórz hasło:
									</label>
									<input
										id="password2"
										type="text"
										placeholder="Powtórz hasło"
										value={newUser.password2}
										onChange={(e) =>
											handleInputChange(
												'password2',
												e.target.value
											)
										}
									/>
									<p className="err-msg">
										{errors.password2Error}
									</p>
								</div>
								<div>
									<label style={{ marginRight: '10px' }}>
										Aktywny:
									</label>
									<Switch
										checked={newUser.isActive}
										onChange={() =>
											handleInputChange(
												'isActive',
												!newUser.isActive
											)
										}
									/>
								</div>
							</div>
							<div className="column">
							<div>
									<label htmlFor="mail">E-mail:</label>
									<input
										id="mail"
										type="text"
										placeholder="E-mail"
										value={newUser.mail}
										onChange={(e) =>
											handleInputChange(
												'mail',
												e.target.value
											)
										}
									/>
									<p className="err-msg">
										{errors.mailError}
									</p>
								</div>
								<div>
									<label htmlFor="description">Opis:</label>
									<input
										id="description"
										type="text"
										placeholder="Opis"
										value={newUser.description}
										onChange={(e) =>
											handleInputChange(
												'description',
												e.target.value
											)
										}
									/>
									<p className="err-msg"></p>
								</div>
								<div>
									<label>Grupa użytkowników:</label>
									<Select
										classNamePrefix="react-select"
										placeholder="Wybierz grupę..."
										options={userGroups}
										value={newUser.group}
										onChange={(selectedOption) =>
											handleInputChange(
												'group',
												selectedOption
											)
										}
										styles={customStyles}
									/>
									<p className="err-msg">
										{errors.selectedGroupError}
									</p>
								</div>
								{newUser.group && newUser.group.value !== 1 && (
									<div>
										<label>
											Uczestnik Grupy Zakupowej:
										</label>
										<Select
											classNamePrefix="react-select"
											placeholder="Wybierz uczestnika..."
											options={userGminy}
											value={newUser.gmina_id}
											onChange={(selectedOption) =>
												handleInputChange(
													'gmina_id',
													selectedOption
												)
											}
											styles={customStyles}
										/>
										<p className="err-msg">
											{errors.selectedGminyError}
										</p>
									</div>
								)}
								<div>
									<label style={{ marginRight: '10px' }}>
										Analiza PDF:
									</label>
									<Switch
										checked={newUser.pdf}
										onChange={() =>
											handleInputChange(
												'pdf',
												!newUser.pdf
											)
										}
									/>
								</div>
							</div>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{editMode ? (
								<Button
									className="add"
									onClick={handleEditUser}>
									Zapisz
								</Button>
							) : (
								<Button className="add" onClick={handleAddUser}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isAddUserModalOpen && !isVerified.isAdmin && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Edytuj</h2>
						<h2>{newUser.description}</h2>

						<div>
							<label htmlFor="password1">Hasło:</label>
							<input
								id="password1"
								type="text"
								placeholder="Hasło"
								value={newUser.password1}
								onChange={(e) =>
									handleInputChange(
										'password1',
										e.target.value
									)
								}
							/>
							<p className="err-msg">{errors.password1Error}</p>
						</div>
						<div>
							<label htmlFor="password2">Powtórz hasło:</label>
							<input
								id="password2"
								type="text"
								placeholder="Powtórz hasło"
								value={newUser.password2}
								onChange={(e) =>
									handleInputChange(
										'password2',
										e.target.value
									)
								}
							/>
							<p className="err-msg">{errors.password2Error}</p>
						</div>
						<div>
							<label htmlFor="mail">E-mail:</label>
							<input
								id="mail"
								type="text"
								placeholder="E-mail"
								value={newUser.mail}
								onChange={(e) =>
									handleInputChange('mail', e.target.value)
								}
							/>
							<p className="err-msg">{errors.mailError}</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={handleEditSingleUser}>
								Zapisz
							</Button>
						</div>
					</div>
				</div>
			)}
			{isDeleteUserModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć użytkownika</h2>
						<h2>{deletingUser} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => setConfirmedDeletingUser(true)}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
		</UsersLayout>
	);
};

export default Users;
