import styled from 'styled-components';

export const DocumentLayout = styled.div`
	.ql-container {
		border: none;

        .left-right-normal{
			padding-left: 2.5cm;
			padding-right: 2.5cm;
        }
        .left-right-narrow{
			padding-left: 1.27cm;
			padding-right: 1.27cm;
        }
        .left-right-moderate{
			padding-left: 1.91cm;
			padding-right: 1.91cm;
        }
        .top-bottom-normal{
			padding-top: 2.5cm;
			padding-bottom: 2.5cm;
        }
        .top-bottom-narrow{
			padding-top: 1.27cm;
			padding-bottom: 1.27cm;
        }
        .top-bottom-moderate{
			padding-top: 2.54cm;
			padding-bottom: 2.54cm;
        }

		.ql-editor {
			margin: 60px auto;
			width: 21cm;
			min-height: 29.7cm;
			background-color: white;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			line-height: 1.5;
			color: #000;

			input {
				margin: 5px 0;
				padding: 5px;
				border: none;
				border-bottom: dotted 1px black;
				width: 100px;
				min-height: 30px;
			}
		}
	}
`;
