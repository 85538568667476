import styled from 'styled-components';

export const DocumentsAnalyserLayout = styled.div`
	margin: 60px 0 50px 0;

	h2 {
		margin-bottom: 10px;
	}

	Button {
		margin-top: 10px;
	}

	.table-container {
		position: relative;
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;

		.legend{
			position: absolute;
			top: 95px;
			left: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5px;
			.square{
				height: 25px;
				width: 25px;
				border-radius: 2px;
				background-color: ${(props) => props.theme.danger};
			}
		}
		
		.rdt_Table{
			.rdt_TableHead{
				.rdt_TableHeadRow{
					div{
						font-size: 14px;
					}
				}
			}
			
			.rdt_TableBody{
				div{
					font-size: 14px;
				}
			}
		}

		.button {
			width: 272px;
			margin-top: 0;
			margin-bottom: 10px;
			margin-right: 10px;
		}

		.ppe-dif {
			text-align: center;
			position: absolute;
			top: 10px;
			right: 30px;

			div{
				display: flex;
				gap: 15px;

				div{
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 0;
				}
			}
		}
	}
`;
