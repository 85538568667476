import {
	Menu,
	MenuItem,
	sidebarClasses,
	SubMenu,
	menuClasses,
} from 'react-pro-sidebar';
import { StyledSidebar } from './Nav.styles';
import { ReactComponent as Icoarrow } from './../../svg/ico-w-lewo.svg';
import { sendRequest } from '../../api/sendRequest';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import logo from './../../img/os_ul_kal-trans-hd.png';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFolderOpen,
	faUser,
	faUsers,
	faArrowsToDot,
	faHome,
	faUpload,
} from '@fortawesome/free-solid-svg-icons';

const Nav = ({
	isExpanded,
	setIsExpanded,
	toggled,
	setToggled,
	setIsModalOpen,
	finishedProcedure,
	isGroupId1Enabled,
	isGroupId2Enabled,
	isAdm
}) => {
	const [broken, setBroken] = useState(false);
	const [sidebarWidth, setSidebarWidth] = useState('250px');
	const [fontSize, setFontSize] = useState('14px');
	const navigate = useNavigate();
	const theme = useTheme();
	const location = useLocation();
	const userRoutes = JSON.parse(localStorage.getItem('routes')) || [];
	const documentsComplexVisible =
		userRoutes.find((route) => route.menu_key === 'documents-complex')
			?.visible === 1;
	const documentsDivVisible =
		userRoutes.find((route) => route.menu_key === 'documents-div')
			?.visible === 1;

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setSidebarWidth('280px');
				setFontSize('14px');
			} else if (window.innerWidth < 900) {
				setSidebarWidth('180px');
				setFontSize('10px');
			} else if (window.innerWidth < 1200) {
				setSidebarWidth('230px');
				setFontSize('12px');
			} else {
				setSidebarWidth('280px');
				setFontSize('14px');
			}
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const menuItems = [
		{
			id: 'nabywcy',
			path: '/nabywcy',
			label: 'Nabywcy',
			icon: <FontAwesomeIcon icon={faUsers} size="xl" />,
		},
		{
			id: 'odbiorcy',
			path: '/odbiorcy',
			label: 'Odbiorcy',
			icon: <FontAwesomeIcon icon={faUsers} size="xl" />,
		},
		{
			id: 'ppe',
			path: '/ppe',
			label: 'Punkty Poboru Energii',
			icon: <FontAwesomeIcon icon={faArrowsToDot} size="xl" />,
		},
		{
			id: 'pdf',
			path: '/pdf',
			label: 'Zweryfikuj Stawkę Zakupu Energii',
			icon: <FontAwesomeIcon icon={faUpload} size="xl" />,
		},
	];

	const visibleMainItems = menuItems.filter((item) => {
		const userRight = userRoutes.find(
			(right) => right.menu_key === item.id
		);
		return userRight && userRight.visible === 1;
	});

	const pdfItems = visibleMainItems.filter((item) => item.id === 'pdf');
	const otherItems = visibleMainItems.filter((item) => item.id !== 'pdf');
	const order = ['ppe', 'odbiorcy', 'nabywcy'];
	const sortedItems = order.map((id) =>
		otherItems.find((item) => item.id === id)
	);

	return (
		<StyledSidebar
			width={sidebarWidth}
			breakPoint="md"
			collapsed={!isExpanded}
			onBackdropClick={() => setToggled(false)}
			onBreakPoint={setBroken}
			toggled={toggled}
			rootStyles={{
				position: 'fixed',
				height: '100vh',
				top: '50%',
				transform: 'translate(0, -50%)',
				zIndex: '11',
				borderColor: theme.white,
				[`.${sidebarClasses.container}`]: {
					backgroundColor: theme.white,
				},
			}}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '10px',
					color: theme.primary,
					padding: '10px',
					marginBottom: '20px',
				}}>
				<div>
					<img
						src={logo}
						alt="Logo"
						className={isExpanded ? 'logo' : 'small-logo'}
					/>
				</div>
			</div>
			<Menu
				style={{ fontSize: fontSize }}
				menuItemStyles={{
					button: ({ level }) => {
						if (level === 0) {
							return {
								paddingLeft: '9px',
								paddingRight: '15px',
								height: '55px',
								'&:hover': {
									backgroundColor: `${theme.hoverwhite} !important`,
								},
							};
						} else if (level === 1) {
							return {
								height: '35px',
								'&:hover': {
									backgroundColor: `${theme.hoverwhite} !important`,
								},
							};
						}
					},
					label: ({ open }) => ({
						fontWeight: open ? 600 : undefined,
					}),
					icon: {
						marginRight: isExpanded ? '10px' : '20px',
					},
				}}>
				<MenuItem
					component={<Link to={'/dashboard'} />}
					active={location.pathname === '/dashboard'}
					icon={<FontAwesomeIcon icon={faHome} size="xl" />}>
					Panel Główny
				</MenuItem>
				{sortedItems.map((item) => (
					<MenuItem
						key={item.id}
						id={item.id}
						active={location.pathname === item.path}
						onClick={() => {
							if (broken) {
								setToggled(false);
							}
							navigate(item.path);
						}}
						icon={item.icon}>
						{item.label}
					</MenuItem>
				))}
				{documentsComplexVisible && isGroupId1Enabled && (
					<SubMenu
						icon={<FontAwesomeIcon icon={faFolderOpen} size="xl" />}
						label="Grupa zakupowa kompleksowa">
						<MenuItem
							component={<Link to={'/documents?groupType=1&type=1'} />}
							active={
								location.pathname + location.search ===
								'/documents?groupType=1&type=1'
							}>
							Dokumenty - porozumienie
						</MenuItem>
						{finishedProcedure && isAdm && (
							<MenuItem
								component={
									<Link
										to={
											'/documents?groupType=1&type=2'
										}
									/>
								}
								active={
									location.pathname + location.search ===
									'/documents?groupType=1&type=2'
								}>
								Dokumenty - umowa
							</MenuItem>
						)}
					</SubMenu>
				)}
				{documentsDivVisible && isGroupId2Enabled && (
					<SubMenu
						icon={<FontAwesomeIcon icon={faFolderOpen} size="xl" />}
						label="Grupa zakupowa rozdzielona">
						<MenuItem
							component={<Link to={'/documents?groupType=2&type=1'} />}
							active={
								location.pathname + location.search ===
								'/documents?groupType=2&type=1'
							}>
							Dokumenty - porozumienie
						</MenuItem>
						{finishedProcedure && isAdm &&  (
							<MenuItem
								component={
									<Link
										to={
											'/documents?groupType=2&type=2'
										}
									/>
								}
								active={
									location.pathname + location.search ===
									'/documents?groupType=2&type=2'
								}>
								Dokumenty - umowa
							</MenuItem>
						)}
					</SubMenu>
				)}
				{pdfItems.map((item) => (
					<MenuItem
						key={item.id}
						id={item.id}
						active={location.pathname === item.path}
						onClick={() => {
							if (broken) {
								setToggled(false);
							}
							navigate(item.path);
						}}
						icon={item.icon}>
						{item.label}
					</MenuItem>
				))}
				<MenuItem
					className="settings-menu-item"
					onClick={() => {
						if (broken) {
							setToggled(false);
						}
					}}
					component={<Link to={'/admin-users-admin'} />}
					icon={<FontAwesomeIcon icon={faUser} />}>
					Użytkownicy
				</MenuItem>
				{isExpanded && (
					<>
						{finishedProcedure ? (
							<div className="button disabled">
								Proces zakończony
							</div>
						) : (
							<div
								className="button"
								onClick={() => setIsModalOpen(true)}>
								Zakończ proces
							</div>
						)}
					</>
				)}
			</Menu>
			<div>
				<div
					className="expand-icon"
					style={{
						transform: `translate(-50%, 0) rotate(${
							isExpanded ? '0' : '180deg'
						})`,
					}}>
					<Icoarrow
						style={{ cursor: 'pointer' }}
						onClick={() => setIsExpanded(!isExpanded)}
						width={50}
						fill={theme.primary}
					/>
				</div>
			</div>
		</StyledSidebar>
	);
};

export default Nav;
