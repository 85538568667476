import React from 'react'

const NoAuth = () => {
	return (
		<div
			style={{
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<h2>Niewystarczające uprawnienia</h2>
		</div>
	)
}

export default NoAuth
