import DataTable from "react-data-table-component";
import styled from "styled-components";

export const StyledDataTable = styled(DataTable)`
	overflow: auto;
	

	.rdt_Table {
		background-color: ${(props) => props.theme.white};
	}
	.rdt_TableCell {
		justify-content: center;
		color: ${(props) => props.theme.tablebodyfontcolor};
		background-color: ${(props) => props.theme.secondary};
		border: 1px solid ${(props) => props.theme.white};
		font-size: 11px;
		color: ${(props) => props.theme.fontcolor};
		padding: 2px 4px;
		min-width: 73px;
	}

	.rdt_TableRow {
		border: none;
		min-height: 1px;
		background-color: transparent;
	}

	.rdt_TableCol {
		background-color: ${(props) => props.theme.primary};
		border: 1px solid ${(props) => props.theme.white};
		color: #282828;
		font-size: 13px;
		font-weight: 700;
		justify-content: center;
		padding: 0 4px;
		min-width: 73px;
		
	}

	.rdt_TableHeadRow {
		border: none;
		background-color: ${(props) => props.theme.white};
		min-height: 38px;

		.rdt_TableCol:first-child {
			border-top-left-radius: 5px;
		}

		.rdt_TableCol:last-child {
			border-top-right-radius: 5px;
		}
	}

	.rdt_TableBody {
		background-color: ${(props) => props.theme.white};
		.rdt_TableRow:last-child {
			background-color: ${(props) => props.theme.white};
			.rdt_TableCell:first-child {
				border-bottom-left-radius: 5px;
			}

			.rdt_TableCell:last-child {
				border-bottom-right-radius: 5px;
			}
		}
	}

	.rdt_ExpanderRow{
		background-color: ${(props) => props.theme.white};
	}

	.gOTktU,.dEaAMx, .fYHoTn, .cYOyxy {
		background-color: ${(props) => props.theme.white} !important;
		color: ${(props) => props.theme.fontcolor};
	}
`;
