import styled from 'styled-components';

export const ViewDocumentLayout = styled.div`

    h2{
        position: absolute;
        top: 15px;
    }

	Button{
		position: absolute;
		z-index: 10;
	}

	.ql-container {
		border: none;

        .normal{
			padding: 2.5cm;
        }
        .narrow{
			padding: 1.27cm;
        }
        .moderate{
			padding: 2.54cm 1.91cm;
        }

		.ql-editor {
			margin: 60px auto;
			width: 21cm;
			min-height: 29.7cm;
			background: #fff;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			line-height: 1.5;
			color: #000;

			input {
				margin: 5px 0;
				padding: 5px;
				border: none;
				border-bottom: dotted 1px black;
				width: 100px;
				min-height: 30px;
			}
		}
	}
`;
