import { useState, useRef, useEffect } from 'react';
import { NavbarContainer } from './TopNavbar.styles';
import { ReactComponent as Icoustawienia } from './../../svg/ico-ustawienia.svg';
import { ReactComponent as Icowyloguj } from './../../svg/ico-wyloguj.svg';
import { ReactComponent as BurgerMenu } from './../../svg/ico-rozwin-menu.svg';
import { ReactComponent as SunIcon } from './../../svg/sun_icon.svg';
import { ReactComponent as MoonIcon } from './../../svg/moon_icon.svg';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import logo from './../../img/os_ul_kal-trans-hd.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faGear,
	faUsers,
	faUser,
	faLayerGroup,
	faGears,
	faFile,
	faCoins,
	faBoxArchive,
	faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';
import Select from 'react-select';
import { useStyledSelect } from '../Select/StyledSelect';
import { sendRequest } from '../../api/sendRequest';
import { uploadFile } from '../../api/uploadFile';
import Cookies from 'js-cookie';

const TopNavbar = ({ toggleTheme, toggled, setToggled }) => {
	const customStyles = useStyledSelect();
	const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);
	const theme = useTheme();
	const groupName = window.localStorage.getItem('group_name');
	const userDescription = window.localStorage.getItem('user_description');
	const [activeTheme, setActiveTheme] = useState(
		localStorage.getItem('selected-theme')
	);
	const [regions, setRegions] = useState([]);
	const routeLinks = JSON.parse(localStorage.getItem('routes') || '[]');
	const settingsLinks = routeLinks.filter((route) => route.visible === 1);
	const settingsDropdownRef = useRef(null);
	const settingsButtonRef = useRef(null);
	const [file, setFile] = useState(null);
	const [selectedOption, setSelectedOption] = useState({
		value: 'all',
		label: 'Wszczyscy uczestnicy',
	});
	const isAdminSettings = settingsLinks.some(
		(link) => link.menu_key === 'admin-settings'
	);

	const handleThemeClick = () => {
		toggleTheme();
		activeTheme === 'light'
			? setActiveTheme('dark')
			: setActiveTheme('light');
	};

	const handleLogout = () => {
		localStorage.removeItem('token');
		Cookies.remove('user_data');
		Cookies.remove('session_data');
		window.location.reload(true);
	};

	const decodeToken = () => {
		const token = localStorage.getItem('token');
		if (token) {
			const decoded = jwtDecode(token);
			return decoded.adm;
		}
		return 0;
	};

	const isAdmin = decodeToken() === 1;

	const toggleSettingsDropdown = () => {
		setIsSettingsDropdownOpen(!isSettingsDropdownOpen);
	};

	const handleDocumentClick = (e) => {
		if (
			settingsDropdownRef.current &&
			!settingsDropdownRef.current.contains(e.target) &&
			settingsButtonRef.current &&
			!settingsButtonRef.current.contains(e.target)
		) {
			setIsSettingsDropdownOpen(false);
		}
	};

	useEffect(() => {
		const regionsData = JSON.parse(
			localStorage.getItem('gmina_id') || '[]'
		);
		const regionsWithAllOption = [
			{ value: 'all', label: 'Wszczyscy uczestnicy' },
			...regionsData.map((region) => ({
				value: region.gmina_id,
				label: region.gmina_nazwa,
			})),
		];
		setRegions(regionsWithAllOption);

		const savedGminaId = sessionStorage.getItem('selectedGminaId');
		const savedGminaIdNum =
			savedGminaId === 'all' ? 'all' : Number(savedGminaId);

		const defaultOption = regionsWithAllOption.find(
			(option) => option.value === savedGminaIdNum
		) || { value: 'all', label: 'Wszczyscy uczestnicy' };
		setSelectedOption(defaultOption);

		if (defaultOption && isAdminSettings) {
			initRegionChange(defaultOption);
		}

		document.addEventListener('mousedown', handleDocumentClick);

		return () => {
			document.removeEventListener('mousedown', handleDocumentClick);
		};
	}, []);

	const initRegionChange = async (selectedOption) => {
		const setGmina = {
			action: 'set-gmina-admin',
			gmina_id:
				selectedOption.value === 'all' ? [] : [selectedOption.value],
		};
		try {
			await sendRequest(setGmina);
		} catch (error) {
			console.error(error);
		}
	};

	const handleRegionChange = async (selectedOption) => {
		setSelectedOption(selectedOption);
		sessionStorage.setItem('selectedGminaId', selectedOption.value);
		const setGmina = {
			action: 'set-gmina-admin',
			gmina_id:
				selectedOption.value === 'all' ? [] : [selectedOption.value],
		};
		try {
			const response = await sendRequest(setGmina);
			if (response.data && response.data.status === 1) {
				window.location.reload();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleFileChange = async (event) => {
		const selectedFile = event.target.files[0];
	
		if (selectedFile && selectedFile.type === 'application/pdf') {
			const formData = new FormData();
			formData.append('file', selectedFile);
			formData.append('action', 'upload-pdf-cennik');
	
			try {
				await uploadFile(formData);
				alert('Cennik został pomyślnie dodany.');
			} catch (error) {
				console.error(error);
				alert('Wystąpił błąd podczas dodawania cennika.');
			}
		} else {
			alert('Wybrany plik nie jest plikiem PDF');
			console.log('Wybrany plik nie jest plikiem PDF');
		}
	};
	

	settingsLinks.sort((a, b) => {
		if (a.menu_key === 'admin-settings') return 1;
		if (b.menu_key === 'admin-settings') return -1;
		if (a.menu_key === 'admin-regions') return 1;
		if (b.menu_key === 'admin-regions') return -1;
		return a.menu_key.localeCompare(b.menu_key);
	});

	return (
		<NavbarContainer>
			<div className="burger-menu" onClick={() => setToggled(!toggled)}>
				<BurgerMenu fill={theme.fontcolor} width={35} />
			</div>
			<img
				src={logo}
				alt="Logo"
				className="logo"
				style={{ height: 40 }}
			/>
			<div className="group-name">{groupName || 'Default Group'}</div>
			<div className="region-name">
				{isAdmin ? (
					<div style={{ minWidth: '200px', marginLeft: '10px' }}>
						<Select
							styles={customStyles}
							options={regions}
							onChange={handleRegionChange}
							placeholder={'Wybierz uczestnika'}
							value={selectedOption}
						/>
					</div>
				) : (
					<div className="region-name-user">
						<div>Uczestnik Grupy Zakupowej:</div>
						<div>{regions[1]?.label}</div>
					</div>
				)}
			</div>
			<div className="logged-as">
				<div>Zalogowany jako: </div>
				<div>{userDescription || 'Unknown User'}</div>
			</div>
			<div>
				{activeTheme === 'dark' ? (
					<SunIcon
						className="theme-icon"
						onClick={handleThemeClick}
						height={40}
						width={40}
						fill={theme.fontcolor}
					/>
				) : (
					<MoonIcon
						className="theme-icon"
						onClick={handleThemeClick}
						height={40}
						width={40}
						fill={theme.fontcolor}
					/>
				)}
			</div>
			<div>
				<FontAwesomeIcon
					className="settings-icon"
					ref={settingsButtonRef}
					icon={faGear}
					onClick={toggleSettingsDropdown}
					size="2xl"
				/>
				{isSettingsDropdownOpen && (
					<div
						ref={settingsDropdownRef}
						className="dropdown-menu menu-settings">
						{settingsLinks.map((route) => {
							let path;
							let label;

							switch (route.menu_key) {
								case 'admin-regions':
									path = '/admin-regions';
									label = (
										<span>
											<FontAwesomeIcon
												icon={faLayerGroup}
											/>
											Regiony
										</span>
									);
									break;
								case 'admin-documents':
									path = '/admin-documents';
									label = (
										<span>
											<FontAwesomeIcon icon={faFile} />
											Dokumenty
										</span>
									);
									break;
								case 'sprzedawcy':
									path = '/sprzedawcy';
									label = (
										<span>
											<FontAwesomeIcon icon={faUsers} />
											Sprzedawcy
										</span>
									);
									break;
								case 'stawka':
									path = '/stawka';
									label = (
										<span>
											<FontAwesomeIcon icon={faCoins} />
											Stawka Wartości Zamówienia
										</span>
									);
									break;
								case 'taryfy':
									path = '/taryfy';
									label = (
										<span>
											<FontAwesomeIcon icon={faCoins} />
											Taryfy
										</span>
									);
									break;
								case 'osd':
									path = '/osd';
									label = (
										<span>
											<FontAwesomeIcon icon={faUsers} />
											OSD
										</span>
									);
									break;
								case 'gminy':
									path = '/gminy';
									label = (
										<span>
											<FontAwesomeIcon icon={faUsers} />
											Uczestnicy Grupy Zakupowej
										</span>
									);
									break;
								case 'admin-settings':
									path = '/admin-settings';
									label = (
										<span>
											<FontAwesomeIcon icon={faGears} />
											Ustawienia
										</span>
									);
									break;
								case 'admin-documents-history':
									path = '/admin-documents-history';
									label = (
										<span>
											<FontAwesomeIcon
												icon={faBoxArchive}
											/>
											Dokumenty Historyczne
										</span>
									);
									break;

								default:
									return null;
							}

							return (
								<Link
									key={route.menu_key}
									id={route.menu_key}
									onClick={toggleSettingsDropdown}
									to={path}>
									{label}
								</Link>
							);
						})}
						<Link
							key="admin-users-admin"
							id="admin-users-admin"
							onClick={toggleSettingsDropdown}
							to="/admin-users-admin">
							<FontAwesomeIcon icon={faUsers} />
							Użytkownicy
						</Link>
						{isAdminSettings && (
							<Link
								key="import-data"
								id="import-data"
								onClick={toggleSettingsDropdown}
								to="/import-data">
								<FontAwesomeIcon icon={faFileImport} />
								Import danych z XLS
							</Link>
						)}
						{isAdminSettings && (
							<Link
								key="import-zal"
								id="import-zal"
								onClick={toggleSettingsDropdown}
								to="/import-zal">
								<FontAwesomeIcon icon={faFileImport} />
								Import Załącznika nr 3
							</Link>
						)}
						{isAdminSettings && (
							<div
								className="upload-cennik"
								onClick={() =>
									document.getElementById('fileInput').click()
								}>
								<FontAwesomeIcon icon={faFileImport} />
								Dodaj Cennik GZE
								<input
									id="fileInput"
									type="file"
									style={{ display: 'none' }}
									accept="application/pdf"
									onChange={handleFileChange}
								/>
							</div>
						)}
					</div>
				)}
				<Icowyloguj
					onClick={handleLogout}
					width={40}
					fill={theme.danger}
				/>
			</div>
		</NavbarContainer>
	);
};

export default TopNavbar;
