import styled from 'styled-components';

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	border-bottom-left-radius: 5px;
	padding: 1px 1px 1px 10px;
	min-width: 15%;
	position: fixed;
	top: 0;
	right: 0;
	background-color: ${(props) => props.theme.white};
	z-index: 20;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 12px -2px,
		rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

	div:nth-of-type(4),
	div:nth-of-type(5),
	div:nth-of-type(6) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	svg {
		cursor: pointer;
	}

	.logged-as {
		flex-direction: column;
		padding: 0 15px;
	}

	.burger-menu {
		display: none;
	}

	.group-name {
		padding: 0 5px;
	}

	.region-name {
		.region-name-user {
			text-align: center;
			flex-direction: column;
			padding: 0 15px;
		}
	}

	.menu-settings {
		top: 100%;
		right: 0;
	}

	.settings-icon {
		margin: 0 10px;
	}

	.dropdown-menu {
		display: flex;
		flex-direction: column;
		position: absolute;
		background-color: ${(props) => props.theme.white};
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		min-width: 160px;
		box-shadow: 0px 24px 28px -10px rgba(0, 0, 0, 0.2);
		z-index: 3;

		a {
			width: 100%;
			color: ${(props) => props.theme.fontcolor};
			padding: 6px 8px;
			text-decoration: none;
			display: block;

			svg {
				width: 18px;
				margin-right: 15px;
			}
		}

		.upload-cennik{
			width: 100%;
			color: ${(props) => props.theme.fontcolor};
			padding: 6px 8px;
			text-decoration: none;
			display: block;
			cursor: pointer;

			svg {
				width: 18px;
				margin-right: 15px;
			}
		}

		.upload-cennik:hover {
			background-color: ${(props) => props.theme.hoverwhite};
		}

		a:hover {
			background-color: ${(props) => props.theme.hoverwhite};
		}

		a:nth-of-type(8){
			border-top: 1px ${(props) => props.theme.fontcolor} solid;
		}

		a:last-of-type {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			border-bottom: none;
		}
	}
	.logo {
		display: none;
		align-self: center;
		margin: 0 auto;
	}
	@media (max-width: 768px) {
		width: 100%;
		justify-content: end;

		.burger-menu {
			display: flex;
			margin-right: auto;
			background-color: ${(props) => props.theme.white};
			border: 1px solid rgba(0, 0, 0, 0.2);
			border-radius: 5px;
		}

		.settings-icon {
			display: none;
		}
	}
	@media (max-width: 425px) {
		.logo {
			display: block;
		}

		.theme-icon {
			position: absolute;
			top: 0;
			left: 13.5%;
		}

		.logged-as {
			font-size: 14px;
		}
		.group-name,
		.logged-as {
			display: none;
		}
	}
	@media (max-width: 320px) {
		.logo {
			height: 33px !important;
			width: 110px;
		}
	}
`;
