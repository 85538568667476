import styled from 'styled-components';
import { Sidebar } from 'react-pro-sidebar';

export const StyledSidebar = styled(Sidebar)`
	top: 50% !important;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 12px -2px,
		rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

	ul li.ps-active {
		position: relative;

		svg {
			fill: ${(props) => props.theme.primary};
		}

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 10px;
			height: 100%;
			background-color: ${(props) => props.theme.primary};
		}
	}

	.ps-submenu-content {
		background-color: ${(props) => props.theme.white};
	}

	.logo {
		height: 60px;
		transition: height 0.3s ease;
	}

	.button {
	position: absolute;
	bottom: 125px;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	border-radius: 5px;
	font-weight: 700;
	padding: 10px;
	color: rgb(40, 40, 40);
	background-color: ${(props) => props.theme.primary};
	cursor: pointer;

	&:hover {
		background-color: #f0d032;
	}
}

.button.disabled {
	background-color: #cccccc;
	color: #666666;
	cursor: default;
	pointer-events: none; 
}


	.small-logo {
		height: 30px;
		transition: height 0.3s ease;
	}

	.expand-icon {
		position: absolute;
		bottom: 5%;
		left: 50%;
		transition: transform 0.3s;
	}

	.settings-menu-item {
		display: none;
	}
	@media (max-width: 1024px) {
		.logo,
		.small-logo {
			height: 50px;
		}
	}
	@media (max-width: 768px) {
		.expand-icon {
			display: none;
		}
		.settings-menu-item {
			display: block;
		}
	}
	@media (max-height: 590px) {
		.ps-menu-root {
			max-height: 50vh !important;
			overflow-y: scroll;
		}
	}
`;
