import { useState, useEffect } from 'react'
import { UsageDataTable } from './Zalacznik1.style'
import { Zalacznik1Layout } from './Zalacznik1.style'
import { Button } from '../../components/Button/Button'
import { sendRequest } from '../../api/sendRequest'
import { TailSpin } from 'react-loader-spinner'
import Select from 'react-select'
import { useTheme } from 'styled-components'
import { useStyledSelect } from '../../components/Select/StyledSelect'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { font } from '../../font/font'

const Zalacznik1 = () => {
	const customStyles = useStyledSelect()
	const theme = useTheme()
	const [isLoading, setIsLoading] = useState(true)
	const [contactFilter, setContactFilter] = useState([])
	const [miastoFilter, setMiastoFilter] = useState([])
	const [showFilters, setShowFilters] = useState(false)
	const [data, setData] = useState()
	const [searchValue, setSearchValue] = useState('')
	const [tableTitle, setTableTitle] = useState('')
	const [gminaId, setGminaId] = useState([])
	const urlParams = new URLSearchParams(window.location.search)
	const groupType = urlParams.get('groupType')
	const type = urlParams.get('type')
	const recipient_id = urlParams.get('recipient_id')

	const fetchData = async () => {
		const getGminy = {
			action: 'get-odbiorca-gmina-list',
			shopping_group_id: groupType,
			odbiorca_id: recipient_id,
		}
		const getGroups = {
			action: 'get-shopping-group',
		}

		try {
			const groupsResponse = await sendRequest(getGroups)
			const response = await sendRequest(getGminy)

			const enrichedData = response.data._data_.map((item, index) => ({
				...item,
				lp: index + 1,
			}))
			setData(enrichedData)

			const matchingGroup = groupsResponse.data._data_.find(group => group.id == groupType)
			if (matchingGroup) {
				if (type === 'porozumienie') {
					setTableTitle(`do Porozumienia: ${matchingGroup.name}`)
				} else if (type === 'umowa') {
					setTableTitle(`do Umowy: ${matchingGroup.name}`)
				} else {
					setTableTitle(matchingGroup.name)
				}
			}
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
		const storedGminaId = localStorage.getItem('gmina_id')
		if (storedGminaId) {
			setGminaId(JSON.parse(storedGminaId))
		}
	}, [])

	const getUniqueOptions = key => {
		const unique = [...new Set(data.map(item => item[key]))]
		return unique.map(value => ({ value, label: value }))
	}

	const exportToExcel = () => {
		let selectedGminaNazwa
		const selectedGminaId = sessionStorage.getItem('selectedGminaId')
		if (selectedGminaId) {
			if (selectedGminaId === 'all') {
				selectedGminaNazwa = gminaId[0].gmina_nazwa
			} else {
				const selectedGmina = gminaId.find(g => g.gmina_id == selectedGminaId)
				selectedGminaNazwa = selectedGmina ? selectedGmina.gmina_nazwa : gminaId[0].gmina_nazwa
			}
		} else {
			selectedGminaNazwa = gminaId[0].gmina_nazwa
		}

		const transformedData = filteredData.map((item, index) => ({
			'Lp.': index + 1,
			'Nazwa Uczestnika Grupy': item.gmina_nazwa,
			'Nazwa odbiorcy': item.odbiorca_nazwa,
			Miejscowość: item.odbiorca_miasto,
			Ulica: item.odbiorca_ulica,
			'Nr budynku': item.odbiorca_budynek,
			'Nr lokalu': item.odbiorca_lokal,
			'Kod pocztowy': item.odbiorca_kod,
			Poczta: item.odbiorca_poczta,
			'Nr NIP': item.odbiorca_nip,
			'Nr REGON': item.odbiorca_regon,
			'Czy odrębna Umowa?': item.odbiorca_odrebna_umowa === 1 ? 'Tak' : 'Nie',
		}))

		const ws = XLSX.utils.aoa_to_sheet([
			[null, `JST: ${selectedGminaNazwa}`],
			[null, 'Załącznik nr 1'],
			[null, 'Wykaz podmiotów objętych przedmiotem zamówienia, reprezentowanych przez Gminę/Miasto'],
		])

		XLSX.utils.sheet_add_json(ws, [{}], { skipHeader: true, origin: -1 })

		XLSX.utils.sheet_add_json(ws, transformedData, { origin: 4 })

		const colsWidth = transformedData.reduce((widths, row) => {
			Object.values(row).forEach((val, index) => {
				const valueLength = val ? val.toString().length : 0
				widths[index] = Math.max(valueLength, widths[index] || 10)
			})
			return widths
		}, [])

		ws['!cols'] = colsWidth.map(w => ({ wch: w }))

		const wb = XLSX.utils.book_new()
		const currentDateTime = new Date().toLocaleString().replace(/[\s:]/g, '_')
		const fileName = `zalacznik1_${currentDateTime}.xlsx`

		XLSX.utils.book_append_sheet(wb, ws, 'Dane')
		XLSX.writeFile(wb, fileName)
	}

	const exportToPDF = () => {
		let selectedGminaNazwa
		const selectedGminaId = sessionStorage.getItem('selectedGminaId')
		if (selectedGminaId) {
			if (selectedGminaId === 'all') {
				selectedGminaNazwa = gminaId[0].gmina_nazwa
			} else {
				const selectedGmina = gminaId.find(g => g.gmina_id == selectedGminaId)
				selectedGminaNazwa = selectedGmina ? selectedGmina.gmina_nazwa : gminaId[0].gmina_nazwa
			}
		} else {
			selectedGminaNazwa = gminaId[0].gmina_nazwa
		}
	
		const doc = new jsPDF('portrait', 'mm', 'a4')
	
		doc.addFileToVFS('CustomFont.ttf', font)
		doc.addFont('CustomFont.ttf', 'CustomFont', 'normal')
		doc.setFont('CustomFont')
	
		doc.setFontSize(12)
		doc.text(`JST: ${selectedGminaNazwa}`, 20, 20)
		doc.text('Załącznik nr 1', 20, 30)
		doc.text('Wykaz podmiotów objętych przedmiotem zamówienia, reprezentowanych przez Gminę/Miasto', 20, 40)
	
		const styles = {
			font: 'CustomFont',
			lineWidth: 0.15,
			lineColor: [0, 0, 0],
			fontSize: 7,
		}
	
		const headStyles = {
			fillColor: [254, 228, 91],
			textColor: [0, 0, 0],
			lineWidth: 0.15,
			lineColor: [0, 0, 0],
		}
	
		const columnNumbers = ['', '1.', '2.', '3.', '4.', '5.', '6.', '7.', '8.', '9.', '10.', '11.']
	
		doc.autoTable({
			head: [
				columnNumbers,
				[
					'Lp.',
					'Nazwa Uczestnika Grupy',
					'Nazwa odbiorcy',
					'Miejscowość',
					'Ulica',
					'Nr budynku',
					'Nr lokalu',
					'Kod pocztowy',
					'Poczta',
					'Nr NIP',
					'Nr REGON',
					'Czy odrębna Umowa?',
				],
			],
			body: filteredData.map((item, index) => [
				index + 1,
				item.gmina_nazwa,
				item.odbiorca_nazwa,
				item.odbiorca_miasto,
				item.odbiorca_ulica,
				item.odbiorca_budynek,
				item.odbiorca_lokal,
				item.odbiorca_kod,
				item.odbiorca_poczta,
				item.odbiorca_nip,
				item.odbiorca_regon,
				item.odbiorca_odrebna_umowa === 1 ? 'Tak' : 'Nie',
			]),
			startY: 50,
			styles: styles,
			headStyles: headStyles,
			bodyStyles: styles,
			rowPageBreak: 'avoid',
		})
	
		const currentDateTime = new Date().toLocaleString().replace(/[\s:]/g, '_')
		doc.save(`zalacznik1_${currentDateTime}.pdf`)
	}	

	const filteredData = data
		? data.filter(item => {
				return (
					(contactFilter.length === 0 || contactFilter.some(filter => filter.value === item.odbiorca_nazwa)) &&
					(miastoFilter.length === 0 || miastoFilter.some(filter => filter.value === item.odbiorca_miasto))
				)
		  })
		: []

	const toggleFilters = () => setShowFilters(!showFilters)

	const columns = [
		{
			name: 'Lp.',
			selector: row => row.lp,
			grow: 1,
			width: '45px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nazwa Uczestnika Grupy',
			selector: row => row.gmina_nazwa,
			minWidth: '225px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nazwa Odbiorcy',
			selector: row => row.odbiorca_nazwa,
			minWidth: '625px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Miejscowość',
			selector: row => row.odbiorca_miasto,
			minWidth: '140px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Ulica',
			selector: row => row.odbiorca_ulica,
			minWidth: '250px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nr budynku',
			selector: row => row.odbiorca_budynek,
			minWidth: '85px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nr lokalu',
			selector: row => row.odbiorca_lokal,
			minWidth: '85px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Kod pocztowy',
			selector: row => row.odbiorca_kod,
			minWidth: '95px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Poczta',
			selector: row => row.odbiorca_kod,
			minWidth: '95px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'NIP',
			selector: row => row.odbiorca_nip,
			minWidth: '120px',
			maxWidth: '120px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'REGON',
			selector: row => row.odbiorca_regon,
			minWidth: '120px',
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Czy odrębna umowa?',
			selector: row => (row.odbiorca_odrebna_umowa === 1 ? 'Tak' : 'Nie'),
			minWidth: '120px',
			sortable: true,
			sortField: '0',
		},
	]

	if (isLoading) {
		return (
			<TailSpin
				height='80'
				width='80'
				color={theme.primary}
				ariaLabel='tail-spin-loading'
				radius='1'
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		)
	}

	return (
		<Zalacznik1Layout>
			<h2 className='page-title'>Załącznik nr 1 {tableTitle}</h2>
			<div className='table-container'>
				<UsageDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={
						filteredData && filteredData.length > 0
							? filteredData.filter(customer =>
									Object.values(customer).some(value => String(value).toLowerCase().includes(searchValue.toLowerCase()))
							  )
							: []
					}
					columns={columns}
					noDataComponent='Brak danych spełniających kryteria'
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100, 150, 250, 500]}
					fixedHeader
					defaultSortFieldId={7}
				/>
				<div className='table-menu'>
					<input
						placeholder='Szukaj...'
						className='search-input'
						value={searchValue}
						onChange={e => setSearchValue(e.target.value)}
					/>
					<Button
						style={{
							fontSize: '14px',
						}}
						onClick={toggleFilters}>
						{showFilters ? 'Ukryj filtry' : 'Pokaż filtry'}
					</Button>
					<div style={{ marginLeft: 'auto' }}>
						<Button onClick={exportToPDF}>Eksportuj do PDF</Button>
						<Button style={{ marginLeft: '15px' }} onClick={exportToExcel}>
							Eksportuj do Excela
						</Button>
					</div>
					{showFilters && (
						<div className='filters-dropdown'>
							<Select
								options={getUniqueOptions('odbiorca_nazwa')}
								onChange={selectedOptions => setContactFilter(selectedOptions || [])}
								value={contactFilter}
								placeholder='Kontakt'
								styles={customStyles}
								isMulti
							/>

							<Select
								options={getUniqueOptions('odbiorca_miasto')}
								onChange={selectedOptions => setMiastoFilter(selectedOptions || [])}
								value={miastoFilter}
								placeholder='Miejscowość'
								styles={customStyles}
								isMulti
							/>
						</div>
					)}
				</div>
			</div>
		</Zalacznik1Layout>
	)
}

export default Zalacznik1
