import styled from 'styled-components';

export const AdminDocumentsLayout = styled.div`
	margin: 60px 0 50px 0;

	.table-container {
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;

		.add-button {
			width: 242px;
			margin-bottom: 10px;
		}
	}
	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 15;

		.modal-content {
			background-color: ${(props) => props.theme.white};
			padding: 20px;
			border-radius: 8px;
			min-width: 400px;
			max-width: 900px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			font-size: 12px;

			.form {
				display: flex;
				justify-content: space-between;
				.column {
					flex: 1;
					margin: 0 10px;
					font-size: 12px;
				}
			}

			.modal-actions {
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;
			}

			.modal-actions {
				.cancel {
					margin-right: 10px;
				}
				.add {
					margin-left: 10px;
				}
			}

			.err-msg {
				color: ${(props) => props.theme.danger};
				margin-top: 2px;
				min-height: 17px;
				font-size: 11px;
			}

			h2 {
				margin-bottom: 25px;
			}

			.input-form {
				width: 100%;
				margin-top: 5px;
				padding: 10px 10px;
				border: none;
				outline: none;
				border-radius: 5px;
				background-color: ${(props) => props.theme.secondary};
				color: ${(props) => props.theme.fontcolor};
			}
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px
					${(props) => props.theme.secondary} inset !important;
			}
		}
	}
`;

export const EditorLayout = styled.div`
	margin: 100px 0 50px 0;
	height: 85vh;
	position: relative;

	.toolbar {
		position: absolute;
		display: flex;
		right: 0;
		top: -45px;
		gap: 5px;
		z-index: 2;

		input {
			padding: 5px;
		}
	}

	.margin-select {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: black;
		position: absolute;
		top: 45px;
		right: 10px;
		font-size: 14px;
	}

	.margin-select-top-bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: black;
		position: absolute;
		top: 45px;
		right: 150px;
		font-size: 14px;
	}

	.quill {
		min-height: 100%;
		background-color: white;
		color: black;

		.ql-toolbar {
			position: sticky;
			top: 0;
			z-index: 1;
			background-color: #fff;
		}

		.ql-container {
			background-color: gray;
		}

		.ql-editor {
			position: relative;
			margin: auto;
			background-color: white;
			width: 8.27in;
			height: 83vh;
		}

		.ql-editor > *:nth-child(1)::after,
		.ql-editor > *:nth-child(2)::after,
		.ql-editor > *:nth-child(3)::after,
		.ql-editor > *:nth-child(4)::after,
		.ql-editor > *:nth-child(5)::after,
		.ql-editor > *:nth-child(6)::after,
		.ql-editor > *:nth-child(7)::after,
		.ql-editor > *:nth-child(8)::after,
		.ql-editor > *:nth-child(9)::after,
		.ql-editor > *:nth-child(10)::after,
		.ql-editor > *:nth-child(11)::after,
		.ql-editor > *:nth-child(12)::after,
		.ql-editor > *:nth-child(13)::after,
		.ql-editor > *:nth-child(14)::after,
		.ql-editor > *:nth-child(15)::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			border-bottom: 1px dashed black;
		}

		.ql-editor > *:nth-child(1)::after {
			top: 1195px;
		}

		.ql-editor > *:nth-child(2)::after {
			top: 2363px;
		}

		.ql-editor > *:nth-child(3)::after {
			top: 3525px;
		}

		.ql-editor > *:nth-child(4)::after {
			top: 4692px;
		}

		.ql-editor > *:nth-child(5)::after {
			top: 5855px;
		}
		.ql-editor > *:nth-child(6)::after {
			top: 7028px;
		}
		.ql-editor > *:nth-child(7)::after {
			top: 8196px;
		}
		.ql-editor > *:nth-child(8)::after {
			top: 9358px;
		}
		.ql-editor > *:nth-child(9)::after {
			top: 10525px;
		}
		.ql-editor > *:nth-child(10)::after {
			top: 11692px;
		}
		.ql-editor > *:nth-child(11)::after {
			top: 12813px;
		}
		.ql-editor > *:nth-child(12)::after {
			top: 13976px;
		}
		.ql-editor > *:nth-child(13)::after {
			top: 15139px;
		}
		.ql-editor > *:nth-child(14)::after {
			top: 16302px;
		}
		.ql-editor > *:nth-child(15)::after {
			top: 17465px;
		}

		.ql-editor > *:nth-child(1)::before,
		.ql-editor > *:nth-child(2)::before,
		.ql-editor > *:nth-child(3)::before,
		.ql-editor > *:nth-child(4)::before,
		.ql-editor > *:nth-child(5)::before,
		.ql-editor > *:nth-child(6)::before,
		.ql-editor > *:nth-child(7)::before,
		.ql-editor > *:nth-child(8)::before,
		.ql-editor > *:nth-child(9)::before,
		.ql-editor > *:nth-child(10)::before,
		.ql-editor > *:nth-child(11)::before,
		.ql-editor > *:nth-child(12)::before,
		.ql-editor > *:nth-child(13)::before,
		.ql-editor > *:nth-child(14)::before,
		.ql-editor > *:nth-child(15)::before {
			position: absolute;
			left: 10px;
			width: 20px;
			height: 20px;
			background-color: #f0f0f0;
			color: black;
			text-align: center;
			line-height: 20px;
		}

		.ql-editor > *:nth-child(1)::before {
			content: '1';
			left: 0;
			top: 1175px;
		}

		.ql-editor > *:nth-child(2)::before {
			content: '2';
			left: 0;
			top: 2343px;
		}

		.ql-editor > *:nth-child(3)::before {
			content: '3';
			left: 0;
			top: 3505px;
		}

		.ql-editor > *:nth-child(4)::before {
			content: '4';
			left: 0;
			top: 4672px;
		}
		.ql-editor > *:nth-child(5)::before {
			content: '5';
			left: 0;
			top: 5835px;
		}
		.ql-editor > *:nth-child(6)::before {
			content: '6';
			top: 7008px;
			left: 0;
		}
		.ql-editor > *:nth-child(7)::before {
			content: '7';
			top: 8176px;
			left: 0;
		}
		.ql-editor > *:nth-child(8)::before {
			content: '8';
			top: 9338px;
			left: 0;
		}
		.ql-editor > *:nth-child(9)::before {
			content: '9';
			top: 10505px;
			left: 0;
		}
		.ql-editor > *:nth-child(10)::before {
			content: '10';
			top: 11672px;
			left: 0;
		}
		.ql-editor > *:nth-child(11)::before {
			content: '11';
			top: 12793px;
			left: 0;
		}
		.ql-editor > *:nth-child(12)::before {
			content: '12';
			top: 13956px;
			left: 0;
		}
		.ql-editor > *:nth-child(13)::before {
			content: '13';
			top: 15119px;
			left: 0;
		}
		.ql-editor > *:nth-child(14)::before {
			content: '14';
			top: 16282px;
			left: 0;
		}
		.ql-editor > *:nth-child(15)::before {
			content: '15';
			top: 17445px;
			left: 0;
		}
	}

	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 15;

		.modal-content {
			background-color: ${(props) => props.theme.white};
			padding: 20px;
			border-radius: 8px;
			min-width: 400px;
			max-width: 900px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			font-size: 12px;

			.modal-actions {
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;
			}

			.modal-actions {
				.cancel {
					margin-right: 10px;
				}
				.add {
					margin-left: 10px;
				}
			}
		}
	}
`;
