import React, { useState } from 'react';
import { LoginContainer } from './Login.styles';
import { ReactComponent as Icooko } from './../../svg/ico-oko.svg';
import { ReactComponent as Logolight } from './../../svg/logo-light.svg';
import { Button } from '../../components/Button/Button';
import logo from './../../img/os_ul_kal-trans-hd.png';
import { useTheme } from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { TailSpin } from 'react-loader-spinner';

const Login = ({ setToken }) => {
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [login, setLogin] = useState('');
	const [loginError, setLoginError] = useState('');
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
	const [isChecked, setIsChecked] = useState(false);
	const navigate = useNavigate();
	const handlePasswordToggle = () => {
		setPasswordVisible(!passwordVisible);
	};
	const apiUrl = process.env.REACT_APP_API_URL;

	const sendRequest = async (payload) => {
		const response = await axios.post(apiUrl, payload);
		return response;
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		let isValid = true;

		if (!login) {
			setLoginError('Login jest wymagany');
			isValid = false;
		} else {
			setLoginError('');
		}

		if (!password) {
			setPasswordError('Hasło jest wymagane');
			isValid = false;
		} else {
			setPasswordError('');
		}

		if (isValid) {
			setIsLoading(true);

			const loginPayload = {
				username: login,
				password: password,
				action: 'login',
			};

			try {
				const response = await axios.post(apiUrl, loginPayload);

				if (response.data.status === 0) {
					setPasswordError('Login lub hasło są nieprawidłowe');
				} else {
					const token = response.data.JWT;
					const routes = response.data.routes;
					const groupName = response.data.group_name;
					const userDescription = response.data.user_description;
					const gminaId = response.data.gmina_id;

					localStorage.removeItem('routes');
					localStorage.setItem('routes', JSON.stringify(routes));
					localStorage.setItem('group_name', groupName);
					localStorage.setItem('user_description', userDescription);
					localStorage.setItem('gmina_id', JSON.stringify(gminaId));

					const encryptedLogin = CryptoJS.AES.encrypt(
						login,
						SECRET_KEY
					).toString();
					const encryptedPassword = CryptoJS.AES.encrypt(
						password,
						SECRET_KEY
					).toString();
					if (isChecked) {
						Cookies.set('user_data', encryptedLogin, {
							expires: 7,
							secure: true,
							sameSite: 'Strict',
						});
						Cookies.set('session_data', encryptedPassword, {
							expires: 7,
							secure: true,
							sameSite: 'Strict',
						});
					}

					setToken({token});
					navigate('/dashboard', { replace: true });
				}
			} catch (error) {
				console.error('Błąd podczas wysyłania requestu:', error);
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<LoginContainer>
			{isLoading && (
				<TailSpin
					height="80"
					width="80"
					color={theme.primary}
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						transform: 'translate(-50%, -50%)',
						zIndex: 2,
						height: '100vh',
						width: '100vw',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
					}}
				/>
			)}
			<div className="login-box">
				<img src={logo} alt="Logo" className="logo" width={102} />
				<form onSubmit={handleSubmit}>
					<div className="login-input">
						<label htmlFor="login">Login:</label>
						<input
							type="text"
							id="login"
							placeholder="Nazwa użytkownika"
							value={login}
							onChange={(e) => setLogin(e.target.value)}
						/>
						<p className="err-msg">{loginError}</p>
					</div>
					<div className="password-input">
						<label htmlFor="pass">Hasło:</label>
						<input
							type={passwordVisible ? 'text' : 'password'}
							placeholder="Hasło"
							id="pass"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<button
							type="button"
							onClick={handlePasswordToggle}
							className="password-toggle">
							{passwordVisible ? (
								<Icooko
									width="45"
									height="45"
									fill={theme.darkerprimary}
								/>
							) : (
								<Icooko
									width="45"
									height="45"
									fill={theme.primary}
								/>
							)}
						</button>
						<p className="err-msg">{passwordError}</p>
					</div>
					<div className="remember-input">
						<label>
							<input
								type="checkbox"
								onChange={() => {
									setIsChecked(!isChecked);
								}}
							/>
							<svg
								className={`checkbox ${
									isChecked ? 'checkbox--active' : ''
								}`}
								aria-hidden="true"
								viewBox="0 0 15 11"
								fill="none">
								<path
									d="M1 4.5L5 9L14 1"
									strokeWidth="2"
									stroke={isChecked ? theme.primary : 'none'}
								/>
							</svg>
							Zaloguj automatycznie
						</label>
					</div>
					<div className="submit-input">
						<Button style={{ fontSize: '16px' }} type="submit">
							Login
						</Button>
					</div>
				</form>
				<p className="version-info">{process.env.REACT_APP_VERSION}</p>
			</div>
		</LoginContainer>
	);
};

export default Login;
