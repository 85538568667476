import { Outlet, useLocation } from 'react-router-dom';
import Login from './pages/Login/Login';
import useToken from './hooks/useToken';
import Nav from './components/Nav/Nav';
import TopNavbar from './components/TopNavbar/TopNavbar';
import { useState, useEffect } from 'react';
import { sendRequest } from './api/sendRequest';
import Loading from './components/Loading/Loading';
import Modal from './components/Modal/Modal';
import { Button } from './components/Button/Button';
import LoadingThreeDots from './components/Loading/LoadingThreeDots';
import Select from 'react-select';
import { useStyledSelect } from './components/Select/StyledSelect';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const Root = ({ toggleTheme }) => {
	const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
	const { token, setToken } = useToken();
	const customStyles = useStyledSelect();
	const [toggled, setToggled] = useState(false);
	const [isNavExpanded, setIsNavExpanded] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isInitialCheckDone, setIsInitialCheckDone] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [isGroupId1Enabled, setIsGroupId1Enabled] = useState(false);
	const [isGroupId2Enabled, setIsGroupId2Enabled] = useState(false);
	const [isAdm, setIsAdm] = useState(false);
	const [groups, setGroups] = useState();
	const [err, setErr] = useState('');
	const [selectedGroup, setSelectedGroup] = useState();
	const [modalText, setModalText] = useState('');
	const [documentList, setDocumentList] = useState([]);
	const location = useLocation();
	const [finishedProcedure, setFinishedProcedure] = useState(() => {
		const saved = localStorage.getItem('finishedProcedure');
		return saved ? saved === 'true' : false;
	});

	function isValidBase64(str) {
		try {
			const decodedStr = atob(str);
			const reEncodedStr = btoa(decodedStr);
			return (
				reEncodedStr === str ||
				reEncodedStr === str + '=' ||
				reEncodedStr === str + '=='
			);
		} catch (err) {
			console.log('Error during encoding/decoding: ', err.message);
			return false;
		}
	}

	useEffect(() => {
		if (token) {
			try {
				const payload = token.split('.')[1];
				if (!isValidBase64(payload)) {
					throw new Error('Payload is not valid Base64');
				}
				const decodedPayload = JSON.parse(atob(payload));
				const isTokenExpired =
					decodedPayload.exp < new Date().getTime() / 1000;

				if (isTokenExpired) {
					localStorage.removeItem('token');
					setToken('');
				} else {
					setIsInitialCheckDone(true);
				}
				if (decodedPayload.adm == 1) {
					setIsAdm(true);
				}
			} catch (error) {
				console.error(
					'An error occurred while decoding the token:',
					error
				);
				localStorage.removeItem('token');
				setToken('');
			}
		} else {
			const encryptedLogin = Cookies.get('user_data');
			const encryptedPassword = Cookies.get('session_data');

			if (encryptedLogin && encryptedPassword) {
				setIsLoading(true);
				const bytesLogin = CryptoJS.AES.decrypt(
					encryptedLogin,
					SECRET_KEY
				);
				const bytesPassword = CryptoJS.AES.decrypt(
					encryptedPassword,
					SECRET_KEY
				);
				const login = bytesLogin.toString(CryptoJS.enc.Utf8);
				const password = bytesPassword.toString(CryptoJS.enc.Utf8);
				const loginPayload = {
					username: login,
					password: password,
					action: 'login',
				};

				sendRequest(loginPayload)
					.then((response) => {
						if (response.data.status === 1) {
							const routes = response.data.routes;
							const newToken = response.data.JWT;

							localStorage.setItem(
								'routes',
								JSON.stringify(routes)
							);
							setToken({ token: newToken });
						} else {
							localStorage.removeItem('login');
							localStorage.removeItem('password');
						}
					})
					.catch((err) => {
						console.error(
							'Failed to re-login with saved credentials: ',
							err
						);
						localStorage.removeItem('login');
						localStorage.removeItem('password');
					})
					.finally(() => {
						setIsLoading(false);
						setIsInitialCheckDone(true);
					});
			} else {
				setIsInitialCheckDone(true);
			}
		}
	}, [token, setToken]);

	useEffect(() => {
		const pageTitle = () => {
			switch (location.pathname) {
				case '/':
					return 'Logowanie - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/dashboard':
					return 'Panel Główny - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/admin-settings':
					return 'Ustawienia - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/admin-users-admin':
					return 'Użytkownicy - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/admin-documents':
					return 'Admin Dokumenty - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/documents':
					return 'Dokumenty - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/nabywcy':
					return 'Nabywcy - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/sprzedawcy':
					return 'Sprzedawcy - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/odbiorcy':
					return 'Odbiorcy - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/taryfy':
					return 'Taryfy - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/osd':
					return 'OSD - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/gminy':
					return 'Uczestnicy Grupy Zakupowej - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/admin-regions':
					return 'Regiony - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/documents-history':
					return 'Dokumenty Historyczne - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/ppe':
					return 'PPE - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/stawka':
					return 'Stawki wartości zamówienia - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				case '/noauth':
					return 'Brak autoryzacji - Oświetlenie Uliczne i Drogowe sp. z o.o.';
				default:
					return 'System Oświetlenie Uliczne i Drogowe sp. z o.o.';
			}
		};
		document.title = pageTitle();
	}, [location]);

	const checkProcedure = async () => {
		const response = await sendRequest({ action: 'check-procedure' });
		const isFinished = response.data._data_ !== 0;

		setFinishedProcedure(isFinished);
		localStorage.setItem('finishedProcedure', isFinished.toString());
	};

	const updateShoppingGroups = async () => {
		try {
			const checkResponse = await sendRequest({
				action: 'check-shopping-group-by-ppe',
			});
			const enabledGroups = new Set();
			checkResponse.data._data_.forEach((group) => {
				if (group.shopping_group_enabled) {
					enabledGroups.add(group.shopping_group_id);
				}

				if (group.shopping_group_id === 1) {
					setIsGroupId1Enabled(!!group.shopping_group_enabled);
				} else if (group.shopping_group_id === 2) {
					setIsGroupId2Enabled(!!group.shopping_group_enabled);
				}
			});

			const groupsResponse = await sendRequest({
				action: 'get-shopping-group',
			});
			setGroups(
				groupsResponse.data._data_
					.filter((group) => enabledGroups.has(group.id))
					.map((group) => ({
						value: group.id,
						label: group.name,
					}))
			);
		} catch (error) {
			console.error('Error updating shopping groups:', error);
		}
	};

	useEffect(() => {
		if (token) {
			checkProcedure();
			updateShoppingGroups();
		}
	}, [token]);

	if (isLoading) {
		return <Loading />;
	}

	if (!token && isInitialCheckDone) {
		return <Login setToken={setToken} />;
	}

	if (!isInitialCheckDone) {
		return <Loading />;
	}

	const finishProcedure = async () => {
		if (!selectedGroup) {
			setErr(
				'Należy wybrać grupę dla której chcesz zakończyć postępowanie!'
			);
			return;
		}
		setErr('');
		setIsModalLoading(true);
		try {
			const response = await sendRequest({
				action: 'finnish-procedure',
				shopping_group_id: selectedGroup.value,
			});

			if (
				response &&
				response.data &&
				response.data._data_ &&
				response.data._data_.documents &&
				Object.keys(response.data._data_.documents).length > 0
			) {
				const documentNames = Object.values(response.data._data_.documents).map(
					(doc) => doc.name
				);
				if(response.data._data_.shopping_group_id == 2){
					setModalText(`Te dokumenty do umowy rozdzielonej nie zostały zapisane:`);
				} else if (response.data._data_.shopping_group_id == 1){
					setModalText(`Te dokumenty do umowy kompleksowej nie zostały zapisane:`);
				}	
				setDocumentList(documentNames);
			} else {
				setModalText('Procedura zakończona pomyślnie');
				checkProcedure();
				setTimeout(() => {
					setIsModalOpen(false);
					setDocumentList([]);
					setModalText('');
					setErr('');
				}, 1000);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsModalLoading(false);
		}
	};

	return (
		<>
			<TopNavbar
				toggleTheme={toggleTheme}
				toggled={toggled}
				setToggled={setToggled}
			/>
			<Nav
				toggled={toggled}
				setToggled={setToggled}
				isExpanded={isNavExpanded}
				setIsExpanded={setIsNavExpanded}
				setIsModalOpen={setIsModalOpen}
				finishedProcedure={finishedProcedure}
				isGroupId1Enabled={isGroupId1Enabled}
				isGroupId2Enabled={isGroupId2Enabled}
				isAdm={isAdm}
			/>
			<main
				className={`content ${
					isNavExpanded ? 'expanded' : 'collapsed'
				}`}>
				<Outlet />
			</main>
			{isModalOpen && (
				<Modal
					setIsModalOpen={() => {
						setIsModalOpen(false);
						setDocumentList([]);
						setModalText('');
					}}>
					<h2>Czy na pewno chcesz zakończyć proces?</h2>
					<label>Dla grupy:</label>
					<Select
						options={groups}
						classNamePrefix="react-select"
						placeholder="Wybierz grupę..."
						styles={customStyles}
						value={selectedGroup}
						onChange={setSelectedGroup}
					/>
					<p className="err-msg" style={{ color: 'red' }}>
						{err}
					</p>
					{isModalLoading && <LoadingThreeDots />}
					<h3>{modalText}</h3>
					{documentList.map((name, index) => (
						<div key={index}>{name}</div>
					))}
					<div className="modal-actions">
						<Button
							className="cancel"
							onClick={() => {
								setIsModalOpen(false);
								setDocumentList([]);
								setModalText('');
							}}>
							Anuluj
						</Button>
						<Button className="add" onClick={finishProcedure}>
							Zakończ
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
};

export default Root;
