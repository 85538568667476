import styled from 'styled-components';

export const SettingsLayout = styled.div`
	margin: 60px 0 50px 0;

	.table-container {
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;

		.rdt_Table {
			background-color: ${(props) => props.theme.white};
		}
	}

	.action-button {
		width: 222px;
		margin-bottom: 20px;
	}

	.rdt_Table {
		padding-top: 56px;
		border-top: 1px solid rgba(0, 0, 0, 0.12);
	}

	.disabled {
		background-color: #cccccc;
		color: #666666;
		cursor: default;
		pointer-events: none;
	}

	.buttons{
		display: flex;
		gap: 10px;

		button{
			width: 250px;
			font-size: 16px;
		}
	}

	p{
		position: absolute;
		top: -50px;
		left: 150px;
	}

	input {
		padding: 5px 10px;
		width: 100%;
		border: none;
		outline: none;
		border-radius: 5px;
		color: ${(props) => props.theme.fontcolor};
		background-color: ${(props) => props.theme.white};
	}
	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 15;

		.modal-content {
			background-color: ${(props) => props.theme.white};
			padding: 20px;
			border-radius: 8px;
			min-width: 400px;
			max-width: 600px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			font-size: 12px;

			.modal-actions {
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;
			}

			.modal-actions {
				.cancel {
					margin-right: 10px;
				}
				.add {
					margin-left: 10px;
				}
			}

			.err-msg {
				color: ${(props) => props.theme.danger};
				margin-top: 2px;
				min-height: 17px;
				font-size: 11px;
			}

			h2 {
				margin-bottom: 25px;
			}

			.input-form {
				width: 100%;
				margin-top: 5px;
				padding: 10px 10px;
				border: none;
				outline: none;
				border-radius: 5px;
				background-color: ${(props) => props.theme.secondary};
				color: ${(props) => props.theme.fontcolor};
			}
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px
					${(props) => props.theme.secondary} inset !important;
			}
		}
	}
`;
