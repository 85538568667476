import { useState, useRef, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { RecipientsLayout } from './Recipients.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import Switch from 'rc-switch';
import useAccessControl from '../../hooks/useAccessControl';
import * as XLSX from 'xlsx';
import { jwtDecode } from 'jwt-decode';

const Recipients = () => {
	const isAccessVerified = useAccessControl();
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [gminy, setGminy] = useState([]);
	const [data, setData] = useState();
	const [deletingRow, setDeletingRow] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [newRecipient, setNewRecipient] = useState({
		id: '',
		nazwa: '',
		ulica: '',
		budynek: '',
		gmina_id: '',
		miasto: '',
		nip: '',
		regon: '',
		kod: '',
		poczta: '',
		lokal: '',
		odrebna_umowa: false,
	});
	const [errors, setErrors] = useState({
		nazwa: '',
		ulica: '',
		budynek: '',
		gmina_id: '',
		miasto: '',
		nip: '',
		regon: '',
		kod: '',
		poczta: '',
		lokal: '',
	});
	const [finishedProcedure, setFinishedProcedure] = useState(() => {
		const saved = localStorage.getItem('finishedProcedure');
		return saved ? saved === 'true' : false;
	});

	const fetchData = async () => {
		const getRecipients = {
			action: 'get-odbiorcy',
		};
		const checkProcedure = { action: 'check-procedure' };

		try {
			const checkProcedureResponse = await sendRequest(checkProcedure);
			const isFinished = checkProcedureResponse.data._data_ !== 0;
			setFinishedProcedure(isFinished);
			localStorage.setItem('finishedProcedure', isFinished.toString());
			const response = await sendRequest(getRecipients);
			setData(response.data._data_);

			const gminaIdString = localStorage.getItem('gmina_id');
			const gminaIdArray = JSON.parse(gminaIdString);

			setGminy(
				gminaIdArray.map((gmina) => ({
					value: gmina.gmina_id,
					label: gmina.gmina_nazwa,
				}))
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const decodeToken = () => {
		const token = localStorage.getItem('token');
		if (token) {
			const decoded = jwtDecode(token);
			return decoded.adm;
		}
		return 0;
	};

	const isAdmin = decodeToken() === 1;

	const clearForm = () => {
		setNewRecipient({
			id: '',
			nazwa: '',
			ulica: '',
			budynek: '',
			gmina_id: '',
			miasto: '',
			nip: '',
			regon: '',
			kod: '',
			poczta: '',
			lokal: '',
			odrebna_umowa: false,
		});
	};

	const closeModal = () => {
		if (isEditMode) {
			clearForm();
			setIsEditMode(false);
		}
		setIsModalOpen(false);
		setIsModalDeleteOpen(false);
		setDeletingRow();
	};

	const handleEditClick = (row) => {
		const selectedGmina = gminy.find(
			(gmina) => gmina.value === row.gmina_id
		);

		setNewRecipient({
			id: row.id,
			nazwa: row.nazwa,
			ulica: row.ulica,
			budynek: row.budynek,
			gmina_id: selectedGmina,
			miasto: row.miasto,
			nip: row.nip,
			regon: row.regon,
			kod: row.kod,
			poczta: row.poczta,
			lokal: row.lokal,
			odrebna_umowa: row.odrebna_umowa == 1 ? true : false,
		});
		setIsEditMode(true);
		setIsModalOpen(true);
	};

	const handleSwitchChange = (checked) => {
		setNewRecipient((prevState) => ({
			...prevState,
			odrebna_umowa: checked,
		}));
	};

	const handleEditRecipient = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const recipientData = {
				id: newRecipient.id,
				nazwa: newRecipient.nazwa,
				ulica: newRecipient.ulica,
				budynek: newRecipient.budynek,
				gmina_id: newRecipient.gmina_id.value,
				odrebna_umowa: newRecipient.odrebna_umowa ? 1 : 0,
				miasto: newRecipient.miasto,
				nip: newRecipient.nip,
				regon: newRecipient.regon,
				kod: newRecipient.kod,
				poczta: newRecipient.poczta,
				lokal: newRecipient.lokal,
			};

			const addRecipient = {
				action: 'edit-odbiorca',
				_data_: recipientData,
			};

			try {
				await sendRequest(addRecipient);
				closeModal();
				clearForm();
				fetchData();
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const handleDeleteRecipient = async () => {
		const deleteRecipient = {
			action: 'delete-odbiorca',
			id: deletingRow.id,
		};
		try {
			await sendRequest(deleteRecipient);
			fetchData();
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddRecipient = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const recipientData = {
				nazwa: newRecipient.nazwa,
				ulica: newRecipient.ulica,
				budynek: newRecipient.budynek,
				gmina_id: newRecipient.gmina_id.value,
				miasto: newRecipient.miasto,
				nip: newRecipient.nip,
				regon: newRecipient.regon,
				kod: newRecipient.kod,
				poczta: newRecipient.poczta,
				lokal: newRecipient.lokal,
				odrebna_umowa: newRecipient.odrebna_umowa ? 1 : 0,
			};

			const addRecipient = {
				action: 'add-odbiorca',
				_data_: recipientData,
			};

			try {
				const response = await sendRequest(addRecipient);
				if (response.data.status === 0) {
					setErrors((prevState) => ({
						...prevState,
						nazwa: 'Błąd dodawania nabywcy',
					}));
				} else if (response.data.status === 2) {
					setErrors((prevState) => ({
						...prevState,
						nip: response.data.message,
					}));
				} else {
					closeModal();
					clearForm();
					fetchData();
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const validateForm = () => {
		let isValid = true;
		let newErrors = {
			nazwa: '',
			ulica: '',
			budynek: '',
			gmina_id: '',
			miasto: '',
			nip: '',
			regon: '',
			kod: '',
			poczta: '',
			lokal: '',
		};

		const isNumeric = (value, allowDashes = false) => {
			const regex = allowDashes ? /^[0-9-]+$/ : /^[0-9]+$/;
			return regex.test(value);
		};

		for (const key in newRecipient) {
			if (key === 'id' || key === 'lokal' || key === 'ulica') continue;

			const value =
				newRecipient[key] != null
					? newRecipient[key].toString().trim()
					: '';
			if (value === '') {
				newErrors[key] = 'To pole jest wymagane';
				isValid = false;
			} else if (key === 'nip') {
				if (!isNumeric(value) || value.length !== 10) {
					newErrors[key] = 'NIP powinien zawierać 10 cyfr';
					isValid = false;
				}
			}
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		if (name === 'kod' && /^[0-9-]*$/.test(value)) {
			setNewRecipient((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else if (name === 'nip' && /^[0-9]*$/.test(value)) {
			setNewRecipient((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else if (name === 'regon' && /^[0-9-]*$/.test(value)) {
			setNewRecipient((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else if (name !== 'kod' && name !== 'nip' && name !== 'regon') {
			setNewRecipient((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '7px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							handleEditClick(row);
						}}
					/>
					<Icozamknij
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							setDeletingRow(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			width: '50px',
			grow: 1,
			omit: !(!finishedProcedure || isAdmin),
		},
		{
			name: 'Nazwa',
			selector: (row) => row.nazwa,
			grow: 6,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'NIP',
			selector: (row) => row.nip,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Regon',
			selector: (row) => row.regon,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Ulica',
			selector: (row) => row.ulica,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nr budynku',
			selector: (row) => row.budynek,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Nr lokalu',
			selector: (row) => row.lokal,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Miejscowość',
			selector: (row) => row.miasto,
			grow: 1.1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Kod pocztowy',
			selector: (row) => row.kod,
			grow: 1.2,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Poczta',
			selector: (row) => row.poczta,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Czy odrębna umowa?',
			selector: (row) => (row.odrebna_umowa == 1 ? 'Tak' : 'Nie'),
			width: '152px',
			grow: 2,
			sortable: true,
			sortField: '0',
		},
	];

	const exportToExcel = () => {
		const dataToExport = data.map((item) => ({
			Nazwa: item.nazwa,
			NIP: item.nip,
			Regon: item.regon,
			Ulica: item.ulica,
			'Nr budynku': item.budynek,
			'Nr lokalu': item.budynek,
			Miejscowość: item.miasto,
			'Kod pocztowy': item.kod,
			Poczta: item.poczta,
			'Czy odbrębna umowa?': item.odrebna_umowa == 1 ? 'Tak' : 'Nie',
		}));

		const ws = XLSX.utils.json_to_sheet(dataToExport);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Odbiorcy');

		const exportFileName = `Odbiorcy_${new Date().toLocaleDateString()}.xlsx`;

		XLSX.writeFile(wb, exportFileName);
	};

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	if (!isAccessVerified) {
		return null;
	}

	return (
		<RecipientsLayout>
			<h2 className="page-title">Odbiorcy</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={
						data && data.length > 0
							? data.filter((customer) =>
									Object.values(customer).some((value) =>
										String(value)
											.toLowerCase()
											.includes(searchValue.toLowerCase())
									)
							  )
							: []
					}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<div className="table-menu">
					{(!finishedProcedure || isAdmin) && (
						<Button
							className="add-button"
							onClick={() => setIsModalOpen(true)}
							style={{
								fontSize: '14px',
							}}>
							Dodaj odbiorcę
						</Button>
					)}
					<input
						placeholder="Szukaj..."
						className="search-input"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<Button
						style={{
							fontSize: '14px',
							marginBottom: '10px',
							marginLeft: 'auto',
						}}
						onClick={exportToExcel}>
						Eksportuj do Excela
					</Button>
				</div>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{isEditMode ? (
							<h2>Edytuj Odbiorcę</h2>
						) : (
							<h2>Dodaj Odbiorcę</h2>
						)}
						<div className="form">
							<div className="column">
								<div>
									<label>Nazwa odbiorcy:</label>
									<input
										name="nazwa"
										placeholder="Nazwa odbiorcy"
										value={newRecipient.nazwa}
										className="input-form"
										onChange={handleInputChange}
									/>

									<p className="err-msg">{errors.nazwa}</p>
								</div>
								<div>
									<label>Uczestnik Grupy Zakupowej:</label>
									<Select
										name="gmina_id"
										classNamePrefix="react-select"
										placeholder="Wybierz uczestnika..."
										options={gminy}
										styles={customStyles}
										value={newRecipient.gmina_id}
										onChange={(selectedOption) => {
											setNewRecipient((prevState) => ({
												...prevState,
												gmina_id: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">{errors.gmina_id}</p>
								</div>
								<div>
									<label>Ulica:</label>
									<input
										name="ulica"
										placeholder="Ulica odbiorcy"
										value={newRecipient.ulica}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.ulica}</p>
								</div>
								<div>
									<label>Nr budynku:</label>
									<input
										name="budynek"
										placeholder="Nr budynku odbiorcy"
										value={newRecipient.budynek}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.budynek}</p>
								</div>
								<div>
									<label>Nr lokalu:</label>
									<input
										name="lokal"
										placeholder="Nr lokalu odbiorcy"
										value={newRecipient.lokal}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.lokal}</p>
								</div>
								<div>
									<label>NIP:</label>
									<input
										name="nip"
										placeholder="NIP odbiorcy"
										value={newRecipient.nip}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.nip}</p>
								</div>
							</div>
							<div className="column">
								<div>
									<label>REGON:</label>
									<input
										name="regon"
										placeholder="Regon odbiorcy"
										value={newRecipient.regon}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.regon}</p>
								</div>
								<div>
									<label>Miejscowość:</label>
									<input
										name="miasto"
										placeholder="Miejscowość odbiorcy"
										value={newRecipient.miasto}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.miasto}</p>
								</div>
								<div>
									<label>Kod pocztowy:</label>
									<input
										name="kod"
										placeholder="Kod pocztowy odbiorcy"
										value={newRecipient.kod}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.kod}</p>
								</div>
								<div>
									<label>Poczta:</label>
									<input
										name="poczta"
										placeholder="Poczta odbiorcy"
										value={newRecipient.poczta}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.poczta}</p>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'start',
									}}>
									<label
										htmlFor="odrebnaUmowaSwitch"
										style={{ marginBottom: '5px' }}>
										Czy odrębna umowa?
									</label>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '7px',
										}}>
										<span>NIE</span>
										<Switch
											id="odrebnaUmowaSwitch"
											checked={newRecipient.odrebna_umowa}
											onChange={handleSwitchChange}
										/>
										<span>TAK</span>
									</div>
									<p
										style={{
											fontSize: '11px',
											marginTop: '5px',
										}}>
										TAK - jeżeli dana jednostka chce odrębną
										umowę
									</p>
									<p
										style={{
											fontSize: '11px',
											marginTop: '5px',
										}}>
										NIE - jeżeli dana jednostka chce jedną
										wspólną umowę z gminą
									</p>
								</div>
							</div>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{isEditMode ? (
								<Button
									className="add"
									onClick={handleEditRecipient}>
									Zapisz
								</Button>
							) : (
								<Button
									className="add"
									onClick={handleAddRecipient}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć odbiorcę:</h2>
						<h2>{deletingRow.nazwa} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeleteRecipient()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</RecipientsLayout>
	);
};

export default Recipients;
