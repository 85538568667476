import { useState, useRef, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { CustomersLayout } from './Customers.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import useAccessControl from '../../hooks/useAccessControl';
import * as XLSX from 'xlsx';
import { jwtDecode } from 'jwt-decode';

const Customers = () => {
	const isAccessVerified = useAccessControl();
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [deletingRow, setDeletingRow] = useState();
	const [data, setData] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [dataRoles, setDataRoles] = useState();
	const [gminy, setGminy] = useState();
	const [dataPpes, setDataPpes] = useState();
	const [newCustomer, setNewCustomer] = useState({
		id: '',
		contact: '',
		nip: '',
		regon: '',
		email: '',
		rep_role_id: [],
		gmina_id: [],
		ppe_id: [],
		budynek: '',
		ulica: '',
		miasto: '',
		kod: '',
	});
	const [errors, setErrors] = useState({
		contact: '',
		nip: '',
		regon: '',
		email: '',
		rep_role_id: '',
		gmina_id: '',
		ppe_id: '',
		budynek: '',
		ulica: '',
		miasto: '',
		kod: '',
	});
	const [finishedProcedure, setFinishedProcedure] = useState(() => {
		const saved = localStorage.getItem('finishedProcedure');
		return saved ? saved === 'true' : false;
	});

	const fetchData = async () => {
		const getCustomers = {
			action: 'get-customers',
		};
		const getRepRoles = { action: 'get-rep-role' };
		const getPPES = { action: 'get-ppes' };
		const checkProcedure = { action: 'check-procedure' };

		try {
			const checkProcedureResponse = await sendRequest(checkProcedure);
			const isFinished = checkProcedureResponse.data._data_ !== 0;
			setFinishedProcedure(isFinished);
			localStorage.setItem('finishedProcedure', isFinished.toString());
			const customersResponse = await sendRequest(getCustomers);
			setData(customersResponse.data._data_);

			const repRolesResponse = await sendRequest(getRepRoles);
			setDataRoles(
				repRolesResponse.data._data_.map((role) => ({
					value: role.id,
					label: role.description,
				}))
			);

			const gminaIdString = localStorage.getItem('gmina_id');
			const gminaIdArray = JSON.parse(gminaIdString);

			setGminy(
				gminaIdArray.map((gmina) => ({
					value: gmina.gmina_id,
					label: gmina.gmina_nazwa,
				}))
			);

			const ppeResponse = await sendRequest(getPPES);
			setDataPpes(
				ppeResponse.data._data_.map((ppe) => ({
					value: ppe.ppe_id,
					label: `${ppe.nazwa} (${ppe.ppe_id})`,
				}))
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const decodeToken = () => {
		const token = localStorage.getItem('token');
		if (token) {
			const decoded = jwtDecode(token);
			return decoded.adm;
		}
		return 0;
	};

	const isAdmin = decodeToken() === 1;

	const clearForm = () => {
		setNewCustomer({
			id: '',
			contact: '',
			nip: '',
			regon: '',
			email: '',
			rep_role_id: [],
			gmina_id: '',
			ppe_id: [],
			budynek: '',
			ulica: '',
			miasto: '',
			kod: '',
		});
	};

	const clearErrors = () => {
		setErrors({
			contact: '',
			nip: '',
			regon: '',
			email: '',
			rep_role_id: '',
			gmina_id: '',
			ppe_id: '',
			budynek: '',
			ulica: '',
			miasto: '',
			kod: '',
		});
	};

	const closeModal = () => {
		if (isEditMode) {
			clearForm();
			setIsEditMode(false);
		}
		clearErrors();
		setIsModalOpen(false);
		setIsModalDeleteOpen(false);
		setDeletingRow();
	};

	const handleEditClick = (row) => {
		const selectedGmina = gminy.find(
			(gmina) => gmina.value === row.gmina_id
		);
		const repRoleIds = row.rep_role_ids ? row.rep_role_ids.split(',') : [];
		const ppeIds = row.ppe_ids ? row.ppe_ids.split(',') : [];

		const selectedRepRoles = dataRoles.filter((role) =>
			repRoleIds.includes(String(role.value))
		);
		const selectedPpes = dataPpes.filter((ppe) =>
			ppeIds.includes(String(ppe.value))
		);

		setNewCustomer({
			id: row.id,
			contact: row.contact,
			nip: row.nip != null ? String(row.nip) : row.nip,
			regon: row.regon != null ? String(row.regon) : row.regon,
			email: row.email,
			rep_role_id: selectedRepRoles,
			gmina_id: selectedGmina,
			ppe_id: selectedPpes,
			budynek: row.budynek,
			ulica: row.ulica,
			miasto: row.miasto,
			kod: row.kod,
		});
		setIsEditMode(true);
		setIsModalOpen(true);
	};

	const handleEditCustomer = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);

			const customerData = {
				id: newCustomer.id,
				contact: newCustomer.contact,
				nip: newCustomer.nip,
				regon: newCustomer.regon,
				email: newCustomer.email,
				rep_role_id: newCustomer.rep_role_id.map((role) => role.value),
				gmina_id: newCustomer.gmina_id.value,
				ppe_id: newCustomer.ppe_id.map((ppe) => ppe.value),
				budynek: newCustomer.budynek,
				ulica: newCustomer.ulica,
				miasto: newCustomer.miasto,
				kod: newCustomer.kod,
			};

			const addCustomer = {
				action: 'edit-customer',
				_data_: customerData,
			};

			try {
				const response = await sendRequest(addCustomer);
				if (response.data.status === 0) {
					setErrors((prevState) => ({
						...prevState,
						contact: 'Błąd edytowania nabywcy',
					}));
				} else {
					clearErrors();
					closeModal();
					clearForm();
					fetchData();
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const handleAddCustomer = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const customerData = {
				contact: newCustomer.contact,
				nip: newCustomer.nip,
				regon: newCustomer.regon,
				email: newCustomer.email,
				rep_role_id: newCustomer.rep_role_id.map((role) => role.value),
				gmina_id: newCustomer.gmina_id.value,
				ppe_id: newCustomer.ppe_id.map((ppe) => ppe.value),
				budynek: newCustomer.budynek,
				ulica: newCustomer.ulica,
				miasto: newCustomer.miasto,
				kod: newCustomer.kod,
			};

			const addCustomer = {
				action: 'add-customer',
				_data_: customerData,
			};

			try {
				const response = await sendRequest(addCustomer);
				if (response.data.status === 0) {
					setErrors((prevState) => ({
						...prevState,
						contact: 'Błąd dodawania nabywcy',
					}));
				} else if (response.data.status === 2) {
					setErrors((prevState) => ({
						...prevState,
						nip: response.data.message,
					}));
				} else {
					clearErrors();
					closeModal();
					clearForm();
					fetchData();
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const validateForm = () => {
		let isValid = true;
		let newErrors = {
			contact: '',
			nip: '',
			regon: '',
			email: '',
			rep_role_id: '',
			gmina_id: '',
			ppe_id: '',
			budynek: '',
			ulica: '',
			miasto: '',
			kod: '',
		};

		for (const key in newCustomer) {
			if (key === 'id' || key === 'ulica') continue;

			if (
				newCustomer[key] === null ||
				newCustomer[key] === undefined ||
				newCustomer[key] === '' ||
				(typeof newCustomer[key] === 'string' &&
					newCustomer[key].length === 0)
			) {
				newErrors[key] = 'To pole jest wymagane';
				isValid = false;
			}
		}

		if (
			newCustomer.email !== null &&
			!/\S+@\S+\.\S+/.test(newCustomer.email)
		) {
			newErrors.email = 'Nieprawidłowy adres email';
			isValid = false;
		}

		if (newCustomer.nip !== null && newCustomer.nip.length !== 10) {
			newErrors.nip = 'NIP powinien zawierać 10 cyfr';
			isValid = false;
		}

		if (
			newCustomer.regon !== null &&
			![9, 14].includes(newCustomer.regon.length)
		) {
			newErrors.regon = 'REGON powinien zawierać 9 lub 14 cyfr';
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleDeleteCustomer = async () => {
		const deleteCustomer = {
			action: 'delete-customer',
			id: deletingRow.id,
		};
		try {
			await sendRequest(deleteCustomer);
			fetchData();
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		if (name === 'nip' || name === 'regon') {
			if (/^\d*$/.test(value)) {
				setNewCustomer((prevState) => ({
					...prevState,
					[name]: value,
				}));
			}
		} else if (name === 'kod') {
			if (/^[0-9-]*$/.test(value)) {
				setNewCustomer((prevState) => ({
					...prevState,
					[name]: value,
				}));
			}
		} else {
			setNewCustomer((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const exportToExcel = () => {
		const dataToExport = data.map((item) => ({
			Nazwa: item.contact,
			NIP: item.nip,
			Regon: item.regon,
			Miasto: item.miasto,
			'Kod pocztowy': item.kod,
			Ulica: item.ulica,
			Budynek: item.budynek,
		}));

		const ws = XLSX.utils.json_to_sheet(dataToExport);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Nabywcy');

		const exportFileName = `Nabywcy_${new Date().toLocaleDateString()}.xlsx`;

		XLSX.writeFile(wb, exportFileName);
	};

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '15px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							handleEditClick(row);
						}}
					/>
					<Icozamknij
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							setDeletingRow(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
			omit: !(!finishedProcedure || isAdmin),
		},
		{
			name: 'Nazwa',
			selector: (row) => row.contact,
			grow: 6,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'NIP',
			selector: (row) => row.nip,
			grow: 4,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Regon',
			selector: (row) => row.regon,
			grow: 5,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Miasto',
			selector: (row) => row.miasto,
			grow: 4,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Kod',
			selector: (row) => row.kod,
			grow: 3,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Ulica',
			selector: (row) => row.ulica,
			grow: 3,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Budynek',
			selector: (row) => row.budynek,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
	];

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	if (!isAccessVerified) {
		return null;
	}

	return (
		<CustomersLayout>
			<h2 className="page-title">Nabywcy</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={
						data && data.length > 0
							? data.filter((customer) =>
									Object.values(customer).some((value) =>
										String(value)
											.toLowerCase()
											.includes(searchValue.toLowerCase())
									)
							  )
							: []
					}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<div className="table-menu">
					{(!finishedProcedure || isAdmin) && (
						<Button
							className="add-button"
							onClick={() => setIsModalOpen(true)}
							style={{
								fontSize: '14px',
							}}>
							Dodaj nabywcę
						</Button>
					)}
					<input
						placeholder="Szukaj..."
						className="search-input"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<Button
						style={{
							fontSize: '14px',
							marginBottom: '10px',
							marginLeft: 'auto',
						}}
						onClick={exportToExcel}>
						Eksportuj do Excela
					</Button>
				</div>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{isEditMode ? (
							<h2>Edytuj nabywcę</h2>
						) : (
							<h2>Dodaj nabywcę</h2>
						)}
						<div className="form">
							<div className="column">
								<div>
									<label>Opis:</label>
									<input
										name="contact"
										placeholder="Opis nabywcy"
										value={newCustomer.contact}
										className="input-form"
										onChange={handleInputChange}
									/>

									<p className="err-msg">{errors.contact}</p>
								</div>
								<div>
									<label>NIP:</label>
									<input
										name="nip"
										placeholder="NIP nabywcy"
										value={newCustomer.nip}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.nip}</p>
								</div>
								<div>
									<label>Regon:</label>
									<input
										name="regon"
										placeholder="Regon nabywcy"
										value={newCustomer.regon}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.regon}</p>
								</div>
								<div>
									<label>Email:</label>
									<input
										name="email"
										placeholder="Email nabywcy"
										value={newCustomer.email}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.email}</p>
								</div>
								<div>
									<label>Kod pocztowy:</label>
									<input
										name="kod"
										placeholder="Kod pocztowy nabywcy"
										value={newCustomer.kod}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.kod}</p>
								</div>
								<div>
									<label>Miasto:</label>
									<input
										name="miasto"
										placeholder="Miasto nabywcy"
										value={newCustomer.miasto}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.miasto}</p>
								</div>
							</div>
							<div className="column">
								<div>
									<label>Ulica:</label>
									<input
										name="ulica"
										placeholder="Ulica nabywcy"
										value={newCustomer.ulica}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.ulica}</p>
								</div>
								<div>
									<label>Budynek:</label>
									<input
										name="budynek"
										placeholder="Budynek nabywcy"
										value={newCustomer.budynek}
										className="input-form"
										onChange={handleInputChange}
									/>
									<p className="err-msg">{errors.budynek}</p>
								</div>
								<div>
									<label>Reprezentowana/y przez:</label>
									<Select
										name="rep_role_id"
										options={dataRoles}
										classNamePrefix="react-select"
										placeholder="Wybierz reprezentanta..."
										styles={customStyles}
										isMulti
										value={newCustomer.rep_role_id}
										onChange={(selectedOptions) => {
											setNewCustomer((prevState) => ({
												...prevState,
												rep_role_id: selectedOptions,
											}));
										}}
									/>
									<p className="err-msg">
										{errors.rep_role_id}
									</p>
								</div>
								<div>
									<label>Uczestnik Grupy Zakupowej:</label>
									<Select
										name="gmina_id"
										classNamePrefix="react-select"
										placeholder="Wybierz uczestnika..."
										options={gminy}
										styles={customStyles}
										value={newCustomer.gmina_id}
										onChange={(selectedOption) => {
											setNewCustomer((prevState) => ({
												...prevState,
												gmina_id: selectedOption,
											}));
										}}
									/>
									<p className="err-msg">{errors.gmina_id}</p>
								</div>
								<div>
									<label>PPE:</label>
									<Select
										name="ppe_id"
										classNamePrefix="react-select"
										options={dataPpes}
										placeholder="Wybierz PPE..."
										styles={customStyles}
										isMulti
										value={newCustomer.ppe_id}
										onChange={(selectedOptions) => {
											setNewCustomer((prevState) => ({
												...prevState,
												ppe_id: selectedOptions,
											}));
										}}
									/>
									<p className="err-msg">{errors.ppe_id}</p>
								</div>
							</div>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{isEditMode ? (
								<Button
									className="add"
									onClick={handleEditCustomer}>
									Zapisz
								</Button>
							) : (
								<Button
									className="add"
									onClick={handleAddCustomer}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć nabywcę:</h2>
						<h2>{deletingRow.contact} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeleteCustomer()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</CustomersLayout>
	);
};

export default Customers;
