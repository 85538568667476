import styled from 'styled-components';

export const HomeLayout = styled.div`
	margin-top: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 20px;

	.chart {
		padding: 5px 5px 0 5px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
		margin: 10px;
		flex-basis: 45%;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}

	.chart {
		min-width: 300px;
	}
	.user-container {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		min-height: 90vh;
		gap: 15px;

		.info-container {
			display: flex;
			justify-content: center;
			align-items: center;

			flex-direction: row;
			gap: 15px;

			.info {
				display: flex;
				align-items: center;
				flex-direction: column;
				gap: 10px;
				width: 325px;
				height: 600px;
				padding: 15px 15px;
				background-color: ${(props) => props.theme.white};
				border-radius: 5px;

				table {
					width: 100%;
					border-collapse: collapse;
					font-family: Arial, sans-serif;
				}

				th,
				td {
					border: 1px solid #ddd;
					text-align: center;
					padding: 8px;
				}

				tr:nth-of-type(1),tr:nth-of-type(2) {
					background-color: #fee45b;
					color: #5F616E;
				}

				h2 {
					margin-bottom: 10px;
				}

				div {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					align-items: center;
					font-size: 13px;
					width: 100%;
					height: 100%;

					p {
						display: flex;
						width: 100%;
						justify-content: space-between;
						padding-bottom: 2px;
						font-weight: 700;

						span {
							font-weight: 400;
						}
						&:nth-child(odd) {
							border-bottom: 2px solid #fee45b;
						}

						&:nth-child(even) {
							border-bottom: 2px solid gray;
						}
					}
				}
			}
		}

		.contact {
			display: flex;
			justify-content: space-between;
			background-color: ${(props) => props.theme.white};
			padding: 15px 15px;
			margin-bottom: 25px;
			border-radius: 5px;
			width: 100%;
			font-size: 13px;

			.contact-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: start;
				gap: 5px;

				p:nth-of-type(1) {
					margin-bottom: 15px;
					font-weight: 700;
				}
			}

			.footer-container {
				display: flex;
				gap: 25px;

				.footer {
					p:nth-of-type(1),
					p:nth-of-type(2),
					p:nth-of-type(3) {
						font-style: italic;
						font-weight: 700;
						letter-spacing: 1px;
					}

					p:nth-of-type(3) {
						margin-bottom: 15px;
						font-weight: 200;
						letter-spacing: normal;
					}
				}
			}
		}
	}
	.note {
		font-size: 11px;
		text-align: center;
	}

	@media (min-width: 1024px) {
		.user-container {
			.info-container {
				.info {
					width: 420px;

					div {
						font-size: 16px;
					}
				}
			}

			.contact {
				padding: 25px 40px;
				font-size: 16px;
			}
		}
	}

	@media (min-width: 1981px) {
		.user-container {
			.info-container {
				.info {
					width: 600px;
					height: 800px;
					gap: 50px;
					div {
						font-size: 18px;
					}
				}
			}

			.contact {
				padding: 25px 60px;
				font-size: 18px;
			}
		}

		.note {
			font-size: 14px;
		}
	}
`;
