import { useState, useRef, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { OSDLayout } from './OSD.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import useAccessControl from '../../hooks/useAccessControl';

const OSD = () => {
	const isAccessVerified = useAccessControl();
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [data, setData] = useState();
	const [deletingRow, setDeletingRow] = useState();
	const [newOSD, setNewOSD] = useState({
		id: '',
		nazwa: '',
	});
	const [errors, setErrors] = useState({
		nazwa: '',
	});

	const fetchData = async () => {
		const getOSDS = {
			action: 'get-osds',
		};

		try {
			const response = await sendRequest(getOSDS);
			setData(response.data._data_);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const clearForm = () => {
		setNewOSD({
			id: '',
			nazwa: '',
		});
	};

	const closeModal = () => {
		if (isEditMode) {
			clearForm();
			setIsEditMode(false);
		}
		setIsModalOpen(false);
		setIsModalDeleteOpen(false);
		setDeletingRow();
	};

	const handleEditClick = (row) => {
		setNewOSD({
			id: row.id,
			nazwa: row.nazwa,
		});
		setIsEditMode(true);
		setIsModalOpen(true);
	};

	const handleEditOsd = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const osdData = {
				id: newOSD.id,
				nazwa: newOSD.nazwa,
			};

			const addOsd = {
				action: 'edit-osd',
				_data_: osdData,
			};

			try {
				await sendRequest(addOsd);
				closeModal();
				clearForm();
				fetchData();
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const handleDeleteOsd = async () => {
		const deleteOsd = {
			action: 'delete-osd',
			id: deletingRow.id,
		};
		try {
			await sendRequest(deleteOsd);
			fetchData();
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddOsd = async () => {
		if (validateForm()) {
			setIsLoadingRequest(true);
			const osdData = {
				nazwa: newOSD.nazwa,
			};

			const addOsd = {
				action: 'add-osd',
				_data_: osdData,
			};

			try {
				await sendRequest(addOsd);
				closeModal();
				clearForm();
				fetchData();
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingRequest(false);
			}
		}
	};

	const validateForm = () => {
		let isValid = true;
		let newErrors = {
			nazwa: '',
		};

		for (const key in newOSD) {
			if (key === 'id') continue;

			if (newOSD[key] === '' || newOSD[key].length === 0) {
				newErrors[key] = 'To pole jest wymagane';
				isValid = false;
			}
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewOSD((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '15px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							handleEditClick(row);
						}}
					/>
					<Icozamknij
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() => {
							setDeletingRow(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
		{
			name: 'Nazwa',
			selector: (row) => row.nazwa,
			grow: 2,
			sortable: true,
			sortField: '0',
		},
	];

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	if (!isAccessVerified) {
		return null;
	}

	return (
		<OSDLayout>
			<h2 className="page-title">OSD</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={data}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<Button
					className="add-button"
					onClick={() => setIsModalOpen(true)}
					style={{
						fontSize: '14px',
					}}>
					Dodaj OSD
				</Button>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{isEditMode ? <h2>Edytuj OSD</h2> : <h2>Dodaj OSD</h2>}
						<div>
							<label>Nazwa OSD:</label>
							<input
								name="nazwa"
								placeholder="Nazwa OSD"
								value={newOSD.nazwa}
								className="input-form"
								onChange={handleInputChange}
							/>

							<p className="err-msg">{errors.nazwa}</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{isEditMode ? (
								<Button className="add" onClick={handleEditOsd}>
									Zapisz
								</Button>
							) : (
								<Button className="add" onClick={handleAddOsd}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć OSD:</h2>
						<h2>{deletingRow.nazwa} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeleteOsd()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</OSDLayout>
	);
};

export default OSD;
