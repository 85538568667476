import React, { useState, useEffect } from 'react';
import { ViewDocumentLayout } from './ViewDocument.style';
import { sendRequest } from '../../api/sendRequest';
import ReactQuill, { Quill } from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import parse from 'html-react-parser';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Button } from '../../components/Button/Button';
import { useParams } from 'react-router-dom';

const ViewDocument = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [htmlContent, setHtmlContent] = useState('');
	const [dataJson, setDataJson] = useState({});
	const [documentName, setDocumentName] = useState('');
	const [margin, setMargin] = useState('normal');
	const [documentNotFound, setDocumentNotFound] = useState(false);
	const { id } = useParams();

	const fetchData = async () => {
		const getDocument = { action: 'get-document-done', id: id };

		try {
			const response = await sendRequest(getDocument);
			if (response.data._data_ && response.data._data_.length > 0) {
				const documentData = response.data._data_[0];
				setDocumentName(documentData.name);
				setDataJson(documentData.data_json);
				const delta = JSON.parse(documentData.data_json);
				setMargin(delta.marginOption);

				const quill = new Quill(document.createElement('div'));
				quill.setContents(delta);
				let html = quill.root.innerHTML;

				setHtmlContent(html);
				setDocumentNotFound(false);
			} else {
				setDocumentNotFound(true);
			}
		} catch (error) {
			console.error(error);
			setDocumentNotFound(true);
		} finally {
			setIsLoading(false);
		}
	};

	const savePDF = () => {
		const input = document.querySelector('.ql-editor');
		html2canvas(input, { scale: 2 })
			.then((canvas) => {
				const imgWidth = 190;
				const pageHeight = 295;
				const imgHeight = (canvas.height * imgWidth) / canvas.width;
				let heightLeft = imgHeight;

				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF('p', 'mm');
				let position = 0;

				pdf.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;

				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					pdf.addPage();
					pdf.addImage(
						imgData,
						'PNG',
						5,
						position,
						imgWidth,
						imgHeight
					);
					heightLeft -= pageHeight;
				}

				pdf.save(`${documentName}.pdf`);
			})
			.catch((err) => console.error('Error generating PDF', err));
	};

	const handleDownload = async () => {
		const inputs = document.querySelectorAll('input');
		const data = Array.from(inputs).reduce((acc, input) => {
			acc[input.name] = { length: input.maxLength, value: input.value };
			return acc;
		}, {});
		savePDF();
	};

	useEffect(() => {
		fetchData();
	}, [id]);

	useEffect(() => {
		document.title = documentName || 'Dokument';
	}, [documentName]);

	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (documentNotFound) {
		return <ViewDocumentLayout>Brak dokumentu</ViewDocumentLayout>;
	}

	return (
		<ViewDocumentLayout>
			<h2>{documentName}</h2>
			<Button onClick={handleDownload}>
				Pobierz dokument
			</Button>
			<div className="ql-container ql-snow">
				<div className={`ql-editor ${margin}`}>
					{parse(htmlContent)}
				</div>
			</div>
		</ViewDocumentLayout>
	);
};

export default ViewDocument;
