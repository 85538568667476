import { useState, useRef, useEffect } from 'react';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { ArchivalDocumentsLayout } from './ArchivalDocuments.style';
import { Button } from '../../components/Button/Button';
import { sendRequest } from '../../api/sendRequest';
import { TailSpin } from 'react-loader-spinner';
import { ReactComponent as Icoedytuj } from './../../svg/ico-edytuj.svg';
import { ReactComponent as Icozamknij } from './../../svg/ico-zamknij.svg';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { useStyledSelect } from '../../components/Select/StyledSelect';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';
import useAccessControl from '../../hooks/useAccessControl';
import * as XLSX from 'xlsx';

const ArchivalDocuments = () => {
	const isAccessVerified = useAccessControl();
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [gminyOptions, setGminyOptions] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedRegions, setSelectedRegions] = useState([]);

	const currentYear = new Date().getFullYear();
	const years = Array.from(
		{ length: currentYear + 2 - 2024 },
		(_, index) => ({
			value: 2024 + index,
			label: (2024 + index).toString(),
		})
	);

	const fetchData = async () => {
		const getDocumentsDone = {
			action: 'get-documents-done',
		};

		const getGminy = {
			action: 'get-gminy',
		};

		try {
			const documentsResponse = await sendRequest(getDocumentsDone);
			setData(documentsResponse.data._data_);
			const gminyResponse = await sendRequest(getGminy);
			const sortedGminy = gminyResponse.data._data_.sort((a, b) => a.nazwa.localeCompare(b.nazwa));
			setGminyOptions(
				sortedGminy.map((gmina) => ({
					value: gmina.id,
					label: gmina.nazwa,
				}))
			);			
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const filterData = () => {
		let result = data;

		if (searchValue) {
			result = result.filter((item) =>
				item.document_name
					.toLowerCase()
					.includes(searchValue.toLowerCase())
			);
		}

		if (selectedYear) {
			result = result.filter(
				(item) => item.document_year === selectedYear.value
			);
		}

		if (selectedRegions.length > 0) {
			result = result.filter((item) =>
				selectedRegions.some(
					(region) => region.label === item.gmina_nazwa
				)
			);
		}

		setFilteredData(result);
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		filterData();
	}, [data, searchValue, selectedYear, selectedRegions]);

	const columns = [
		{
			name: 'Akcje',
			cell: (row) => (
				<div style={{ display: 'flex', gap: '15px' }}>
					<Icoedytuj
						style={{ cursor: 'pointer' }}
						width={20}
						onClick={() =>
							window.open(
								`/admin-documents-history/document/${row.document_save_id}`,
								'_blank'
							)
						}
					/>
				</div>
			),
			style: { justifyContent: 'center' },
			grow: 1,
		},
		{
			name: 'Nazwa Dokumentu',
			selector: (row) => row.document_name,
			grow: 10,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Rok',
			selector: (row) => row.document_year,
			grow: 10,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Uczestnik',
			selector: (row) => row.gmina_nazwa,
			grow: 10,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Data Utworzenia',
			selector: (row) => row.ctime,
			grow: 10,
			sortable: true,
			sortField: '0',
		},
	];

	const exportToExcel = () => {
		const dataToExport = filteredData.map((item) => ({
			'Nazwa Dokumentu': item.document_name,
			Rok: item.document_year,
			'Gmina Nazwa': item.gmina_nazwa,
			'Opis Użytkownika': item.user_description,
			'Grupa Zakupowa': item.shopping_group_name,
		}));

		const ws = XLSX.utils.json_to_sheet(dataToExport);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'FilteredDocuments');

		const exportFileName = `Dokumenty_Historyczne_${new Date().toLocaleDateString()}.xlsx`;

		XLSX.writeFile(wb, exportFileName);
	};

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
				visible={true}
			/>
		);
	}

	if (!isAccessVerified) {
		return null;
	}

	return (
		<ArchivalDocumentsLayout>
			<h2 className="page-title">Dokumenty Historyczne</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
						rangeSeparatorText: 'z',
					}}
					data={filteredData}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationPerPage={25}
					paginationRowsPerPageOptions={[10, 20, 25, 30, 50, 75, 100]}
				/>
				<div className="table-menu">
					<input
						placeholder="Szukaj..."
						className="search-input"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<Select
						classNamePrefix="react-select"
						placeholder="Wybierz rok..."
						options={years}
						value={selectedYear}
						onChange={(option) => setSelectedYear(option)}
						styles={customStyles}
						isSearchable={false}
						isClearable={true}
					/>
					<Select
						classNamePrefix="react-select"
						placeholder="Wybierz uczestnika..."
						isMulti
						options={gminyOptions}
						value={selectedRegions}
						onChange={setSelectedRegions}
						styles={customStyles}
					/>
					<Button
						style={{
							fontSize: '14px',
							marginBottom: '10px',
						}}
						onClick={exportToExcel}>
						Eksportuj do Excela
					</Button>
				</div>
			</div>
		</ArchivalDocumentsLayout>
	);
};

export default ArchivalDocuments;
