import styled from 'styled-components';

export const ImportDataPageLayout = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 60px auto;
	padding: 20px;
	width: 90%;
	max-width: 500px;
	background-color: ${(props) => props.theme.white};
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	max-height: 500px;

	.header {
		color: ${(props) => props.theme.fontcolor};
	}

	.input-group {
		width: 100%;
		margin: 20px 0;
	}

	.label {
		display: block;
		color: ${(props) => props.theme.fontcolor};
		margin-top: 10px;
		margin-bottom: 5px;
	}

	.input {
		width: 100%;
		padding: 8px;
		border: 1px solid #ccc;
		border-radius: 4px;
		&:focus {
			border-color: #007bff;
		}
	}

	.button {
		margin-top: 10px;
		width: 100%;
		padding: 10px;
		border: none;
		border-radius: 4px;
		cursor: pointer;
	}

	.skip-button {
		position: absolute;
		top: 115px;
		right: 26px;
		padding: 2px;
	}

	.msg {
		margin-top: 100px;
		margin-bottom: 50px;
	}
`;
